import React, { useEffect, useMemo, useState } from 'react';
import { SimpleButton } from '../../../../common/Buttons/SimpleButton';
import { useDispatch } from 'react-redux';
import { cnergeeAssetDeviceDetails } from '../../../../actions/tickets';
import { validateStringValue } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import { closeModalAction } from '../../../../actions/modal';
import { getCurrentLocation } from '../../../../utils/utils';
import config from '../../../../config';
import devConfig from '../../../../config/env/development';
import { SkeltonCardTwo } from '../../../../common/SkeltonCardTwo';
import { UPDATE_MODAL_HEADER } from '../../../../types/modal';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';


export default function DeviceDetails({ isSupportTicket, onExecuteTicketCLick = () => { }, isExecuteDisabled = false, btnClass, ticketId, assets, sign_off_lock }) {
    const dispatch = useDispatch();
    const { cnergeeTicketDeviceData } = useSelector((state) => state.tickets);
    const { isCnergeeAssetDeviceDetailsLoading } = useSelector((state) => state.loading);
    const [deniedLocation, setDeniedLocation] = useState(false);

    useEffect(() => {
        const fetchAndDispatch = async () => {
            if (assets && assets.length > 0) {
                try {
                    const asset = assets.find(({ serial_number }) => serial_number);
                    if (!asset) return;

                    const currentLocation = await getCurrentLocation();

                    const payload = {
                        ticketId,
                        latitude: currentLocation?.latitude || asset.latitude,
                        longitude: currentLocation?.longitude || asset.longitude,
                        serial_number: validateStringValue(asset?.serial_number),
                    };

                    dispatch(cnergeeAssetDeviceDetails(payload));
                    setDeniedLocation(false);
                } catch (error) {
                    console.error('Error in fetching location or dispatching details:', error);
                    if (error.code === 1) setDeniedLocation(true);
                    else setDeniedLocation(false);
                }
            }
        };

        fetchAndDispatch();
    }, [assets, ticketId, dispatch]);

    const deviceData = useMemo(() => {
        let showExecute = false;
        let data = [
            { key: 'serial_number', label: 'Serial Number', value: '' },
            { key: 'box_status', label: 'Box Status', value: '' },
            { key: 'sim_1', label: 'SIM 1', value: '' },
            { key: 'sim_2', label: 'SIM 2', value: '' },
            { key: 'ip_matched', label: 'IP Matched', value: '' },
            { key: 'tunnel_Status', label: 'Tunnel Status', value: '' },
            { key: 'configured_ip', label: 'Configured IP', value: '' },
        ];
        if (ticketId && cnergeeTicketDeviceData && cnergeeTicketDeviceData[ticketId]) {
            let d = cnergeeTicketDeviceData[ticketId]?.[0];
            if (d) {
                showExecute = d.SIM1Status === 'UP' && d.SIM2Status === 'UP' && parseInt(d.IpMatched) === 1;
                data = [
                    { key: 'serial_number', label: 'Serial Number', value: d.HWSerialNo },
                    { key: 'box_status', label: 'Box Status', value: d.boxStatus },
                    { key: 'sim_1', label: 'SIM 1', value: d.SIM1Status },
                    { key: 'sim_2', label: 'SIM 2', value: d.SIM2Status },
                    { key: 'ip_matched', label: 'IP Matched', value: d.IpMatched },
                    { key: 'tunnel_Status', label: 'Tunnel Status', value: d.TunnelStatus },
                    { key: 'configured_ip', label: 'Configured IP', value: d.configuredIP },
                ]
            }
        }
        if (config.name === devConfig.name) showExecute = true;
        return { data, showExecute };
    }, [cnergeeTicketDeviceData, ticketId]);

    useEffect(() => {
        if (deviceData.showExecute) {
            dispatch({ type: UPDATE_MODAL_HEADER, payload: 'Device is matched/configured' })
        }
    }, [deviceData.showExecute])

    if (isCnergeeAssetDeviceDetailsLoading) {
        return <div className='w-full px-4'>
            <SkeltonCardTwo arr={[1, 2, 3, 4, 5]} height={20} />
        </div>
    }

    if (deniedLocation) {
        return <div className='w-full px-6 py-2 text-[1.5rem] text-scogoorange'>We need your location to provide better services. Please enable location access.</div>
    };

    return < div className='flex flex-col px-4 py-4' >
        {
            deviceData.data.map(({ key, label, value }) => {
                return <div className='flex gap-2 items-center' key={key}>
                    <span className='text-light text-gray-600 text-font13 flex whitespace-nowrap'>{label} : </span>
                    <span className='text-light w-full break-all text-font13 line-clamp-2 text-black'>{value}</span>
                </div>
            })
        }
        < div className='pt-3 justify-end flex gap-3' >
            {deviceData.showExecute && <SimpleButton
                buttonIconComponent={<span className='material-icons text-font16'>room_preferences</span>}
                btnClass={btnClass}
                textOrComponent={isSupportTicket ? 'Submit' : 'Execute'}
                onClick={onExecuteTicketCLick}
                disabled={isExecuteDisabled}
                onHoverTitle={isSupportTicket && sign_off_lock === 1 ? 'SP/FE can not raise sign off of this ticket, as execution date is more than 7 days old' : ''}
            />}
            <ButtonScogoPrimary
                textOrComponent={"Okay"}
                onClick={() => dispatch(closeModalAction())}
            />
        </div >
    </div >
}