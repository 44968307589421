import React from 'react';
import DropdownMenu from '../../../../common/MenuDropdown';
import Timestamp from './Timestamp';
import { chatActions, chatType, messageType } from '../../ChatController';
import Loader from '../../../../common/Loader';
import moment from 'moment';
import IconToolTip from '../../../../common/IconToolTip';
import { useSelector } from 'react-redux';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReplyIcon from '@mui/icons-material/Reply';
function RightMessage({
    children,
    message,
    onReplyClick,
    messageActions,
    onRetryMessage,
    onSaveEditedMessage,
    isEditMessage,
    setIsEditMessage,
    editedMessage,
    setEditedMessage,
    showActions,
    showReplyIcon = true,
    isSearchMessage,
    messageOnclick,
    showJumbIcon = false,
    ticketId
}) {
    const { openedConversationId, conversationDetails } = useSelector((state) => state.conversations);
    const openedConversationDetail = conversationDetails?.[openedConversationId];
    let chatUsers = conversationDetails?.[openedConversationId]?.users || [];
    if (ticketId) chatUsers = conversationDetails?.[ticketId]?.users;
    const { viewTicket } = useSelector((state) => state.tickets);

    const actionForGpt = () => {
        let possibleActions
        if ('chatGpt') {
            possibleActions = [chatActions.copy]
        }
        return possibleActions
    }
    const getFilteredMessageActions = () => {
        return messageActions.filter((action) => {
            if (action?.key === chatActions.copy && message.type === messageType.attachments) {
                return false;
            }

            else if (chatActions.forwardMessageToWhatsapp === action?.key && message?.conversation?.type === chatType?.help) {
                return false
            } else if (action?.key === chatActions.notify_end_user) {
                return message.type !== messageType.attachments && openedConversationDetail?.type === chatType.ticket;
            }

            if (action.key === chatActions.edit) {
                const islessThanTwoHourAgo = (date) => {
                    return moment(date).isAfter(moment().subtract(2, 'hours'));
                };

                return [messageType.message, messageType.reply].includes(message.type);
            }

            return true;
        });
    };

    const getLoaderDiv = () => {
        if (message.sendMessageError) {
            return (
                <div>
                    <span className='material-icons text-scogoclosed text-font12'>error</span>
                    <IconToolTip title='Retry'>
                        <>
                            <span onClick={() => onRetryMessage(message)} className={`material-icons cursor-pointer text-font12`}>
                                refresh
                            </span>
                        </>
                    </IconToolTip>
                </div>
            );
        }
        if (message.timestamp) {
            return (
                <>
                    <span>
                        <Timestamp className='text-right cursor-pointer' timestamp={message.timestamp} />
                    </span>
                </>
            );
        }
        return (
            <div className='w-8 items-start ml-auto'>
                <Loader size='10' thickness='2' />
            </div>
        );
    };

    const dropDown = () => {
        return (
            <>
                {showActions && (
                    <DropdownMenu
                        dropDownClass='text-font14 invisible text-scogoprimary left_right_message'
                        componentOrIcon={'more_vert'}
                        menuData={getFilteredMessageActions()}
                        item={message}
                        payload={{ onReplyClick, onEditClick: () => setIsEditMessage(true), ticket: viewTicket }}
                    />
                )}
            </>
        );
    };
    const isSingleChat = openedConversationDetail && openedConversationDetail.type === chatType.single;
    const showDoubleTick = isSingleChat && message.timestamp;
    return (
        <div className={`w-full pr-2 flex ${isSearchMessage ? "hover:bg-cyan-50 py-1 cursor-pointer" : ""} justify-end gap-2`} onClick={() => {
            if (isSearchMessage) {
                messageOnclick(message?.conversation?._id, message?._id)
            }
        }} >
            <>
                {showReplyIcon ? <span className='hidden items-center justify-center messageDropdown hover:bg-scogoorange  cursor-pointer bg-scogogray  rounded-full mt-4 rounded-s-full h-8 w-8 pb-1'>
                    < ReplyIcon className='text-white' onClick={() => onReplyClick(message)} />
                </span>
                    :
                    <>
                        {showJumbIcon && <p className=' hidden messageDropdown cursor-pointer bg-gray-300 h-2/4 w-1/10 justify-center rounded-md text-font09 text-black' >Jump</p>}
                    </>
                }
            </>
            <div className='w-2/3 max-w-max bg-white px-1 pt-1 rounded-t-lg rounded-l-lg '>
                <div className='relative'>
                    {message.edited && <span className='text-font8 text-scogogray px-1 pt-2 pr-4 italic'>(edited)</span>}
                    {message.timestamp && (
                        <div className='absolute right-0 top-0 pl-2 z-10' style={{ transform: 'translateX(15%)' }}>
                            {dropDown()}
                        </div>
                    )}
                    {children({
                        repliedToBG: 'bg-mesColor',
                        audioClassName: 'audioBgWhite',
                        isEditMessage: isEditMessage,
                        setIsEditMessage: setIsEditMessage,
                        onSaveEditedMessage: onSaveEditedMessage,
                        setEditedMessage: setEditedMessage,
                        editedMessage: editedMessage,
                        chatUsers: chatUsers,
                    })}
                    <div className='mt-1 h-8 border-none text-right ml-auto'>

                        <div className=' flex gap-2 justify-end pr-2'>
                            {getLoaderDiv()}
                            {showDoubleTick && (
                                <div style={message.readByAll ? { color: '#F48A21' } : { color: '#888888' }}>
                                    <DoneAllIcon />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RightMessage;
