import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { clearPoGstDetailsFromStore, getPoGstDetails, payPoTax } from '../../../actions/payments';
import DetailTag from '../../../pages/project/DetailTag';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { validateDateValue, validateNumberValue, validateResponseDate, validateStringValue } from '../../../utils/common';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import GridTable from '../../../common/GridTable';
import moment from 'moment';
import OverflowTip from '../../../common/OverflowTip';
import IconToolTip from '../../../common/IconToolTip';
import { DateComponent } from '../../../common/TableReusableColumns';
import { textsClass } from '../../../common/tabeHelpers';
import { orderStatusCode, paymentType } from '../../Wallet/walletController';
import { copyToClipboard } from '../../../actions/utils';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';
import { getWalletDetailsById } from '../../../actions/wallet';
import { ScogoAmountBalance } from '../../Wallet/Modals/SendToBank';

const payTaxThroughBank = 'payTaxThroughBank';
const payTaxThroughWallet = 'payTaxThroughWallet';
const bankTransferTitle = 'PAY_PO_GST_BANK_TRANSFER';
const payPoGstTitle = 'PAY_PO_GST';

const PayPoTax = (props) => {
    const { ticketId, vendorId, isRefresh, paymentDetails, enduserId } = props;
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { walletDetailsById } = useSelector((state) => state.wallet);
    const dispatch = useDispatch();

    const defaultValues = {
        withdrawAmount: '',
    };

    useEffect(() => {
        if (ticketId) {
            dispatch(getPoGstDetails({ ticketId: ticketId }));
        }
        return () => {
            dispatch(clearPoGstDetailsFromStore());
        }
    }, [dispatch, ticketId]);

    const onSubmit = ({ formValues, formName, type }) => {
        const payload = {
            ticketId,
            formName,
            withdrawAmount: validateNumberValue(formValues.withdrawAmount),
            vendorId,
            isRefresh,
            type,
            updateConvo: true,
            enduserId
        };
        if (type === bankTransferTitle) {
            // COMMENTED BY AMARJEET BANKOPEN
            // payload.banking_partner = 'BANKOPEN';
        }
        if (ticketId && vendorId) {
            dispatch(payPoTax(payload));
        }
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const gstTaxDetail = paymentDetails?.gstDetails?.total;
    const ticketTotalTax = paymentDetails?.totalTax;
    const ticketTotalUnpaidTax = paymentDetails?.totalUnpaidTax;
    const isToShowForm = ticketTotalUnpaidTax > 0;
    const taxAmountErrorMsg = `Amount should not be more than ${ticketTotalUnpaidTax ? parseFloat(ticketTotalUnpaidTax).toFixed(2) : 'unpaid tax.'}`;

    const validatePayTaxAmount = (amount) => {
        return parseFloat(amount) <= parseFloat(ticketTotalUnpaidTax);
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { getValues } = methods;

    useEffect(() => {
        dispatch(getWalletDetailsById({ updateBalance: false, accountBalance: 'BANKOPEN' }));
    }, [dispatch]);

    const scogoBankAccountBalance = validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance);

    return (
        <>
            <div className='px-4 py-3 max-w-max'>
                <ScogoAmountBalance />
            </div>
            <div className='px-4 py-2'>
                <GstPaymentTable />
            </div>
            <div className={` ${!isToShowForm ? 'pb-6' : ''}`}>
                <div className={`md:flex pl-5 pt-4 w-full`}>
                    {gstTaxDetail && (
                        <DetailTag
                            tagHeadClass={'text-scogo99 text-font11 font-medium'}
                            tagValueClass={'text-font11 font-medium break-words'}
                            tagClass='px-1 w-6/12'
                            tagName='GST'
                            tagValue={`${gstTaxDetail} %`}
                        />
                    )}
                    {!isNaN(ticketTotalTax) && !isNaN(ticketTotalUnpaidTax) && (
                        <DetailTag
                            tagHeadClass={'text-scogo99 text-font11 font-medium'}
                            tagValueClass={'text-font11 font-medium break-words line-clamp-3'}
                            tagClass=' px-1 w-6/12'
                            tagName='Total Tax Paid'
                            tagValue={`${validateNumberValue(ticketTotalTax - ticketTotalUnpaidTax).toFixed(2)}/${validateNumberValue(ticketTotalTax).toFixed(2)}`}
                        />
                    )}
                </div>
            </div>
            {isToShowForm ? (
                <div>
                    <Form
                        className='w-full'
                        onSubmit={() => { }}
                        methods={methods}
                    >
                        <div className='px-3'>
                            <Input
                                label='Amount To Pay'
                                required
                                name='withdrawAmount'
                                type={'number'}
                                customValidation={validatePayTaxAmount}
                                errorMessage={taxAmountErrorMsg}
                                validate={(value) => {
                                    if (value === '') return true;
                                    if (parseFloat(value) === 0) return 'Amount Should Be More Than 0';
                                    return parseFloat(value) <= parseFloat(ticketTotalUnpaidTax) || taxAmountErrorMsg;
                                }}
                                step={'any'}
                            />
                        </div>

                    </Form>
                    <div className='flex gap-2 justify-end py-4 px-4'>
                        <ButtonScogoPrimary
                            textOrComponent={'Bank'}
                            loading={formButtonLoading[payTaxThroughBank]}
                            onClick={() => {
                                const formValues = getValues();
                                onSubmit({ formValues, formName: payTaxThroughBank, type: bankTransferTitle })
                            }}
                            disabled={scogoBankAccountBalance < 400}
                        />
                        <ButtonScogoPrimary
                            textOrComponent={'Wallet'}
                            loading={formButtonLoading[payTaxThroughWallet]}
                            onClick={() => {
                                const formValues = getValues();
                                onSubmit({ formValues, formName: payTaxThroughWallet, type: payPoGstTitle })
                            }}
                        />
                        <ButtonScogoClosedOutlined textOrComponent={'Cancel'} type='button' onClick={closeModal} />
                    </div>
                </div>
            ) : (
                <></>
            )}

        </>
    );
};

export default PayPoTax;


const GstPaymentTable = () => {
    const { poGstDetails } = useSelector((state) => state.payments);
    const { isPoGstDetailsLoading } = useSelector((state) => state.loading);



    const schema = {
        table: [
            {
                head: {
                    render: (headName) => headName,
                    headName: 'Transaction Id',
                    width: 0.8,

                },
                body: {
                    render: (transaction) => {
                        const transactionTime = validateDateValue(transaction.txTime) && moment(new Date(validateResponseDate(transaction.txTime)));

                        return (
                            <div className=''>
                                <OverflowTip textClass={'text-scogoprimary font-medium'} someLongText={transaction.scogo_tx_no} />
                                {transactionTime && (
                                    <IconToolTip title={'Transaction Date'}>
                                        <>
                                            <DateComponent date={transactionTime} text={false} />
                                        </>
                                    </IconToolTip>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => headName,
                    headName: 'Amount',
                    width: 0.4,

                },
                body: {
                    render: (transaction) => {
                        return (
                            <div className='md:block flex items-center'>
                                <p className={`${textsClass.medium}`}>
                                    ₹ <span>{validateNumberValue(transaction.orderAmount)}</span>
                                </p>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Status',
                    columnRowCss: 'break-words',
                    width: 1,
                },
                body: {
                    render: (transaction) => {
                        return (
                            <div className='md:block flex items-center'>
                                <p className={`${textsClass.medium}`}>
                                    <span className='break-all'>{validateStringValue(transaction.txStatus)}</span>
                                </p>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    thClassName: `w-80 mx-2`,
                    headName: 'Action/Status',
                    render: (headName) => headName,
                    width: 0.6,
                    columnRowCss: 'break-all'
                },
                body: {
                    bodyClassName: `w-80 mx-2`,
                    render: (ticket) => (
                        <GetPaymentStatus po={ticket} />
                    ),
                },
            },
        ]
    }

    if (isPoGstDetailsLoading) return <SkeltonCardTwo arr={[1, 2, 3, 4]} height={18} padding={0.1} />
    if (!poGstDetails || poGstDetails?.length === 0) return <></>;

    return <GridTable
        schema={schema}
        rowData={poGstDetails}
        tableError={'No Payments Found'}
    />
}


const GetPaymentStatus = ({ po }) => {
    const dispatch = useDispatch();

    const copyTransaction = (txnDetails) => {
        let content = '';
        if (txnDetails.paymentType === paymentType.wallet_transfer) {
            content = '';
            content += `Mode of Payment: Scogo Wallet\n`;
            content += `Amount: ${txnDetails.orderAmount}\n`;
            content += `Scogo Transaction No: ${txnDetails.scogo_tx_no}\n`;
            content += `Transaction Time: ${moment(txnDetails.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}`;
        } else if ([paymentType.bank_transfer_debit, paymentType.bank_transfer_credit].includes(txnDetails.paymentType)) {
            content = '';
            content += `Mode of Payment: Bank Transfer\n`;
            if (txnDetails.user) {
                content += `Recepient Name: ${txnDetails.user.first_name} ${txnDetails.user.last_name}\n`;
            }
            content += `Transaction No: ${txnDetails.referenceId}\n`;
            content += `Account Number: ${txnDetails.cardNumber}\n`;
            content += `Amount: ${txnDetails.orderAmount}\n`;
            content += `Transaction Time: ${moment(txnDetails.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}`;
        }
        dispatch(copyToClipboard({ data: content }))
    }

    let label = '';
    let color = '';
    if (po.paymentType === paymentType.wallet_transfer) {
        if (po.orderStatus === 'Successful') {
            label = 'Wallet';
            color = 'scogogoing'
        } else {
            label = 'Failure';
            color = 'scogoclosed'
        }
    } else if ([paymentType.bank_transfer_debit, paymentType.bank_transfer_credit].includes(po.paymentType)) {
        if (orderStatusCode.withdraw_success.includes(po.orderStatus)) {
            label = 'Bank';
            color = 'scogogoing'
        } else if (orderStatusCode.withdraw_failed.includes(po.orderStatus)) {
            label = 'Failure';
            color = 'scogoclosed'
        } else {
            label = 'Pending';
            color = 'scogoorange'
        }
    }

    if (label) {
        return <p className={`bg-${color} scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
            {label}
            <span
                onClick={(e) => {
                    e.preventDefault();
                    copyTransaction(po);
                }}
                className={`material-icons text-base text-white hover:text-scogoorange cursor-pointer hover:text-scogogray pl-2`}
            >
                content_copy
            </span>
        </p>
    }
    return <></>;
};