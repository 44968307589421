import { modal } from '../types';

const INITIAL_STATE = {
    isTextModalOpen: false,
    isCustomModalOpen: false,
    isSmallCustomModalOpen: false,
    heading: '',
    text: '',
    type: '',
    buttons: [],
    modalComponent: '',
    closeOnBackdropClick: true,
    isMediaViewModalOpen: false,
    showLogout: false,
    isTutorialStepsOpen: false,
    tutorialSteps: [],
    isFullScreenModalOpen: false,
    isProgressBarModalOpen: false,
    downloadProgressList: [],
    isOnCall: false,
    activeCallDetails: undefined,
    fullScreenModalComponent: <></>
};

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case modal.OPEN_TEXT_MODAL:
            return {
                ...state,
                isTextModalOpen: true,
                heading: action.payload.heading,
                text: action.payload.text,
                type: action.payload.type,
                buttons: action.payload.buttons,
                closeOnBackdropClick: action.payload.closeOnBackdropClick,
                formName: action.payload.formName,
                selectedCount: action.payload.selectedCount,
            };

        case modal.OPEN_CUSTOM_MODAL:
            return {
                ...state,
                isCustomModalOpen: true,
                modalComponent: action.payload.modalComponent,
                heading: action.payload.heading,
                selectedCount: action.payload.selectedCount,
                modalWidth: action.payload.modalWidth,
                modalHeight: action.payload.modalHeight,
                showClose: action.payload.showClose,
                showLogout: action.payload.showLogout,
                maxHeight: action.payload.maxHeight,
                headerComponent: action.payload.headerComponent,
                onClose: action.payload.onClose,
            };
        case modal.OPEN_SMALL_CUSTOM_MODAL:
            return {
                ...state,
                isSmallCustomModalOpen: true,
                modalComponent: action.payload.modalComponent,
                heading: action.payload.heading,
            };
        case modal.OPEN_FULLSCREEN_MODAL:
            return {
                ...state,
                isFullScreenModalOpen: true,
                fullScreenModalComponent: action.payload.modalComponent,
                heading: action.payload.heading,
            };
        case modal.MEDIA_VIEW_MODAL:
            return {
                ...state,
                isMediaViewModalOpen: true,
                attachments: action.payload.attachments,
                sender: action.payload.sender,
                openedMediaIndex: action.payload.openedMediaIndex,
                showSenderDetail: action.payload.showSenderDetail,
                isTicketChat: action.payload.isTicketChat,
                currentConversationId: action.payload.currentConversationId,
                callStepFields: action.payload.callStepFields,
                isExecuteImage: action.payload.isExecuteImage,
            };
        case modal.OPEN_TUTORIAL_STEPS: {
            return {
                ...state,
                isTutorialStepsOpen: true,
                tutorialSteps: action.payload.steps,
            };
        }
        case modal.CLOSE_TUTORIAL_STEPS: {
            return { ...state, isTutorialStepsOpen: INITIAL_STATE.isTutorialStepsOpen, tutorialSteps: INITIAL_STATE.tutorialSteps };
        }
        case modal.OPEN_PROGRESS_BAR_MODAL: {
            return { ...state, isProgressBarModalOpen: true };
        }
        case modal.CLOSE_PROGRESS_BAR_MODAL: {
            return { ...state, isProgressBarModalOpen: INITIAL_STATE.isProgressBarModalOpen, downloadProgressList: [] };
        }

        case modal.DOWNLOAD_ZIP_START_WEBSOCKET: {
            const { zipFileName, progress = 0 } = action.payload;
            const findProgress = state.downloadProgressList.find((item) => item.zipFileName === zipFileName);
            if (!findProgress) {
                return {
                    ...state,
                    downloadProgressList: [...state.downloadProgressList, { progress, zipFileName }]
                }
            }
            return state;
        }

        case modal.DOWNLOAD_ZIP_PROGRESS_WEBSOCKET: {
            const { zipFileName, progress } = action.payload;
            const findProgress = state.downloadProgressList.find((item) => item.zipFileName === zipFileName);

            if (!findProgress) return { ...state, downloadProgressList: [...state.downloadProgressList, { progress, zipFileName }] }
            const downloadProgressList = state.downloadProgressList.map((item) => {
                if (item.zipFileName === zipFileName) return { ...item, progress };
                return item
            });

            return {
                ...state,
                downloadProgressList
            }
        }
        case modal.DOWNLOAD_ZIP_DONE_WEBSOCKET: {
            const { zipFileName, fileUrl } = action.payload;
            const downloadProgressList = state.downloadProgressList.map((item) => {
                if (item.zipFileName === zipFileName) return { ...item, progress: 1, fileUrl };
                return item
            });
            return {
                ...state,
                downloadProgressList
            }
        }
        case modal.DOWNLOAD_ZIP_FAIL_WEBSOCKET: {
            const { zipFileName } = action.payload;
            const downloadProgressList = state.downloadProgressList.map((item) => {
                if (item.zipFileName === zipFileName) return { ...item, downloadFail: true };
                return item
            });
            return {
                ...state,
                downloadProgressList
            }
        }

        case modal.OPEN_REQUESTED_CALL_MODAL: {
            return {
                ...state,
                isOnCall: action.payload.isOnCall,
                activeCallDetails: action.payload.data
            }
        }

        case modal.CLOSE_MODAL:
            return {
                ...state,
                isTextModalOpen: INITIAL_STATE.isTextModalOpen,
                isCustomModalOpen: INITIAL_STATE.isCustomModalOpen,
                isSmallCustomModalOpen: INITIAL_STATE.isSmallCustomModalOpen,
                heading: INITIAL_STATE.heading,
                text: INITIAL_STATE.text,
                type: INITIAL_STATE.type,
                buttons: INITIAL_STATE.buttons,
                modalComponent: INITIAL_STATE.modalComponent,
                closeOnBackdropClick: INITIAL_STATE.closeOnBackdropClick,
                isMediaViewModalOpen: INITIAL_STATE.isCustomModalOpen,
                showLogout: INITIAL_STATE.showLogout,
                isTutorialStepsOpen: INITIAL_STATE.isTutorialStepsOpen,
                tutorialSteps: INITIAL_STATE.tutorialSteps,
                selectedCount: undefined,
                formName: undefined,
                attachments: undefined,
                sender: undefined,
                openedMediaIndex: undefined,
                showSenderDetail: undefined,
                isTicketChat: false,
                isExecuteImage: false,
                currentConversationId: 0,
                callStepFields: false,
                modalWidth: undefined,
                modalHeight: undefined,
                maxHeight: undefined,
                isProgressBarModalOpen: INITIAL_STATE.isProgressBarModalOpen,
            };

        case modal.CLOSE_FULL_SCREEN_MODAL: {
            return {
                ...state,
                modalComponent: INITIAL_STATE.modalComponent,
                isFullScreenModalOpen: false
            }
        }
        case modal.UPDATE_MODAL_HEADER: {
            return {
                ...state,
                heading: action.payload,
            }
        }
        default:
            return state;
    }
};

export default modalReducer;
