import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputBar from './InputBar';
import ReplyBox from './ReplyBox';
import SendFiles from './SendFiles';
import { connectToWebsocket, sendDataThroughWebsocket } from '../../../../actions/websocket';
import { sendScogoGptMessage, setopenedConversationMessageIsLatest, updateunReadMessage, uploadFilesToChat } from '../../../../actions/conversations';
import { websocketEvents } from '../../../../utils/websocket';
import { sendToastMessage } from '../../../../actions/toast';
import { maxChatFileSizeInBytes } from '../../ChatController';
import { GetQueryParam } from '../../../../utils/common';
import { scogo_new_chat_created } from '../../ChatMessage';
import { getFileType } from '../../../../utils/common';

export const transformMentionsForSubmit = (mentions, users) => {
    const tags = mentions.map((mention) => {
        const position = mention.plainTextIndex;
        const allUser = { userId: -2, username: 'All', role: '' };
        if (mention.id === '@all') {
            return { position, user: allUser };
        }
        const mentionedUser = users?.find((user) => user._id === mention.id);
        return { position, user: mentionedUser };
    });

    return tags;
};

export const getHeightOfUploaded = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let height, width;

        reader.onload = (e) => {
            const imageUrl = e.target.result;
            const img = new Image();

            img.src = imageUrl;

            img.onload = () => {
                width = img.width;
                height = img.height;
                resolve({ height, width });
            };

            img.onerror = () => {
                reject('Failed to load image.');
            };
        };

        reader.readAsDataURL(file);
    });
};

const MessageInput = forwardRef(({ replyShow, setReplyShow, closeReply, replyToMessage, getLatestConversationMessages, isLatest, showAllTag, showDoubleHash = false, ticketId, isSupportTicket, openedConversationId, isChatPopup, uuid }, ref) => {
    const dispatch = useDispatch();
    const [inputTextValue, setInputTextValue] = useState('');
    const { conversationDetails } = useSelector((state) => state.conversations);
    let openedConversation = conversationDetails?.[openedConversationId];
    if (ticketId) openedConversation = conversationDetails?.[ticketId];
    const { loggedUser } = useSelector((state) => state.auth);
    const [showFileUi, setshowFileUi] = useState(false);
    const [files, setFile] = useState([]);
    const isScogoGptChat = GetQueryParam("chatGpt", Boolean);
    const { websocketConnection } = useSelector((state) => state.websocket);
    const [mentionsusers, setMentionsusers] = useState([]);

    const clearInput = useCallback(() => {
        setInputTextValue('');
        closeReply();
    }, [closeReply]);

    const onFileSelect = useCallback(async (event) => {
        let uploadedData = [...event.target.files];
        let newFiles = [...files];
        let maxSizeFileNamesList = [];

        for (let idx = 0; idx < uploadedData.length; idx++) {
            const file = uploadedData[idx];

            if (file.size <= maxChatFileSizeInBytes) {
                const now = new Date();
                const id = now.getTime() + idx;

                try {
                    let f = { id, caption: '', file }
                    if (getFileType(file.type) === 'image') {
                        const { height, width } = await getHeightOfUploaded(file);
                        f = { ...f, height, width };
                    }
                    newFiles.push(f);
                } catch (error) {
                    console.error(error);
                }
            } else {
                maxSizeFileNamesList.push(file.name);
            }
        }

        if (maxSizeFileNamesList.length) {
            let message = `File Size of ${maxSizeFileNamesList.join(', ')} ${maxSizeFileNamesList.length > 1 ? 'are' : 'is'
                } bigger than ${maxChatFileSizeInBytes / 1000000} MB`;
            dispatch(sendToastMessage({ status: 'danger', message }));
        }

        if (newFiles.length > 20) {
            let message = 'Cannot send more than 20 files at a time';
            dispatch(sendToastMessage({ status: 'danger', message }));
        }

        if (newFiles.length > 0) {
            let filesToSet = newFiles.slice(0, 20);
            setFile(filesToSet);
            setshowFileUi(true);
        }
    }, [dispatch, files]);


    useImperativeHandle(ref, () => ({
        onFileSelect: (files) => {
            onFileSelect({ target: { files } });
        },
    }));

    const handlePaste = useCallback(
        (evt) => {
            const clipboardItems = evt.clipboardData.items;
            const items = [].slice.call(clipboardItems).filter(function (item) {
                // Filter the image items only
                return /^image\//.test(item.type);
            });
            if (items.length === 0) return;
            const blob = items[0].getAsFile();
            let file = new File([blob], `image${files.length + 1}.jpg`, { type: 'image/jpeg', lastModified: new Date().getTime() }, 'utf-8');
            onFileSelect({ target: { files: [file] } });
        },
        [onFileSelect, files.length]
    );

    const loggedUserDetails = {
        email: loggedUser.email,
        role: loggedUser.type,
        roleId: loggedUser.role.id,
        userId: loggedUser.id,
        username: `${loggedUser.first_name} ${loggedUser.last_name}`,
    };

    useEffect(() => {
        clearInput();
    }, [openedConversationId, clearInput]);

    const sendNormalMessage = (messageText, tags) => {
        const payload = {
            action: websocketEvents.chat.sendMessage,
            data: {
                conversation: openedConversationId,
                type: 'message',
                message: messageText,
            },
            from: loggedUserDetails,
        }
        if (openedConversation?.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadCount: 0, _id: openedConversationId }))
        }
        if (Array.isArray(tags) && tags.length > 0) payload.data.tags = tags;
        dispatch(sendDataThroughWebsocket(payload));
    };

    const sendReplyMessage = (messageText, repliedTo, tags) => {
        let payload = {
            action: websocketEvents.chat.sendMessage,
            data: {
                conversation: openedConversationId,
                type: 'reply',
                message: messageText,
                repliedTo,
            },
            from: loggedUserDetails,
        }
        if (Array.isArray(tags) && tags.length > 0) payload.data.tags = tags
        if (openedConversation?.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadCount: 0, _id: openedConversationId }))
        }
        dispatch(sendDataThroughWebsocket(payload));
    };

    const sendScogoChatGptMessage = (userInput) => {
        let conversationId = scogo_new_chat_created;
        if (openedConversationId) conversationId = openedConversationId;
        dispatch(sendScogoGptMessage({ conversationId, userInput }));
    }

    const taggableUsers = useMemo(() => {
        const allUser = { display: 'All', id: '@all' };
        const transformed = openedConversation?.users?.map((user) => ({ id: user._id, display: user.username, ...user })) || [];
        if (transformed.length > 0) {
            return showAllTag ? [allUser, ...transformed] : transformed;
        }
        return [];
    }, [openedConversation?.users, showAllTag]);

    const onSend = async (callOnSubmit = () => { }) => {
        if (!websocketConnection) {
            if (isSupportTicket) {
                dispatch(connectToWebsocket());
                dispatch(sendToastMessage({ status: 'danger', message: 'Oops ..Connection Lost !! Please Retry.' }));
                return;
            } else {
                dispatch(sendToastMessage({ status: 'danger', message: 'Oops ..Connection Lost !! Please Retry.' }));
                return;
            }

        }
        const users = openedConversation?.users
        if (inputTextValue) {
            let mentionsUser = [];
            if (!inputTextValue.startsWith('##')) {
                mentionsUser = transformMentionsForSubmit(mentionsusers, users);
            }
            if (replyShow) {
                sendReplyMessage(inputTextValue, replyToMessage, mentionsUser);
            } else if (isScogoGptChat) {
                sendScogoChatGptMessage(inputTextValue);
            } else {
                sendNormalMessage(inputTextValue, mentionsUser);
            }
            if (openedConversation?.unreadMessageCount > 0) {
                dispatch(updateunReadMessage({ unreadCount: 0, _id: openedConversationId }))
            }
        }
        callOnSubmit('');
        clearInput();
    };


    const onFileSend = async (files) => {
        const filesToSend = await Promise.all(
            files.map(async (item) => {
                return item;
            })
        );
        if (openedConversation?.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadCount: 0, _id: openedConversationId }))
        }
        dispatch(uploadFilesToChat({ _id: openedConversationId, fileData: filesToSend, from: loggedUserDetails, isSupportTicket, isReplying: replyShow, replyToMessage }));
        dispatch(setopenedConversationMessageIsLatest({ isLatest: true, _id: openedConversationId }));
        closeFileUI();
        setReplyShow(false);
    };

    const closeFileUI = useCallback(() => {
        setshowFileUi(false);
        setFile([]);
    }, []);

    const handleInputChange = (inputValue, mentions) => {
        setMentionsusers(mentions);
        setInputTextValue(inputValue);
    };

    useEffect(() => {
        closeFileUI()
    }, [closeFileUI, openedConversationId])

    return (
        <div className='chatInputBar'>
            {replyShow && <ReplyBox closeReply={closeReply} message={replyToMessage} timestamp={replyToMessage.timestamp} isChatPopup={isChatPopup} />}
            {showFileUi && (
                <SendFiles
                    openedConversationId={openedConversationId}
                    users={openedConversation?.users}
                    taggableUsers={taggableUsers}
                    closeFileUI={closeFileUI}
                    onFileSelect={onFileSelect}
                    onFileSend={onFileSend}
                    files={files}
                    setFile={setFile}
                    isChatPopup={isChatPopup}
                    replyShow={replyShow}
                />
            )}
            {!showFileUi && <InputBar
                allowFileUpload={!isScogoGptChat}
                setInputTextValue={setInputTextValue}
                inputTextValue={inputTextValue}
                handleInputChange={handleInputChange}
                onFileSelect={onFileSelect}
                onSend={onSend}
                showAllTag={showAllTag}
                taggableUsers={taggableUsers}
                conversationId={openedConversationId}
                replyShow={replyShow}
                handlePaste={handlePaste}
                showDoubleHash={showDoubleHash}
                openedConversationId={openedConversationId}
                uuid={uuid}


            />}

        </div>
    );
});

export default MessageInput;
