export const SCM = {
    role: 6,
    type: 'SCM',
    label: 'SCM',
};

export const CLUSTER = {
    role: 6,
    type: 'CLUSTER',
    label: 'Cluster',
};
export const CUSTOMER = {
    role: 4,
    type: '',
    label: 'Customer',
};
export const CUSTOMER_ADMIN = {
    role: 14,
    type: '',
    label: 'Customer Admin',
};
export const CUSTOMER_USER = {
    role: 15,
    type: '',
    label: 'Customer User',
};
export const FE = {
    role: 8,
    type: 'FE',
    label: 'Field Engineer',
};

export const SP = {
    role: 12,
    type: 'SP',
    label: 'Service Partner',
};

export const PSP = {
    role: 12,
    type: 'PSP',
    label: 'PSP',
};

export const SPISP = {
    role: '',
    type: 'SPISP',
    label: 'SPISP',
};

export const ISP = {
    role: '',
    type: 'ISP',
    label: 'Internet Service Provider',
};
export const ENDUSER = {
    role: 16,
    type: '',
};

export const SUPERVISOR = {
    role: 6,
    type: 'SUPERVISOR',
    label: 'Supervisor',
};

export const ADMIN = {
    role: 1,
    type: '',
};

export const FM = {
    role: 10,
    type: '',
};

export const IWAN = {
    role: 12,
    type: 'SP',
    label: 'IWAN'
};

export const CSP = {
    role: 12,
    type: 'SP',
    label: 'Service Partner',
    working_under: 'CUSTOMER'
};

export const BRANCH_USER = {
    role: 15,
    type: 'BU',
    label: 'BRANCH USER'
};

export const isScm = (role, type) => role === SCM.role && type === SCM.type;

export const isCluster = (role, type) => role === CLUSTER.role && type === CLUSTER.type;

export const isCustomer = (role, type) => role === CUSTOMER.role && type !== ISP.type;

export const isCustomerAdmin = (role, type) => role === CUSTOMER_ADMIN.role;

export const isCustomerUser = (role, type) => role === CUSTOMER_USER.role;

export const isFe = (role, type) => role === FE.role;

export const isSp = (role, type) => role === SP.role && type === SP.type;

export const isPsp = (role, type) => role === PSP.role && type === PSP.type;

export const isSpIsp = (role, type) => (role === SP.role || role === CUSTOMER.role) && type === SPISP.type;

export const isIsp = (role, type) => (role === SP.role || role === CUSTOMER.role) && type === ISP.type;

export const isEndUser = (role, type) => role === ENDUSER.role;

export const isSuperVisor = (role, type) => role === SUPERVISOR.role && type === SUPERVISOR.type;

export const isAdmin = (role) => role === ADMIN.role;

export const isFM = (role) => role === FM.role;

export const isCustomerGroup = (role, type) => isCustomer(role, type) || isCustomerAdmin(role) || isCustomerUser(role);

export const isIwan = (role, type, fk_cluster_id) => role === IWAN.role && type === IWAN.type && fk_cluster_id !== 0;

export const isCsp = (role, type, partner_working_under) => role === CSP.role && type === CSP.type && partner_working_under === CSP.working_under;

export const isBranchUser = (role, type) => role === BRANCH_USER.role && type === BRANCH_USER.type;

export const getRoleByLabelName = (role, type) => {
    if (isCustomer(role, type)) return CUSTOMER.label;
    else if (isCustomerAdmin(role, type)) return CUSTOMER_ADMIN.label;
    else if (isCustomerUser(role, type)) return CUSTOMER_USER.label;
    else if (isScm(role, type)) return SCM.label;
    else if (isSp(role, type)) return SP.label;
    else if (isIsp(role, type)) return ISP.label;
    else if (isSpIsp(role, type)) return SPISP.label;
    else if (isFe(role, type)) return FE.label;
    else if (isCluster(role, type)) return CLUSTER.label;
    else if (isPsp(role, type)) return PSP.label;
    else if (isSuperVisor(role, type)) return SUPERVISOR.label;
};
