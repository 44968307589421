import React, { useState, useEffect, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExecuteSidebar from '../../components/Execute';
import { useDispatch, useSelector } from 'react-redux';
import { getExecutionStepsFields, } from '../../../../actions/ticketExecution';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { validateNumberValue, validateStringValue } from '../../../../utils/common';

import { TicketExecutionHeader } from './TicketExecutionHeader';
import { RenderTicketExecution } from './RenderTicketExecution';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.1rem', color: 'white' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const colorLookup = {
    green: '#2FB768',
    orange: '#FFAB5B',
    red: '#E74C3C',
}
export const signoff = { field_title: 'Upload SignOff Images with Sign and Stamp', key: 'upload_signoff_images', input_type: 'fileupload' };

export default function SubHeader({ onClick, memoizedTicket, isChatPopup, openedConversation, isSupportTicket, subHeaderVisibleElement }) {

    const dispatch = useDispatch();
    const { chatHeaderExecutionStepFields } = useSelector((state) => state.tickets);
    const [headerColor, setHeaderColor] = useState('orange');
    const [showChecklist, setShowChecklist] = useState(false);

    const steps = useMemo(() => {
        let list = [signoff];
        if (memoizedTicket.sign_off_document) signoff.file_values = memoizedTicket.sign_off_document;
        if (memoizedTicket && memoizedTicket.ticket_id && chatHeaderExecutionStepFields && chatHeaderExecutionStepFields[memoizedTicket.ticket_id] && parseInt(openedConversation?.conversationId) === memoizedTicket.ticket_id) {
            list = [...chatHeaderExecutionStepFields[memoizedTicket.ticket_id], signoff]
        }
        return list;
    }, [chatHeaderExecutionStepFields, memoizedTicket, openedConversation?.conversationId]);

    useEffect(() => {
        let color = 'orange';
        if (Array.isArray(steps)) {
            const isAllStepsHasFiles = steps.every((step) => Array.isArray(step.file_values) && step.file_values.length > 0);
            const isAnyStepRejected = steps.find((step) => step.status === 2);
            if (isAnyStepRejected) color = 'red';
            else if (isAllStepsHasFiles) color = 'green';
            else color = 'orange';
            setHeaderColor(color);
        }
    }, [steps])

    useEffect(() => {
        if (memoizedTicket.ticket_id && memoizedTicket?.fk_template_id) {
            const data = { ticket_id: memoizedTicket.ticket_id, is_checklist: true, template_id: memoizedTicket.fk_template_id };
            dispatch(getExecutionStepsFields({ data, source: 'chat_header' }));
        }
    }, [memoizedTicket?.ticket_id, memoizedTicket?.fk_template_id, dispatch]);

    const totalSteps = validateNumberValue(steps.length);
    let completedSteps = 0;
    steps.forEach((step) => {
        if (Array.isArray(step.file_values) && step.file_values.length > 0 && step.status !== 2) return completedSteps += 1;
    });

    const isReacheSiteActionVisible = subHeaderVisibleElement.add_boq && memoizedTicket.reachedSite === 0;
    const isBoqVisible = subHeaderVisibleElement.add_boq && !showChecklist;
    const isChecklistVisible = subHeaderVisibleElement.checklist || showChecklist;
    const isProblemDescriptionVisible = subHeaderVisibleElement.show_problem_desciption;
    const showBoqBackButton = subHeaderVisibleElement.add_boq && showChecklist;

    if (!isReacheSiteActionVisible && !isBoqVisible && !isChecklistVisible && !isProblemDescriptionVisible && !showBoqBackButton) {
        return <></>
    }

    return <div className={`w-full z-20 overflow-hidden ${isSupportTicket ? 'absolute block' : 'chatSuHeaderTop'}`}>
        <Accordion className='bg-white'>
            <AccordionSummary sx={{ paddingX: '2rem', minHeight: '3rem', maxHeight: '3rem', background: `${colorLookup[headerColor]}`, width: '100%' }}>
                <TicketExecutionHeader isSupportTicket={isSupportTicket} memoizedTicket={memoizedTicket} completedSteps={completedSteps} totalSteps={totalSteps} onClick={onClick} isProblemDescriptionVisible={isProblemDescriptionVisible} isBoqVisible={isBoqVisible} isChecklistVisible={isChecklistVisible} isReacheSiteActionVisible={isReacheSiteActionVisible} />
            </AccordionSummary>
            <div className='shadow-lg'>
                <AccordionDetails sx={{ padding: '0', overflowY: 'scroll', maxHeight: "100%" }}>
                    {isBoqVisible && <RenderTicketExecution memoizedTicket={memoizedTicket} setShowChecklist={setShowChecklist} />}
                    {isChecklistVisible && <ExecuteSidebar ticket={memoizedTicket} steps={steps} isChatPopup={isChatPopup} openedConversation={openedConversation} isSupportTicket={isSupportTicket} showBoqBackButton={showBoqBackButton} setShowChecklist={setShowChecklist} />}
                    {!isChecklistVisible && memoizedTicket?.ticket_description && isProblemDescriptionVisible && <p className='bg-white  px-3 text-font13 font-normal' style={{ maxHeight: '30vh', overflowY: 'auto' }}>{validateStringValue(memoizedTicket?.ticket_description)}</p>}

                </AccordionDetails>
            </div>
        </Accordion>
    </div>
};