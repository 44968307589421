import { groupBy } from 'lodash';
import validator from 'validator';
import config from '../config';
import { compressImage } from './compression';
export function guid() {
    function _p8(s) {
        let p = (Math.random().toString(16) + '000000000').substr(2, 8);
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
}

export function getCustomerIdOfLoginUser(user) {
    if (user?.customer_id) {
        return user.customer_id;
    }
    return 0;
}

export function isValidMobileNumber(mobile) {
    if (!mobile) return;
    return validator.isMobilePhone(mobile, 'en-IN');
}

export function isValidEmail(email) {
    if (!email) return;
    return validator.isEmail(email);
}

export function isValidAadhaar(number) {
    const aadharRegex = /^\d{4}\d{4}\d{4}$/;
    if (!number) return;
    return aadharRegex.test(number);
}

export function isValidPincode(pin) {
    if (!pin) return;
    return /^(\d{6})$/.test(pin);
}

export function validatePAN(panVal) {
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regpan.test(panVal)) {
        return true;
    } else return false;
}

export function isValidGST(gstVal) {
    var regGst = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (regGst.test(gstVal)) {
        return true;
    } else return false;
}

export function isValidIFSC(ifsc) {
    var regIfsc = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (regIfsc.test(ifsc)) {
        return true;
    } else return false;
}

const encrypt = ({ salt, text }) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const decrypt = ({ salt, text }) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return text
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');
};

export const encryptProfilePath = ({ salt = 'profilePath', text }) => encrypt({ salt, text });
export const decryptProfilePath = ({ salt = 'profilePath', text }) => decrypt({ salt, text });

export function getUserIdOfLoginUser(user) {
    if (user) return user.id;
    return 0;
}

export const getPartnerListApiPayload = ({ type }) => {
    let payload = {};
    if (type === 'SCM') payload = { user_role: [6, 12], status: 0, type };
    if (type === 'CLUSTER') payload = { user_role: [6, 12], status: 0, type };
    return payload;
};

export const ticketBoqDetails = (ticket, faulty) => {
    let boq_data = [];
    if (ticket && ticket.assets && Array.isArray(ticket.assets) && ticket.assets.length > 0) {
        ticket.assets.map((data) => {
            let filter = ticket.assets.filter((obj) => {
                return obj.asset_type_id === data.asset_type_id;
            });

            return filter.forEach((boq) => {
                let data = {
                    asset_type_id: boq.asset_type_id,
                    title: boq.title,
                    quantity: filter.length,
                };
                const found = boq_data.some((el) => el.asset_type_id === boq.asset_type_id);
                if (!found) {
                    return boq_data.push(data);
                }
            });
        });
    }
    if (faulty) {
        if (ticket && ticket.ticket_asset && Array.isArray(ticket.ticket_asset) && ticket.ticket_asset.length > 0) {
            ticket.ticket_asset.map((data) => {
                let filter = ticket.ticket_asset.filter((obj) => {
                    return obj.asset_type_id === data.asset_type_id;
                });

                return filter.forEach((boq) => {
                    let data = {
                        asset_type_id: boq.asset_type_id,
                        title: boq.title,
                        quantity: filter.length,
                    };
                    const found = boq_data.some((el) => el.asset_type_id === boq.asset_type_id);
                    if (!found) {
                        return boq_data.push(data);
                    }
                });
            });
        }
    }
    return boq_data;
};

export const isDateValid = (date) => {
    return date && date instanceof Date && !isNaN(date);
};

export const getSingleConvoIdFromUserIds = (userId_1, userId_2) => {
    const [smallerUserId, largerUserId] = [Math.min(userId_1, userId_2), Math.max(userId_1, userId_2)];
    return `single_${smallerUserId}_${largerUserId}`;
};

export const generateNocExecutionForm = async (formValues = {}, searchedTicket) => {
    let scogoChecks = {};
    let dataToSend = new FormData();
    dataToSend.set('ticket_id', searchedTicket.id);

    await Promise.all(
        Object.keys(formValues).map(async (field, index) => {
            if (!field.includes('$') && !field.includes('scogocheckbox') && !field.includes('$geo_')) {
                if (field.split('_').length <= 5 && formValues[field] !== undefined && formValues[field] !== '' && formValues[field] !== null) {
                    let data;
                    if (typeof formValues[field] === 'string' && formValues[field].toLowerCase() === 'others') {
                        if (formValues[`$others${field}`]) {
                            data = formValues[`$others${field}`].toString();
                        } else {
                            data = formValues[field].toString();
                        }
                    } else {
                        data = formValues[field];
                    }
                    dataToSend.set(`nocData[${index}][noc_fields_relationship_id]`, parseInt(field.split('_')[1]));
                    if (field.split('_').length === 5) {
                        dataToSend.set(`nocData[${index}][count_value]`, data);
                    } else {
                        dataToSend.set(`nocData[${index}][field_value]`, data);
                    }
                    // if (formValues[`$geo_${field}`]) {
                    //     dataToSend.set(`nocData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                    // }
                    if (field.split('_')[2] !== 'fe' && !isNaN(field.split('_')[2])) {
                        dataToSend.set(`nocData[${index}][asset_index]`, parseInt(field.split('_')[2]));
                    }

                    if (formValues[`$file${field}`]) {
                        await Promise.all(
                            formValues[`$file${field}`].map(async (file, fileIndex) => {
                                try {
                                    const data = await compressImage(file);
                                    dataToSend.set(`nocData[${index}][files][${fileIndex}]`, data);
                                } catch (error) {
                                    console.error('compression error', error);
                                    dataToSend.set(`nocData[${index}][files][${fileIndex}]`, file);
                                }
                            })
                        );
                    }
                    if (field.split('_').length === 5) {
                        let gridData = Object.keys(formValues).filter((key) => key.includes(field.split('_')[3]) && key.split('_').length > 5);
                        let jsonData = [];
                        for (let i = 0; i < parseInt(formValues[field]); i++) {
                            let data = {};
                            let gridTypeKey = field.split('_');
                            data[gridTypeKey[gridTypeKey.length - 1]] = `${gridTypeKey[gridTypeKey.length - 1]} ${i + 1}`;
                            let b = groupBy(gridData, i);
                            for (let j = 0; j < b.length; j++) {
                                let key = b[j].split('_');
                                data[`${key[key.length - 1]}`] = formValues[b[j]];
                            }
                            jsonData.push(data);
                        }
                        dataToSend.set(`nocData[${index}][field_value]`, JSON.stringify(jsonData));
                        dataToSend.set(`nocData[${index}][grid_index]`, parseInt(field.split('_')[3].split('-')[0]));
                    }
                }
            }
            if (field.includes('scogocheckbox') && !field.includes('$geo_') && formValues[field] !== undefined && formValues[field] !== '' && formValues[field] !== null) {
                if (!field.includes('$file')) {
                    let similarWord = field.split('_')[0].slice(0, field.split('_')[0].length - 1);
                    let check = scogoChecks;
                    let filter = Object.keys(formValues).filter((data) => {
                        return data.includes(similarWord) && !data.includes('$file');
                    });
                    if (Array.isArray(check[similarWord])) {
                        check[similarWord].push(formValues[field]);
                    } else {
                        check[similarWord] = [formValues[field]];
                    }
                    scogoChecks = check;
                    if (filter.length === check[similarWord].length) {
                        dataToSend.set(`nocData[${index}][field_value]`, check[similarWord].join(','));
                        dataToSend.set(`nocData[${index}][noc_fields_relationship_id]`, parseInt(field.split('_')[1]));
                        // if (formValues[`$geo_${field}`]) {
                        //     dataToSend.set(`nocData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                        // }
                        if (field.split('_')[2] !== 'fe' && !isNaN(field.split('_')[2])) {
                            dataToSend.set(`nocData[${index}][asset_index]`, parseInt(field.split('_')[2]));
                        }
                        let fileFilter = Object.keys(formValues).filter((data) => {
                            return data.includes(similarWord) && data.includes(`$file${similarWord}_${parseInt(field.split('_')[1])}`);
                        });
                        if (fileFilter.length === 1) {
                            let checkKey = `$file${similarWord}_${parseInt(field.split('_')[1])}`;
                            if (field.split('_').length !== 2) {
                                checkKey = `$file${similarWord}_${parseInt(field.split('_')[1])}_${parseInt(field.split('_')[2])}`;
                            }
                            await Promise.all(
                                formValues[checkKey].map(async (file, fileIndex) => {
                                    try {
                                        const data = await compressImage(file);
                                        dataToSend.set(`nocData[${index}][files][${fileIndex}]`, data);
                                    } catch (error) {
                                        console.error('compression error', error);
                                        dataToSend.set(`nocData[${index}][files][${fileIndex}]`, file);
                                    }
                                })
                            );
                        }
                    }
                }
            }
            if (field.includes('$fileupload_') && !field.includes('$geo_')) {
                await Promise.all(
                    formValues[field].map(async (file, fileIndex) => {
                        dataToSend.set(`nocData[${index}][field_value]`, file);
                        dataToSend.set(`nocData[${index}][noc_fields_relationship_id]`, parseInt(field.split('_')[1]));
                        // if (formValues[`$geo_${field}`]) {
                        //     dataToSend.set(`nocData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                        // }
                        if (field.split('_')[2] !== 'fe' && !isNaN(field.split('_')[2])) {
                            dataToSend.set(`nocData[${index}][asset_index]`, parseInt(field.split('_')[2]));
                        }
                        try {
                            const data = await compressImage(file);
                            dataToSend.set(`nocData[${index}][files][${fileIndex}]`, data);
                        } catch (error) {
                            console.error('compression error', error);
                            dataToSend.set(`nocData[${index}][files][${fileIndex}]`, file);
                        }
                    })
                );
            }
            if (field.includes('$videoupload_') && !field.includes('$geo_')) {
                return formValues[field].map((file, fileIndex) => {
                    dataToSend.set(`nocData[${index}][field_value]`, file);
                    dataToSend.set(`nocData[${index}][files][${fileIndex}]`, file);
                    // if (formValues[`$geo_${field}`]) {
                    //     dataToSend.set(`nocData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                    // }
                    dataToSend.set(`nocData[${index}][noc_fields_relationship_id]`, parseInt(field.split('_')[1]));
                    if (field.split('_')[2] !== 'fe' && !isNaN(field.split('_')[2])) {
                        dataToSend.set(`nocData[${index}][asset_index]`, parseInt(field.split('_')[2]));
                    }
                });
            }
        })
    ).catch((error) => {
        console.error(error);
    });

    return dataToSend;
};

export const findAndUpdate = ({ id, dataList, updatingValues }) => {
    let newList = dataList?.map((item) => {
        if (item?.id === id) {
            return Object.assign(item, updatingValues);
        } else return item;
    });
    return newList;
};

export const numOfDaysBetween = function (d1, d2) {
    var diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
};
export const parseAdvancedSearchParamsFromString = (str) => {
    /**
     * Using this method on the backend as well so any changes here need to be reflected there
     * parse "serial_number{email dum 1 f}|asset_type{man I}"
     * into
     * { serial_number: 'val1 val2 val3', asset_type: 'aval1 aval2 aval3' }
     */
    const advancedSearchRegex = /(\w+)\{(.+)\}/;
    const matches = str.split(/(?<=\})\|/).map((x) => x.match(advancedSearchRegex));
    const params = matches.reduce((acc, match) => {
        if (Array.isArray(match)) {
            if (!acc) acc = {};
            acc[match[1]] = match[2];
        }
        return acc;
    }, null);
    return params;
};

export const serializeAdvancedSearchParams = (params) => {
    /**
     * if we have params like this { serial_number: 'val1 val2 val3', asset_type: 'aval1 aval2 aval3' }
     * then serialized looks like this
     * "serial_number{val1 val2 val3}|asset_type{aval1 aval2 aval3}"
     * */
    const serialized = Object.entries(params)
        .map(([k, v]) => {
            if (typeof v === 'string' && v.trim() !== '') {
                return `${k}{${v}}`;
            }
            return null;
        })
        .filter((x) => x)
        .join('|');
    return serialized;
};

export function isValidDrivingLicense(number) {
    const dlRegex = /^(([A-Z]{2}[0-9]{2})(( )|())|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
    if (!number) return;
    return dlRegex.test(number);
}



export const initOTPless = (callback) => {
    const otplessInit = Reflect.get(window, 'otplessInit')
    const loadScript = () => {
        const script = document.createElement('script')
        script.src = 'https://otpless.com/auth.js'
        script.id = 'otplessIdScript'
        // script.setAttribute('cid', 'YOUR_CID_HERE')
        document.body.appendChild(script)
    }
    otplessInit ? otplessInit() : loadScript();
    Reflect.set(window, 'otpless', callback);
}

export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Extract base64 string
            resolve(base64String);
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsDataURL(file);
    });
}

export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });
};