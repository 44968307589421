import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import Checkbox from '../../common/Checkbox';
import {
    getTicketsList,
    getSPAcceptedTickets,
    getFEAcceptedTickets,
    getTicketFilterList,
    getTicketFilteredList,
    setTicketFilterAndPaginationData,
    setTicketSideDrawer,
    getTicketCount,
    getIssueCategoriesFilterList,
} from '../../actions/tickets';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerIdOfLoginUser } from '../../utils/utils';
import OverflowTip from '../../common/OverflowTip';
import { isCluster, isCustomerGroup, isFe, ISP, isPsp, isScm, isSp, isSuperVisor, SP } from '../../utils/role';
import {
    allTab,
    newAndCustomerTab,
    triggerTab,
    ongoingTab,
    IssuesTab,
    completedTab,
    scheduledTab,
    columnsKeys,
    defaultRowsPerPage,
    defaultPage,
    ticketExtraColumns,
    isToShowEditTicket,
} from './TicketController';
import { LineClampComponent } from './TicketTableComponent';
import { getDateWithISOFormat, readFilterQueryParams, transformQueryFilters, updateQueryParams, validateDate, validateFilterNumber } from '../../utils/filter';
import APIFilterPopup from '../../common/APIFilters/FilterPopup';
import { useState } from 'react';
import { GetQueryParam, useQuery, validateStringValue } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import { PartnerDetailsPopUp } from './TodayStatus/PartnerDetailsPopUp';
import { AssetsColumn } from './TicketColumns/Asset';
import { RemarkColumn } from './TicketColumns/Remark';
import { PartnerDetail } from './TicketColumns/Partner';
import { ProgressBar } from './TicketColumns/Progress';
import { StatusColumn } from './TicketColumns/Status';
import { TicketAction } from './TicketColumns/Actions';
import { Price } from './TicketColumns/Price';

import FrozenTable from '../../common/FrozenTable';
import { tickets } from '../../types';
import { Shipping } from './TicketColumns/Shipping';

import SiteColumn from './TicketColumns/Sites';
import TicketNumber from './TicketColumns/TicketNumber';
import Contact from './TicketColumns/Contact';
import production from '../../config/env/production';
import config from '../../config';


export const ticketFilterSchema = {
    city: {
        filterName: 'cities',
        title: 'City',
        typeOfFilter: 'checkbox',
        apiValueKey: 'city_id',
        displayValueKey: 'city_name',
        submitKey: 'cityIds',
        queryParamKey: 'cityIds',
        parse: ({ cityIds }) => {
            let values = validateFilterNumber(cityIds);
            if (values) return { cityIds: values };
            return {};
        },
        sieveForFilterItems: ({ currentFilter, filterList }) => {
            let filteredItems;
            if (currentFilter.states) {
                const states = currentFilter.states.filter((state) => state.isSelected).map((state) => state.apiValue);
                const cities = filterList.cities.filter((city) => states.includes(city.fk_state_id));
                filteredItems = cities;
            }
            return filteredItems;
        },
    },
    cityTier: {
        filterName: 'cityTier',
        title: 'City Tier',
        typeOfFilter: 'checkbox',
        apiValueKey: 'key',
        displayValueKey: 'label',
        submitKey: 'cityTiers',
        queryParamKey: 'cityTiers',
    },
    state: {
        filterName: 'states',
        title: 'States',
        typeOfFilter: 'checkbox',
        apiValueKey: 'state_id',
        displayValueKey: 'state_name',
        submitKey: 'stateIds',
        queryParamKey: 'stateIds',
        parse: ({ stateIds }) => {
            let values = validateFilterNumber(stateIds);
            if (values) return { stateIds: values };
            return {};
        },
    },
    localities: {
        filterName: 'localities',
        title: 'Localities',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'name',
        submitKey: 'localityIds',
        queryParamKey: 'localityIds',
        parse: ({ localityIds }) => {
            let values = validateFilterNumber(localityIds);
            if (values) return { localityIds: values };
            return {};
        },
        sieveForFilterItems: ({ currentFilter, filterList }) => {
            let filteredItems;
            if (currentFilter.states) {
                const states = currentFilter.states.filter((state) => state.isSelected).map((state) => state.apiValue);
                const localities = filterList.localities.filter((loc) => states.includes(loc.fk_state_id));
                filteredItems = localities;
            }
            if (currentFilter.cities) {
                const cities = currentFilter.cities.filter((state) => state.isSelected).map((state) => state.apiValue);
                const localities = filterList.localities.filter((loc) => cities.includes(loc.fk_city_id));
                filteredItems = localities;
            }
            return filteredItems;
        },
    },
    districts: {
        filterName: 'districts',
        title: 'Districts',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'name',
        submitKey: 'districtIds',
        queryParamKey: 'districtIds',
        parse: ({ districtIds }) => {
            let values = validateFilterNumber(districtIds);
            if (values) return { districtIds: values };
            return {};
        },
        sieveForFilterItems: ({ currentFilter, filterList }) => {
            let filteredItems;
            if (currentFilter.states) {
                const states = currentFilter.states.filter((state) => state.isSelected).map((state) => state.apiValue);
                const districts = filterList.districts.filter((dist) => states.includes(dist.fk_state_id));
                filteredItems = districts;
            }
            return filteredItems;
        },
    },
    projects: {
        filterName: 'projects',
        title: 'Projects',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'project_name',
        submitKey: 'projectIds',
        queryParamKey: 'projectIds',
        parse: ({ projectIds }) => {
            let values = validateFilterNumber(projectIds);
            if (values) return { projectIds: values };
            return {};
        },
    },
    ticketStatus: {
        filterName: 'ticketStatus',
        title: 'Status',
        typeOfFilter: 'checkbox',
        apiValueKey: 'key',
        displayValueKey: 'label',
        submitKey: 'labels',
        queryParamKey: 'labels',
    },
    ticketStatusSpFe: {
        filterName: 'ticketStatusSpFe',
        title: 'Status',
        typeOfFilter: 'checkbox',
        apiValueKey: 'key',
        displayValueKey: 'label',
        submitKey: 'labels',
        queryParamKey: 'labels',
    },
    customer: {
        filterName: 'customers',
        title: 'Customer',
        typeOfFilter: 'checkbox',
        apiValueKey: 'customer_id',
        displayValueKey: 'customer_company_name',
        submitKey: 'customerIds',
        queryParamKey: 'customerIds',
        parse: ({ customerIds }) => {
            let values = validateFilterNumber(customerIds);
            if (values) return { customerIds: values };
            return {};
        },
    },
    endUser: {
        filterName: 'endUsers',
        title: 'Enduser',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'end_user_name',
        submitKey: 'endUserIds',
        queryParamKey: 'enduserIds',
        parse: ({ enduserIds }) => {
            let values = validateFilterNumber(enduserIds);
            if (values) return { enduserIds: values };
            return {};
        },
    },
    partners: {
        filterName: 'partners',
        title: 'SP',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: (user) => `${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}`,
        submitKey: 'spIds',
        queryParamKey: 'spIds',
        parse: ({ spIds }) => {
            let values = validateFilterNumber(spIds);
            if (values) return { spIds: values };
            return {};
        },
    },
    engineers: {
        filterName: 'engineers',
        title: 'FE',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: (user) => `${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}`,
        submitKey: 'engineerIds',
        queryParamKey: 'engineerIds',
        parse: ({ engineerIds }) => {
            let values = validateFilterNumber(engineerIds);
            if (values) return { engineerIds: values };
            return {};
        },
    },
    clusters: {
        filterName: 'clusters',
        title: 'Clusters',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: (cluster) => `${validateStringValue(cluster.first_name)} ${validateStringValue(cluster.last_name)}`,
        submitKey: 'clusterIds',
        queryParamKey: 'clusterIds',
        parse: ({ clusterIds }) => {
            let values = validateFilterNumber(clusterIds);
            if (values) return { clusterIds: values };
            return {};
        },
    },
    psp: {
        filterName: 'pspDetails',
        title: 'PSP',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: (psp) => `${validateStringValue(psp.first_name)} ${validateStringValue(psp.last_name)}`,
        submitKey: 'pspIds',
        queryParamKey: 'pspIds',
        parse: ({ pspIds }) => {
            let values = validateFilterNumber(pspIds);
            if (values) return { pspIds: values };
            return {};
        },
    },
    supervisor: {
        filterName: 'supervisorDetails',
        title: 'Supervisor',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: (supervisor) => `${validateStringValue(supervisor.first_name)} ${validateStringValue(supervisor.last_name)}`,
        submitKey: 'supervisorIds',
        queryParamKey: 'supervisorIds',
        parse: ({ supervisorIds }) => {
            let values = validateFilterNumber(supervisorIds);
            if (values) return { supervisorIds: values };
            return {};
        },
    },
    invoiceGenerated: {
        filterName: 'invoiceGenerated',
        title: 'Invoice Generated',
        typeOfFilter: 'checkbox',
        apiValueKey: 'key',
        displayValueKey: 'label',
        submitKey: 'invoiceGenerated',
        queryParamKey: 'invoiceGenerated',
    },
    ticketType: {
        filterName: 'ticketTypes',
        title: 'Ticket Type',
        typeOfFilter: 'checkbox',
        apiValueKey: 'key',
        displayValueKey: 'label',
        submitKey: 'ticketTypes',
        queryParamKey: 'ticketType',
    },
    executionDate: {
        filterName: 'executionDate',
        title: 'Execution Date',
        typeOfFilter: 'daterange',
        submitKey: ({ eFromDate, eToDate } = {}) => ({
            executionDate: { fromDate: getDateWithISOFormat(eFromDate), toDate: getDateWithISOFormat(eToDate) },
        }),
        queryParamKey: {
            keyList: ['eFromDate', 'eToDate'],
            getQueryObject: ({ fromDate, toDate }) => ({ eFromDate: fromDate, eToDate: toDate }),
        },
        parse: ({ eFromDate, eToDate }) => {
            let validatedFrom = validateDate(eFromDate, { cutoffDate: false });
            let validatedTo = validateDate(eToDate, { cutoffDate: false });
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { eFromDate: validatedFrom, eToDate: validatedTo };
            } else if (validatedFrom) {
                return { eFromDate: validatedFrom, eToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ eFromDate, eToDate } = {}) => ({
            fromDate: getDateWithISOFormat(eFromDate),
            toDate: getDateWithISOFormat(eToDate),
        }),
    },
    orderDate: {
        filterName: 'orderDate',
        title: 'Created At',
        typeOfFilter: 'daterange',
        submitKey: ({ oFromDate, oToDate } = {}) => ({
            orderDate: { fromDate: getDateWithISOFormat(oFromDate), toDate: getDateWithISOFormat(oToDate) },
        }),
        queryParamKey: {
            keyList: ['oFromDate', 'oToDate'],
            getQueryObject: ({ fromDate, toDate }) => ({ oFromDate: fromDate, oToDate: toDate }),
        },
        parse: ({ oFromDate, oToDate }) => {
            let validatedFrom = validateDate(oFromDate, { cutoffDate: false });
            let validatedTo = validateDate(oToDate, { cutoffDate: false });
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { oFromDate: validatedFrom, oToDate: validatedTo };
            } else if (validatedFrom) {
                return { oFromDate: validatedFrom, oToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ oFromDate, oToDate } = {}) => ({
            fromDate: getDateWithISOFormat(oFromDate),
            toDate: getDateWithISOFormat(oToDate),
        }),
    },
    siteComplete: {
        filterName: 'siteComplete',
        title: 'Site Complete',
        typeOfFilter: 'daterange',
        submitKey: ({ scFromDate, scToDate } = {}) => ({
            siteCompletedOn: { fromDate: getDateWithISOFormat(scFromDate), toDate: getDateWithISOFormat(scToDate) },
        }),
        queryParamKey: {
            keyList: ['scFromDate', 'scToDate'],
            getQueryObject: ({ fromDate, toDate }) => ({ scFromDate: fromDate, scToDate: toDate }),
        },
        parse: ({ scFromDate, scToDate }) => {
            let validatedFrom = validateDate(scFromDate, { cutoffDate: false });
            let validatedTo = validateDate(scToDate, { cutoffDate: false });
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { scFromDate: validatedFrom, scToDate: validatedTo };
            } else if (validatedFrom) {
                return { scFromDate: validatedFrom, scToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ scFromDate, scToDate } = {}) => ({
            fromDate: getDateWithISOFormat(scFromDate),
            toDate: getDateWithISOFormat(scToDate),
        }),
    },
    signOffApprovalDate: {
        filterName: 'signOffApprovalDate',
        title: 'Signoff Approval Date',
        typeOfFilter: 'daterange',
        submitKey: ({ saFromDate, saToDate } = {}) => ({
            signOffApprovalDate: { fromDate: getDateWithISOFormat(saFromDate), toDate: getDateWithISOFormat(saToDate) },
        }),
        queryParamKey: {
            keyList: ['saFromDate', 'saToDate'],
            getQueryObject: ({ fromDate, toDate }) => ({ saFromDate: fromDate, saToDate: toDate }),
        },
        parse: ({ saFromDate, saToDate }) => {
            let validatedFrom = validateDate(saFromDate, { cutoffDate: false });
            let validatedTo = validateDate(saToDate, { cutoffDate: false });
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { saFromDate: validatedFrom, saToDate: validatedTo };
            } else if (validatedFrom) {
                return { saFromDate: validatedFrom, saToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ saFromDate, saToDate } = {}) => ({
            fromDate: getDateWithISOFormat(saFromDate),
            toDate: getDateWithISOFormat(saToDate),
        }),
    },
    assignmentType: {
        filterName: 'assignmentTypes',
        title: 'Assignment Type',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'key',
        submitKey: 'assignmentTypes',
        queryParamKey: 'assignmentTypes',
    },
    issueCategories: {
        filterName: 'issueCategories',
        title: 'Issue Category',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'label',
        submitKey: ({ issueCategoryIds } = {}) => {
            const possibleEvents = [72, 73, 31, 83];
            let eventIds = issueCategoryIds?.filter((value) => possibleEvents.includes(value));
            let categoryIds = issueCategoryIds?.filter((value) => !possibleEvents.includes(value));
            let data = {};
            if (categoryIds?.length > 0) data.issueCategoryIds = categoryIds;
            if (eventIds?.length > 0) data.eventIds = eventIds;
            return data;
        },
        queryParamKey: 'issueCategoryIds',
        parse: ({ issueCategoryIds }) => {
            let values = validateFilterNumber(issueCategoryIds);
            if (values) return { issueCategoryIds: values };
            return {};
        },
    },
    agent: {
        filterName: 'agent',
        title: 'Agent',
        typeOfFilter: 'checkbox',
        apiValueKey: 'value',
        displayValueKey: 'label',
        submitKey: 'agentIds',
        queryParamKey: 'agentIds',
        parse: ({ agentIds }) => {
            let values = validateFilterNumber(agentIds);
            if (values) return { agentIds: values };
            return {};
        },
    },
};

export const feTypeLookup = {
    [scheduledTab.path]: 'fe_scheduled_tickets',
    [allTab.path]: '',
    [ongoingTab.path]: 'fe_ongoing_tickets',
    [IssuesTab.path]: 'fe_onhold_tickets',
    [completedTab.path]: 'fe_completed_tickets',
};

export const spTypeLookup = {
    [newAndCustomerTab.path]: 'new',
    [allTab.path]: 'sp_all_tickets',
    [ongoingTab.path]: 'sp_ongoing_tickets',
    [IssuesTab.path]: 'sp_onhold_tickets',
    [completedTab.path]: 'sp_completed_tickets',
};

export const getTicketTabNameOrSortBy = (role, type, tabName) => {
    let sortBy = 'created_at';
    let tab = tabName;
    if (isCluster(role, type) || isSuperVisor(role, type)) {
        sortBy = 'broadcast_timestamp';
        if (tabName === newAndCustomerTab.path) {
            tab = triggerTab.path;
        }
    }
    return { openedTab: tab, sortBy };
};

export const TERMINAL_COLOR = {
    CONNECTED: 'text-scogo2e',
    DISCONNECTED: 'text-scogoclosed',
    WORK_IN_PROGRESS: 'text-scogoorange',
};

const TicketsTable = forwardRef((props, ref) => {
    const { selectedColumns, tabName, setClearFilterButton, searchQueryParam, limitQueryParam, pageQueryParam, queryParams, openedSubTab, openedMaintab, page, rowsPerPage, onINeedRMASupportClick } =
        props;
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const { isTicketListLoading, isTicketFilterListLoading, ticketAssetRemarkLoading } = useSelector((state) => state.loading);
    const { ticketList, filterTicketCount, openedTicketTabCount, ticketFilterList, ticketRemoteTerminal } = useSelector((state) => state.tickets);
    let allTicketsCount = filterTicketCount?.[tabName];
    if (openedSubTab === tabName) {
        allTicketsCount = openedTicketTabCount >= 0 ? openedTicketTabCount : filterTicketCount?.[tabName];
    }
    const [checkedTicketTracker, setCheckedTicketTracker] = useState(() => new Set());

    const [filterList, setFilterList] = useState({});
    const [filterQueryObject, setFilterObject] = useState({});
    const [filtersActiveStatus, setFiltersActiveStatus] = useState({});
    const query = useQuery();
    const navigate = useNavigate();
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    const isLoggedUserIsCustomer = isCustomerGroup(role, type);
    const projectIdsQueryParam = GetQueryParam('projectIds');

    useEffect(() => {
        return () => setCheckedTicketTracker(new Set());
    }, [isTicketListLoading]);

    useImperativeHandle(ref, () => ({
        getSelectedTickets: () => {
            let selectedTickets = ticketList.filter((_, index) => checkedTicketTracker.has(index));
            return selectedTickets;
        },
    }));

    const handleOnChangeCheckbox = (position) => {
        if (checkedTicketTracker.has(position)) {
            setCheckedTicketTracker((prev) => {
                const next = new Set(prev);
                next.delete(position);
                return next;
            });
        } else {
            setCheckedTicketTracker((prev) => new Set(prev).add(position));
        }
    };

    const selectALLHandler = (checked) => {
        if (checked) {
            const allIndices = ticketList.map((_, index) => index);
            setCheckedTicketTracker(new Set(allIndices));
        } else {
            setCheckedTicketTracker(new Set());
        }
    };

    const callFETicketList = useCallback(
        ({ isFilterApplied, searchTerm, limit, page, payload }) => {
            if (isFilterApplied || searchTerm?.length > 0) {
                dispatch(getTicketFilteredList({ limit, page, payload, query: searchTerm, tab: tabName }));
            } else {
                dispatch(getFEAcceptedTickets({ order_by: 'broadcast_timestamp', type: feTypeLookup[tabName], limit, page }));
            }
        },
        [dispatch, tabName]
    );

    const callSPTicketList = useCallback(
        ({ isFilterApplied, searchTerm, limit = 10, page = 1, payload }) => {
            if (isFilterApplied || searchTerm?.length > 0) {
                dispatch(getTicketFilteredList({ limit, page, payload, query: searchTerm, tab: tabName }));
            } else {
                let payload = {
                    order_by: 'broadcast_timestamp',
                    type: spTypeLookup[tabName],
                    limit,
                    page,
                };
                dispatch(getSPAcceptedTickets(payload));
            }
        },
        [dispatch, tabName]
    );

    const getTicketList = useCallback(
        ({ isFilterApplied, searchTerm, limit, page, payload }) => {
            if (isFilterApplied || searchTerm?.length > 0) {
                dispatch(getTicketFilteredList({ limit, page, payload, query: searchTerm, tab: tabName }));
            } else {
                const { openedTab, sortBy } = getTicketTabNameOrSortBy(role, type, tabName);
                dispatch(getTicketsList({ customerId: loggedUserCustomerId, tab: openedTab, sortBy, limit, page }));
            }
        },
        [dispatch, loggedUserCustomerId, tabName, role, type]
    );

    const callDataOnMount = useCallback(
        ({ searchTerm, limit, page, payload, isFilterApplied }) => {
            if (!limit) limit = defaultRowsPerPage;
            if (!page) page = defaultPage;
            if (isFe(role, type)) {
                callFETicketList({ isFilterApplied, searchTerm, limit, page, payload });
            } else if (isPsp(role, type) || isSp(role, type) || (role === SP.role && type === ISP.type)) {
                callSPTicketList({ isFilterApplied, searchTerm, limit, page, payload });
            } else {
                getTicketList({ searchTerm, limit, page, payload, isFilterApplied });
            }
        },
        [role, type, callFETicketList, callSPTicketList, getTicketList]
    );

    const getFilterList = useCallback(({ toFilter = 'cities,states,customers,endUsers,clusters,supervisor,psp,projects,partners,engineers,districts,localities', projectIds = '' }) => {
        dispatch(getTicketFilterList({ toFilter, projectIds }));
    }, [dispatch])

    useEffect(() => {
        let projectIds = '';
        if (projectIdsQueryParam) projectIds = projectIdsQueryParam.split('^').filter((id) => !isNaN(id)).join(',');
        getFilterList({ projectIds });
    }, [getFilterList, projectIdsQueryParam]);


    useEffect(() => {
        if (projectIdsQueryParam) {
            const firstProjectId = projectIdsQueryParam.split('^').filter((id) => !isNaN(id))[0] || 0;
            const findProject = parseInt(firstProjectId) && ticketFilterList?.projects.find((project) => project.id === parseInt(firstProjectId));
            if (firstProjectId && findProject) {
                dispatch(getIssueCategoriesFilterList({ customerId: findProject.fk_customer_id }));
            }
        }
    }, [dispatch, projectIdsQueryParam, ticketFilterList?.projects]);

    useEffect(() => {
        let { queryObject, filterStatus } = readFilterQueryParams(ticketFilterSchema, query);
        setFilterObject(queryObject);
        setFiltersActiveStatus(filterStatus);
        let isToShowFilterButton = false;
        Object.values(filterStatus).forEach((value) => (value ? (isToShowFilterButton = value) : false));
        setClearFilterButton(isToShowFilterButton);
        let transformedFilter = transformQueryFilters(ticketFilterSchema, queryObject);
        const isProjectSelected = transformedFilter && (!transformedFilter?.[ticketFilterSchema.projects.submitKey] || transformedFilter?.[ticketFilterSchema.projects.submitKey]?.length === 0)
        if (isProjectSelected) {
            delete transformedFilter.eventIds;
            delete transformedFilter.issueCategoryIds;
        }
        callDataOnMount({
            searchTerm: searchQueryParam,
            limit: limitQueryParam,
            page: pageQueryParam,
            payload: transformedFilter,
            isFilterApplied: isToShowFilterButton,
        });
        dispatch(
            setTicketFilterAndPaginationData({
                payload: transformedFilter,
                page: pageQueryParam,
                limit: limitQueryParam,
                query: searchQueryParam,
                tab: openedSubTab,
                mainTab: openedMaintab,
            })
        );

        return () => dispatch({ type: tickets.CLEAR_TICKET_LIST });
    }, [queryParams, searchQueryParam, limitQueryParam, pageQueryParam, setClearFilterButton, query, callDataOnMount, dispatch, openedSubTab, openedMaintab]);

    const projectIds = useMemo(() => {
        const { queryObject, filterStatus } = readFilterQueryParams(ticketFilterSchema, query);
        return filterStatus[ticketFilterSchema.projects.filterName] ? queryObject[ticketFilterSchema.projects.queryParamKey].join(',') : undefined;
    }, [query]);

    useEffect(() => {
        if (loggedUser) {
            dispatch(getTicketCount({ customerId: loggedUserCustomerId, projectIds: projectIds?.split(',') }));
        }
    }, [dispatch, loggedUserCustomerId, loggedUser, projectIds]);

    const updateQuery = ({ filterList, destinationTab = openedSubTab, limit = limitQueryParam, page = pageQueryParam, filterSchema, query = searchQueryParam }) => {
        updateQueryParams({
            navigate,
            destinationTab,
            mainTab: openedMaintab,
            limit,
            page,
            filterList,
            filterSchema,
            query,
            defaultRowsPerPage,
            defaultPage,
        });
    };

    const filterComponent = ({ headName, keysToFilter, disable, disableText }) => (
        <div className='flex text-font12'>
            {headName}
            <APIFilterPopup
                filterList={filterList}
                setFilterList={setFilterList}
                height={15}
                keysToFilter={keysToFilter}
                submit={({ payload, page, filterList }) => updateQuery({ filterSchema: ticketFilterSchema, payload, page, filterList })}
                filtersData={ticketFilterList}
                filterSchema={ticketFilterSchema}
                filterQueryObject={filterQueryObject}
                isLoading={isTicketFilterListLoading}
                filtersActiveStatus={filtersActiveStatus}
                disable={disable}
                disableText={disableText}
            />
        </div>
    );

    const textLabelClass = 'text-font11 text-scogogray';
    const textValueClass = 'text-font10 text-black w-full font-normal';



    const handleTicketNumberCLick = (ticket) => {


        let ticketSideDrawerPayload = {
            title: ticket.job_ticket_number,
            ticket,
        };
        dispatch(setTicketSideDrawer(true, 'ticketDetails', ticketSideDrawerPayload));
    };

    const editTicket = (ticket) => {
        navigate(`/tickets/${ticket.ticket_id}/edit`);
    };

    const showSiteAtFirst = config.name === production.name && loggedUserCustomerId === 3843;

    const getTableSchema = () => {
        let columns = [
            {
                head: {
                    render: (headName, checkedTicketTrackerLocal) => {
                        return (
                            <div className='pl-2 flex items-center '>
                                {!showSiteAtFirst ? <Checkbox
                                    checkColor='text-scogoorange mr-2'
                                    checked={ticketList?.length > 0 && checkedTicketTrackerLocal?.size && ticketList?.length === checkedTicketTrackerLocal?.size}
                                    onChange={(event) => {
                                        selectALLHandler(event);
                                    }}
                                    dynamicSize={'1.2rem'}
                                /> : <></>}
                                {filterComponent({
                                    headName,
                                    keysToFilter: [ticketFilterSchema.executionDate,
                                    ticketFilterSchema.ticketType,

                                    ],

                                })}
                            </div>
                        );
                    },
                    headName: 'Ticket No.',
                    width: window.innerWidth * 0.14,
                    isFixed: true,
                    key: columnsKeys.ticket_id,
                    columnRowCss: 'break-all',
                },
                body: {
                    render: (ticket, rowIndex, checkedTicketTrackerLocal, handleOnChangeCheckboxLocal) => {
                        return (<TicketNumber
                            ticket={ticket}
                            rowIndex={rowIndex}
                            checkedTicketTrackerLocal={checkedTicketTrackerLocal}
                            handleOnChangeCheckboxLocal={handleOnChangeCheckboxLocal}
                            ticketRemoteTerminal={ticketRemoteTerminal}
                            handleTicketNumberCLick={handleTicketNumberCLick}
                            showCheckBox={true && !showSiteAtFirst}
                        />)
                    }
                }
            },
            {
                head: {
                    render: (headName) => {
                        const filters = [ticketFilterSchema.customer, ticketFilterSchema.endUser].filter((filter) => {
                            if (filter.filterName === ticketFilterSchema.customer.filterName) {
                                return !isLoggedUserIsCustomer;
                            }
                            return true;
                        });
                        return filterComponent({
                            headName,
                            keysToFilter: filters,
                        });
                    },
                    headName: 'Customer Details',
                    isFixed: true,
                    width: window.innerWidth * 0.11,
                    key: columnsKeys.customer_details,
                },
                body: {
                    render: (ticket) => {
                        const { ticket_owned_by, assignToScogo, signOffAccept, ticketType } = ticket;
                        const showEditIcon = isToShowEditTicket({ ticket_owned_by, assignToScogo, signOffAccept, ticketType }, { role, type });
                        return (
                            <div className='w-full'>
                                {!isLoggedUserIsCustomer && (
                                    <span className={`max-w-[95%] break-all`}>
                                        <p className={textLabelClass}>Customer </p>
                                        <span className={textValueClass}>
                                            <OverflowTip textClass={`text-inherit ${textValueClass}`} someLongText={ticket.customer_name} />
                                        </span>
                                    </span>
                                )}
                                {ticket.enduser_name && (
                                    <span className={`max-w-[95%] break-all`}>
                                        {isLoggedUserIsCustomer && <p className={textLabelClass}>End User </p>}
                                        <span className={textValueClass}>
                                            <OverflowTip textClass={`text-inherit ${textValueClass}`} someLongText={ticket.enduser_name} />
                                        </span>
                                    </span>
                                )}
                                <span className={`max-w-[95%] break-all`}>
                                    <p className={textLabelClass}>Project </p>
                                    <span className='px-2 bg-scogoprimary text-white flex gap-2 items-center rounded-2xl w-fit max-w-11/12'>
                                        <OverflowTip textClass={`text-inherit ${textValueClass}`} someLongText={ticket.project_name} />
                                        {showEditIcon && (
                                            <>
                                                <span className='text-white'>|</span>
                                                <span
                                                    className='material-icons text-font12 text-white pr-1 cursor-pointer '
                                                    onClick={() => {
                                                        editTicket(ticket);
                                                    }}
                                                >
                                                    edit
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </span>
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName, checkedTicketTrackerLocal) => {
                        return (
                            <div className='pl-2 flex items-center '>
                                {showSiteAtFirst ? <Checkbox
                                    checkColor='text-scogoorange mr-2'
                                    checked={ticketList?.length > 0 && checkedTicketTrackerLocal?.size && ticketList?.length === checkedTicketTrackerLocal?.size}
                                    onChange={(event) => {
                                        selectALLHandler(event);
                                    }}
                                    dynamicSize={'1.2rem'}
                                /> : <></>}
                                {filterComponent({
                                    headName,
                                    keysToFilter: [
                                        ticketFilterSchema.state,
                                        ticketFilterSchema.city,
                                        ticketFilterSchema.districts,
                                        ticketFilterSchema.localities,
                                        ticketFilterSchema.cityTier,
                                    ],
                                })}
                            </div>
                        );
                    },
                    headName: 'Site Details',
                    width: window.innerWidth * 0.14,
                    isFixed: true,
                    columnRowCss: 'break-all',
                    key: columnsKeys.site_details,
                },
                body: {
                    render: (ticket, rowIndex, checkedTicketTrackerLocal, handleOnChangeCheckboxLocal) => <SiteColumn
                        ticket={ticket}
                        rowIndex={rowIndex}
                        checkedTicketTrackerLocal={checkedTicketTrackerLocal}
                        handleOnChangeCheckboxLocal={handleOnChangeCheckboxLocal}
                        handleTicketNumberCLick={handleTicketNumberCLick}
                        showCheckBox={showSiteAtFirst}
                    />,
                },
            },
            {
                head: {
                    render: (headName) => {
                        let keysToFilter = [ticketFilterSchema.ticketStatus];
                        if (isSp(role, type) || isFe(role, type)) {
                            keysToFilter = [ticketFilterSchema.ticketStatusSpFe];
                        }
                        return filterComponent({
                            headName,
                            keysToFilter
                        });
                    },
                    headName: 'Status',
                    width: window.innerWidth * 0.07,
                    hide: !selectedColumns.includes(ticketExtraColumns.current_stataus),
                    key: columnsKeys.current_status,
                    isFixed: true,
                },
                body: {
                    render: (ticket) => {
                        return <StatusColumn ticket={ticket} loggedUserId={loggedUser.id} />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return <div className='text-font12 pl-2'>{headName}</div>;
                    },
                    headName: 'Description',
                    hide: !selectedColumns.includes(ticketExtraColumns.description),
                    width: window.innerWidth * 0.1,
                    key: columnsKeys.ticket_description,
                },
                body: {
                    render: (ticket) => {
                        return (
                            <div className='w-full pl-2'>
                                {ticket.ticket_title && <OverflowTip textClass={'text-font11 text-black'} someLongText={`${ticket.ticket_title}`} />}
                                {ticket.ticket_description && (
                                    <LineClampComponent textCss={textValueClass + ' text-scogogray'} value={ticket.ticket_description} lineClamp='line-clamp-3' />
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return filterComponent({
                            headName,
                            keysToFilter: [ticketFilterSchema.invoiceGenerated],
                        });
                    },
                    headName: 'Price',
                    width: window.innerWidth * 0.09,
                    hide: !selectedColumns.includes(ticketExtraColumns.price),
                    key: columnsKeys.price,
                },
                body: {
                    render: (ticket) => <Price ticket={ticket} />,
                },
            },
            {
                head: {
                    render: (headName) => {
                        let keysToFilter = [ticketFilterSchema.clusters, ticketFilterSchema.psp, ticketFilterSchema.supervisor, ticketFilterSchema.agent];
                        keysToFilter = keysToFilter.filter((key) => {

                            if (key.filterName === ticketFilterSchema.agent.filterName) {
                                return isScm(role, type) || isCustomerGroup(role, type)
                            }
                            if (key.filterName === ticketFilterSchema.psp.filterName) {
                                return !isPsp(role, type)
                            }
                            if (key.filterName === ticketFilterSchema.clusters.filterName) {
                                return !isCluster(role, type)
                            }
                            if (key.filterName === ticketFilterSchema.supervisor.filterName) {
                                return !isSuperVisor(role, type)
                            }
                            return false
                        })

                        return filterComponent({
                            headName,
                            keysToFilter,
                        });
                    },
                    headName: 'Contact Details',
                    width: window.innerWidth * 0.12,
                    hide: !selectedColumns.includes(ticketExtraColumns.contact_details),
                    columnRowCss: 'break-all',
                    key: columnsKeys.contact_details,
                },
                body: {
                    render: (ticket) => {
                        return <Contact ticket={ticket} />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return filterComponent({
                            headName,
                            keysToFilter: [ticketFilterSchema.partners, ticketFilterSchema.engineers, ticketFilterSchema.assignmentType],
                        });
                    },
                    headName: 'Partner Details',
                    hide: !selectedColumns.includes(ticketExtraColumns.partner_details),
                    width: window.innerWidth * 0.1,
                    key: columnsKeys.partner_details,
                },
                body: {
                    render: (ticket) => <PartnerDetail ticket={ticket} loading={ticketAssetRemarkLoading} />,
                },
            },

            {
                head: {
                    render: (headName) => {
                        return filterComponent({
                            headName,
                            keysToFilter: [ticketFilterSchema.orderDate, ticketFilterSchema.siteComplete, ticketFilterSchema.signOffApprovalDate],
                        });
                    },
                    headName: 'Progress',
                    width: window.innerWidth * 0.2,
                    hide: !selectedColumns.includes(ticketExtraColumns.progress),
                    key: columnsKeys.progress,
                },
                body: {
                    render: (ticket) => <ProgressBar ticket={ticket} />,
                },
            },
            {
                head: {
                    render: (headName) => {
                        return <div className='text-font12'>{headName}</div>;
                    },
                    headName: 'Assets',
                    width: window.innerWidth * 0.1,
                    hide: !selectedColumns.includes(ticketExtraColumns.assets),
                    key: columnsKeys.assets,
                },
                body: {
                    render: (ticket) => {
                        return <AssetsColumn ticket={ticket} loading={ticketAssetRemarkLoading} onINeedRMASupportClick={onINeedRMASupportClick} />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return filterComponent({
                            headName,
                            keysToFilter: [ticketFilterSchema.issueCategories],
                            disable: projectIdsQueryParam ? false : true,
                            disableText: 'Select project to apply Issue Category filter',
                        });
                    },
                    headName: 'Remarks',
                    width: window.innerWidth * 0.15,
                    hide: !selectedColumns.includes(ticketExtraColumns.remark),
                    key: columnsKeys.remarks,
                },
                body: {
                    render: (ticket, rowIndex) => (
                        <RemarkColumn
                            remarkList={ticket.remarks}
                            positionClass={rowIndex > 4 ? 'lg:bottom-6 bottom-4' : 'lg:top-6 top-4'}
                            loading={ticketAssetRemarkLoading}
                            job_ticket_number={ticket.job_ticket_number}
                            customerId={ticket.customerId}
                            issue_category={ticket.issue_category}
                            issue_title={ticket.issue_title}
                            ticket_id={ticket.ticket_id}
                        />
                    ),
                },
            },
            {
                head: {
                    render: (headName) => {
                        return <div className='text-font12'>{headName}</div>;
                    },
                    headName: 'Shipping Details',
                    width: window.innerWidth * 0.15,
                    columnRowCss: 'break-all',
                    hide: !selectedColumns.includes(ticketExtraColumns.shipping_details),
                    key: columnsKeys.shipping_details,
                },
                body: {
                    render: (ticket) => {
                        return <Shipping ticket={ticket} />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return <div className='text-font12 m-auto'>{headName}</div>;
                    },
                    headName: 'Actions',
                    width: window.innerWidth * 0.1,
                    key: columnsKeys.action,
                },
                body: {
                    render: (ticket) => {
                        return (
                            <div className='flex justify-center flex-end m-auto'>
                                <TicketAction ticket={ticket} />
                            </div>
                        );
                    },
                },
            },
        ];

        columns = columns.filter((column) => {
            if (column.head.key === columnsKeys.price) {
                return !isFe(role, type) && !column.head.hide;
            }
            return !column.head.hide;
        });

        if (showSiteAtFirst) {
            const siteDetailsIndex = columns.findIndex(column => column.head.key === columnsKeys.site_details);
            if (siteDetailsIndex !== -1) {
                const [siteDetailsColumn] = columns.splice(siteDetailsIndex, 1);
                columns.unshift(siteDetailsColumn);
            }
        };
        return { table: columns };
    };

    const { showTicketSideDrawer } = useSelector((state) => state.tickets);
    return (
        <FrozenTable
            rowsData={ticketList}
            frozenColumnNum={showTicketSideDrawer ? 2 : 4}
            schema={getTableSchema()}
            rowsPerPage={rowsPerPage}
            page={page}
            tablePagination
            count={allTicketsCount}
            tablePaginationHandler={(limit, page) => updateQuery({ limit, page, filterSchema: ticketFilterSchema, filterList })}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            checkedTicketTracker={checkedTicketTracker}
            isLoading={isTicketListLoading}
            tableError='No Tickets Found'
        />
    );
});

export default TicketsTable;
