import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dashboardTab, getSidebar } from './index';
import { SidebarNotification } from '../../common/SidebarNotification';
import { putToConversationStore } from '../../actions/conversations';
import { useDispatch } from 'react-redux';
import { isBranchUser, isCustomer, isScm } from '../../utils/role';
import { isMobileView } from '../../pages/auth/components/NewScogoButton';

const Sidebar = ({ mainChatContanerRef }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        loggedUser: {
            role: { id: role },
            type,
            frontend_controller,
            grafana_url,
            manage_partner_payments
        },
    } = useSelector((state) => state.auth);

    const [togglemenu, setToggleMenu] = useState(true);
    const sidebar = getSidebar(role, type, frontend_controller, grafana_url, manage_partner_payments);

    const togglemenumob = () => {
        setToggleMenu(!togglemenu);
        if (togglemenu) {
            mainChatContanerRef.current.classList.add('mainContainerMobileView')
            mainChatContanerRef.current.classList.remove('mainContainer')
        } else {
            mainChatContanerRef.current.classList.remove('mainContainerMobileView')
            mainChatContanerRef.current.classList.add('mainContainer')
        }

    };

    const showChatGpt = isScm(role, type) || isCustomer(role, type) || isBranchUser(role, type);
    return (
        <>
            <div className='xl:hidden fixed z-75 bottom-2 left-2 bg-scogoprimary h-10 w-10 leading-10 rounded-full' onClick={togglemenumob}>
                <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 mt-2 ml-2 text-gray-200' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h8m-8 6h16' />
                </svg>
            </div>
            {togglemenu ? (
                <div className={`sidebar text-center fixed flex flex-col justify-between`} >
                    <div>
                        {sidebar?.map((item, index) => {
                            const isSelected = location.pathname?.startsWith(item.Linkto);

                            const handleClick = () => {

                                const parsedUrl = new URL(grafana_url);
                                if (parsedUrl.hostname === 'dashboard.scogo.in') {
                                    navigate('/dashboard');
                                    return
                                }
                                else if (grafana_url) window.open(grafana_url, '_blank');
                            }

                            if (item.Linkto === dashboardTab.Linkto) {
                                return <div onClick={handleClick} className="hover:font-medium  hover:text-scogoprimary block 2xl:py-4 py-2 hover:bg-blue-100 cursor-pointer">
                                    <div className="relative">
                                        <img
                                            fill="#ffff"
                                            className="sidebarIcons text-scogoprimary material-icons"
                                            alt=""
                                            src={process.env.PUBLIC_URL + item.imgSrc}
                                        />
                                    </div>
                                    <p className="mt-1 sidebarFont">
                                        {typeof item.name === 'function'
                                            ? item.name(role, type, frontend_controller, manage_partner_payments)
                                            : item.name}
                                    </p>
                                </div>
                            }
                            return (
                                <div key={index} className='relative'>
                                    {isSelected && <span className='border-scogoprimary border-l-4 absolute left-0 h-full'></span>}
                                    <Link
                                        to={item.Linkto}
                                        target={item?.target}
                                        className={isSelected ? 'block 2xl:py-4 py-2 bg-blue-100' : 'block 2xl:py-4 py-2 hover:bg-blue-100'}
                                    >
                                        <div className='relative'>
                                            <img
                                                fill='#ffff'
                                                className={
                                                    isSelected ? 'sidebarIcons text-scogoprimary material-icons ' : 'sidebarIcons text-scogogray material-icons '
                                                }
                                                alt=''
                                                src={process.env.PUBLIC_URL + item.imgSrc}
                                            />
                                            {item.countKey && <SidebarNotification countKey={item.countKey} />}
                                        </div>
                                        <p
                                            className={
                                                isSelected ? 'text-scogoprimary mt-1 font-medium sidebarFont' : 'text-scogogray mt-1 font-medium sidebarFont'
                                            }
                                        >
                                            {typeof item.name === 'function' ? item.name(role, type, frontend_controller, manage_partner_payments) : item.name}
                                        </p>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    {showChatGpt && !isMobileView && <div className='w-full flex justify-center' style={{ marginBottom: isMobileView ? '5rem' : '2rem' }}>
                        <ChatGptSvg />
                    </div>}
                </div >
            ) : null}

        </>
    );
};

export default Sidebar;


const ChatGptSvg = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { messageQueueList } = useSelector((state) => state.conversations);

    const onScogoGptClick = () => {
        navigate('/chats?chatGpt=true')
        dispatch(putToConversationStore({
            openedConversationId: 'scogo_new_chat_created',
            messageQueueList: {
                ...messageQueueList, 'scogo_new_chat_created': []
            }
        }));
    }
    return <div onClick={onScogoGptClick} className='flex justify-center drop-shadow-xl cursor-pointer  hover:-translate-y-1 hover:scale-110 duration-300' >
        <img className='h-16' src={process.env.PUBLIC_URL + '/img/sia_logo.png'} alt='' />
    </div >
}