import React, { useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import ProfileImage from '../components/ProfileImage';
import { DialpadIcon, chatType } from '../ChatController';
import IconToolTip from '../../../common/IconToolTip';
import OverflowTip from '../../../common/OverflowTip';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { TicketAction } from '../../Ticket/TicketColumns/Actions';
import { getWhatsappChatList, getWhatsappOr, updateWhatsappNumber } from '../../../actions/whatsapp';
import Loader from '../../../common/Loader';
import { useDispatch } from 'react-redux';
import { openCustomModal } from '../../../actions/modal';
import WhatsappLinkQrPopup from '../../Modals/WhatsappLinkQrPopup';
import { validateStringValue } from '../../../utils/common';
import SitePopup from '../../../common/Popups/SitePop';
import { TicketSearch } from '../../SupportTrack/RenderTicketCard';
import { isMobileView } from '../../auth/components/NewScogoButton';
import { trackSupportTicket } from '../../../router/roleWisePageRoute';
import { StartorJoinCall } from '../../videomeet/components/StartorJoinCall';
import WhatsappChatList from '../Modals/WhatsappChatList';
import { getTicketOwner, ticketStatusList } from '../../Ticket/TicketController';
import { copyToClipboard } from '../../../actions/utils';
import { getSingleConvoIdFromUserIds } from '../../../utils/utils';
import { sendDataThroughWebsocket } from '../../../actions/websocket';
import { websocketEvents } from '../../../utils/websocket';
import { generateRoomLink } from '../../videomeet/components/constants';
import { enableRoom, getUserCallStatusAction } from '../../../actions/users';
import moment from 'moment';
import { isCustomerGroup } from '../../../utils/role';

const getTicketDetail = (transformTicketDetais, loggedUserRole, loggedUserType, loggedUserId) => {
    if (!transformTicketDetais) return {};
    const ticketStatus = ticketStatusList.find((item) => item.check(transformTicketDetais, { role: loggedUserRole, type: loggedUserType }));
    const siteId =
        transformTicketDetais?.external_site_id?.length > 0
            ? `Site ID: ${transformTicketDetais?.external_site_id}\n`
            : transformTicketDetais?.site_branch_name?.length > 0
                ? `Site Branch Name: ${transformTicketDetais?.site_branch_name}\n`
                : '';
    const branchName = transformTicketDetais?.site_branch_name ? `Branch Name : ${transformTicketDetais.site_branch_name}\n` : '';
    const ticketName = transformTicketDetais?.job_ticket_number ? `Ticket Number: ${transformTicketDetais?.job_ticket_number}\n` : '';
    const feName = transformTicketDetais.accepted_fe_id > 0 ? `${transformTicketDetais?.fe_name}` : '';
    const feMobile = transformTicketDetais.accepted_fe_id > 0 ? `${validateStringValue(transformTicketDetais.fe_mobile)}` : '';
    const engineer = transformTicketDetais.accepted_fe_id > 0 && feName?.length > 0 ? `Engineer: ${feName}, ${feMobile}\n` : '';
    const status = ticketStatus?.label?.length > 0 ? `Status: ${ticketStatus?.label}\n` : '';
    const endUser = transformTicketDetais.enduser_name?.length > 0 ? `End User: ${transformTicketDetais.enduser_name}\n` : '';
    // const remoteTerminal = `Console Url: ${transformTicketDetais?.consoleUrl} \n`;


    const ticketDescription = transformTicketDetais.ticket_description ? `Work to do : ${transformTicketDetais.ticket_description}\n` : '';
    const address = `Address : ${transformTicketDetais.site_address}, ${transformTicketDetais.city}, ${transformTicketDetais.job_fk_pincode_id}\n`;
    const date = transformTicketDetais.execution_date ? `Execution Date : ${moment(transformTicketDetais.execution_date).format('DD MMM YYYY h:mm a')}\n` : '';


    let base_domain = window.location.origin;
    if (getTicketOwner({ ticket_owned_by: transformTicketDetais?.ticket_owned_by, assign_to_scogo: transformTicketDetais?.assignToScogo }) === 'Customer' && transformTicketDetais?.domain) base_domain = transformTicketDetais.domain;
    let support_track_url = `${base_domain}${trackSupportTicket.path}?ticketId=${transformTicketDetais?.ticket_id}`;
    const supportTrack = `Click for more detail : ${support_track_url}&shared_by=${loggedUserId}`;


    if (isCustomerGroup(loggedUserRole, loggedUserType)) {
        return `${ticketDescription}${address}${date}${supportTrack}`;
    } else {
        if (transformTicketDetais && transformTicketDetais.accepted_sp_id === 0) return `${ticketName}${ticketDescription}${siteId}${address}${date}${supportTrack}`;
        return `${siteId}${branchName}${ticketName}${engineer}${status}${endUser}${supportTrack}`;
    }
};


export const openTicketSidebar = ({ isOpenExecuteSidebar, navigate }) => {
    let searchParams = new URLSearchParams(window.location.search);
    if (isOpenExecuteSidebar) searchParams.set('isOpenExecuteSidebar', isOpenExecuteSidebar);
    else searchParams.delete('isOpenExecuteSidebar');
    searchParams = searchParams.toString();
    navigate({
        pathname: window.location.pathname,
        search: searchParams,
    });
};

const ChatHeaderMsgLoader = () => {
    const skelton = <Skeleton sx={{ background: 'rgb(237, 235, 235)', borderRadius: '1rem' }} variant='rectangular' width={'full'} height={20} animation='wave' />;
    return (
        <>
            <div className='mr-4'>
                <Skeleton variant='circular'>
                    <Avatar sx={{ height: '3rem', width: '3rem' }} />
                </Skeleton>
            </div>
            <div className='w-2/5'>{skelton}</div>
            <div className='w-16 ml-auto'>{skelton}</div>
        </>
    );
};

export default function Header(props) {
    let { loading, conversationImage, chatName, isSingleChat, icons, showProfileImg, isTicketchat, openedConversationId, showWhatsappicon, showTicketSearch, onTicketModalSearchCancel, onTicketSearhClick, mainChatContanerRef, handleCloseChat = () => { }, isChatPopup, openedConversation, handleChatArrowClick, isChatMinimized, memoizedTicket, openedConversationMessages, unReadMessageId, isSupportTicket, chatGptQueryParams } = props;

    if (memoizedTicket && memoizedTicket.ticket_source === 'SIA_AGENT_TOOL' && memoizedTicket.local_contact_name && !chatGptQueryParams) {
        chatName = memoizedTicket.local_contact_name
    }

    const { isViewTicketByIdLoading } = useSelector((state) => state.loading);
    const { isEnableVCUserLoading } = useSelector((state) => state.loading);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const secondUser = openedConversation?.conversants?.find?.((user) => user.userId !== loggedUser.id);
    const ticketDetailsToCopy = getTicketDetail(memoizedTicket, loggedUser.role.id, loggedUser.type, loggedUser.id);

    const whatsappChatListModal = useCallback(() => {
        dispatch(
            openCustomModal({
                heading: `Forward To WhatsApp `,
                modalWidth: '30vw',
                modalHeight: 'auto',
                headerComponent: <div className='flex items-center gap-2' >
                    <DialpadIcon />

                    <IconToolTip title="Refresh" onClick={() => {
                        let data = {
                            type: "REFERSH_WHATSAPP_LIST",
                        }
                        if (openedConversation?.type === chatType.ticket) {
                            data.ticketId = openedConversation?.conversationId
                        } else if (openedConversation?.type === chatType.group) {
                            data.messageId = openedConversationId
                        }
                        else {
                            data.messageId = openedConversationId
                        }

                        dispatch(getWhatsappChatList(data))
                    }}>
                        <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'  >
                            refresh
                        </span>

                    </IconToolTip>
                </div>,
                modalComponent: <WhatsappChatList ticketDetail={ticketDetailsToCopy} openedConversationId={openedConversationId} />,
            })
        );
    }, [dispatch, openedConversation?.conversationId, openedConversation?.type, openedConversationId, ticketDetailsToCopy])

    const copyTicketDetaisValue = useCallback(() => {
        dispatch(copyToClipboard({ data: ticketDetailsToCopy }));
    }, [dispatch, ticketDetailsToCopy]);

    if (loading) return <ChatHeaderMsgLoader />;
    const backToChatList = () => {
        if (isMobileView && mainChatContanerRef && mainChatContanerRef.current) {
            mainChatContanerRef.current.classList.add('mobileChatListView')
            mainChatContanerRef.current.classList.remove('mobileChatMessageView')
        }
    }


    const viewUserDetail = () => {
        if (isMobileView && mainChatContanerRef && mainChatContanerRef.current) {
            mainChatContanerRef.current.classList.add('mobileUserDetailView')
            mainChatContanerRef.current.classList.remove('mobileChatMessageView')
        }

    };
    const isSupportTrackPath = pathname === trackSupportTicket.path;

    const singleConversationId = getSingleConvoIdFromUserIds(loggedUser?.id, secondUser?.userId);

    const handleOnSuccess = async ({ room_id, conversationId }) => {
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
        dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.videoMeetInvite, data: { userIds: [secondUser?.userId], videoMeetDetails: { videoMeetLink: generateRoomLink(room_id, conversationId), room_id: room_id } } }));
        window.open(generateRoomLink(room_id, conversationId), "_blank");
    }

    const handleEnableRoom = () => {
        dispatch(enableRoom({ userId: loggedUser?.id, conversationId: singleConversationId, onSuccess: handleOnSuccess, buttonName: `singlechat-scogo-meet-${secondUser?.userId}` }));
    }

    return (
        <div className='flex w-full items-center bg-slate-200  ' >
            {isMobileView && !isSupportTrackPath &&
                <span onClick={() => backToChatList()} className='material-icons font-bold '>chevron_left</span>
            }
            {showProfileImg ? (
                <div className='flex '>
                    <div onClick={() => viewUserDetail()} className={`mr-1 inline-block object-cover ${isChatPopup ? 'h-7 w-7 mb-4 ' : 'h-10 w-12'} text-center leading-10`}>

                        {chatGptQueryParams ?
                            <img className='h-10' src={process.env.PUBLIC_URL + '/img/sia_logo.png'} alt='' />
                            : <ProfileImage
                                imgUrl={conversationImage}
                                className={`inline-block object-cover  ${isChatPopup ? 'h-7 w-7' : 'h-10 w-12'} rounded-full text-center leading-10`}
                                type={isSingleChat ? 'single' : 'group'}
                            />}
                    </div>

                </div>

            ) : (
                <></>
            )}
            <div className={`   md:w-[69%] w-[50%] ${isChatPopup ? 'text-font12 ' : 'text-font18 '} font-small `}>
                <div className='max-w-full flex items-center gap-x-2 '>
                    <div className='truncate' onClick={isChatPopup ? onTicketModalSearchCancel : () => { }}>
                        <OverflowTip someLongText={chatName} />
                    </div>
                    {!isChatPopup && (isViewTicketByIdLoading ? (
                        <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={120} height={15} animation='wave' />
                    ) : (
                        <SiteDetails openedConversationId={openedConversationId} isChatPopup={isChatPopup} />
                    ))}
                    {isTicketchat && (
                        <IconToolTip title={'Ticket Details'}>
                            <span
                                className={`material-icons text-scogobgsky hover:text-scogoorange  ${isChatPopup ? 'text-font12' : 'text-font16'} cursor-pointer`}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    copyTicketDetaisValue();
                                }}
                            >
                                content_copy
                            </span>
                        </IconToolTip>

                    )}
                    {isChatPopup && <div className=''>

                        {openedConversationMessages?.map((msg, index) => {
                            return (
                                <div className='rounded-full bg-scogoorange text-white px-2 '>
                                    {unReadMessageId === msg._id && (isNaN(openedConversation?.unreadMessageCount) ? 0 : openedConversation.unreadMessageCount)}


                                </div>

                            );
                        })}
                    </div>}
                    {(showWhatsappicon && !isChatPopup && !isSupportTicket) && <WhatsappIconForChat whatsappChatListModal={whatsappChatListModal} isChatPopup={isChatPopup} />}
                    {isTicketchat && !isChatPopup && !isSupportTicket && <StartorJoinCall />}

                    {isSingleChat && secondUser &&
                        <>
                            {
                                isEnableVCUserLoading ?
                                    <Loader size='15' />
                                    :
                                    <IconToolTip title={'Start Scogo Connect'} onClick={handleEnableRoom}>
                                        <div className="flex items-center w-7 h-7">
                                            <img src={process.env.PUBLIC_URL + '/img/scogo-connect-blue.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                                        </div>
                                    </IconToolTip>
                            }
                        </>
                    }
                </div>
            </div>

            {showTicketSearch ? <div className='ml-auto flex gap-2'>
                <div className='renderSearchIcon'>
                    <TicketSearch mainDivClass='flex items-center gap-2 my-8 ' />
                </div>
                <div className='ml-auto flex items-center gap-2'>
                    <span onClick={onTicketSearhClick} className='renderChatIcon material-icons cursor-pointer max-w-max text-font24'>search</span>
                    {/* <div className='renderChatIcon'>
                        isSuppportTrackMessagesElemVisible && <p onClick={onTicketModalSearchCancel} className='ml-auto flex flex-col items-center hover:text-scogoorange text-scogoprimary py-1 justify-center'>
                            <span className='material-icons cursor-pointer max-w-max text-font20'>info</span>
                        </p>
                    </div> */}
                </div>
            </div> : <div className='w-[30%] flex gap-2 items-center justify-end'>
                {!isChatMinimized && <div className='flex items-center gap-2'>
                    <div className={` flex items-center ml-auto cursor-pointer gap-5 `}>{icons()}</div>
                    {isTicketchat ? <TicketActionIcon memoizedTicket={memoizedTicket} isChatPopup={isChatPopup} /> : <></>}
                </div>}
            </div>
            }
            {isChatPopup &&
                <div className='flex items-center'>
                    <IconToolTip title={'Close Chat'}>
                        <>
                            <span class="material-icons cursor-pointer  rounded-full hover:bg-slate-200 " onClick={(event) => {
                                handleChatArrowClick();
                            }}>

                                remove
                            </span>
                        </>

                    </IconToolTip>

                    <span onClick={(event) => {
                        event.stopPropagation();
                        handleCloseChat(openedConversationId, chatName);
                    }} class="material-icons cursor-pointer text-right hover:text-scogoorange text-font15  ">
                        clear
                    </span></div>}
        </div>
    );
};

const TicketActionIcon = ({ memoizedTicket, isChatPopup }) => {
    const { isViewTicketByIdLoading } = useSelector((state) => state.loading);
    if (isViewTicketByIdLoading || !memoizedTicket) {
        return <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={20} height={15} animation='wave' />;
    }

    return <TicketAction ticket={memoizedTicket} tab={'ticketChat'} isChatPopup={isChatPopup} />;
};


export const WhatsappIconForChat = ({ whatsappChatListModal, title = "Forward ticket details to whatsapp", isChatPopup }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { whatsappLinkDetails } = useSelector((state) => state.whatsapp)
    const { loggedUser } = useSelector((state) => state.auth)
    const { formButtonLoading } = useSelector((state) => state.loading);
    const formName = "WhatsappQR_number_loading"

    return (
        <>
            {
                formButtonLoading[formName] ?
                    <div className='mr-3' >
                        <Loader color='#F48A21' size='15' speed='2' thickness='3' marginTop='' />
                    </div> :
                    whatsappLinkDetails?.status === 1 ?
                        <IconToolTip title={title}>
                            <span
                                className={'text-font18 mr-2 cursor-pointer'}
                                onClick={() => whatsappChatListModal()}
                            >
                                <img src={process.env.PUBLIC_URL + "/img/whatsapp.png"} alt="" className={`${isChatPopup ? 'w-6' : 'w-8'}`} />
                            </span>
                        </IconToolTip>
                        :
                        <IconToolTip title={`Link your whatsapp`}>
                            <span
                                className='text-font18  hover:text-scogoorange mr-2 cursor-pointer'
                                onClick={() => {
                                    if (loggedUser?.is_mobile_linked_with_whatsapp === 0) {
                                        let payload = {
                                            userId: loggedUser?.id,
                                            mobileNumber: loggedUser.mobile,
                                            verifyType: "PRIMARY",
                                            navigate,
                                            formName,
                                            openModal: true
                                        };
                                        dispatch(updateWhatsappNumber(payload));
                                    } else {
                                        dispatch(
                                            openCustomModal({
                                                heading: `Connect Your WhatsApp`,
                                                modalWidth: '32vw',
                                                modalHeight: 'auto',
                                                modalComponent: <WhatsappLinkQrPopup />,
                                                headerComponent: <div className='flex items-center gap-2' >

                                                    <IconToolTip title="Refresh" onClick={() => {
                                                        dispatch(getWhatsappOr({ openModal: true }))
                                                    }}>
                                                        <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'  >
                                                            refresh
                                                        </span>
                                                    </IconToolTip>
                                                </div>,
                                            })
                                        );
                                    }
                                }}
                            >
                                <img src={process.env.PUBLIC_URL + "/img/whatsapp.png"} alt="" className='w-8' style={{ filter: "saturate(0%)" }} />
                            </span>
                        </IconToolTip>
            }
        </>

    );
};

const SiteDetails = ({ openedConversationId, isChatPopup }) => {
    const { viewTicket } = useSelector((state) => state.tickets);
    const { conversationDetails } = useSelector((state) => state.conversations)
    const openedConversation = conversationDetails?.[openedConversationId];
    const address = `${validateStringValue(viewTicket?.job_site_address_1)} ${validateStringValue(viewTicket?.job_city?.city_name)} , ${validateStringValue(
        viewTicket?.job_state?.state_name
    )} `;

    if ((viewTicket?.external_site_id?.length > 0 || viewTicket?.site_branch_name?.length > 0) && openedConversation?.type === chatType.ticket) {
        return (
            <p className='max-w-[45%] truncate' >
                {address?.length > 2 ? (
                    <SitePopup siteInfo={address}>
                        <OverflowTip someLongText={`(${viewTicket?.external_site_id || viewTicket?.site_branch_name})`} textClass="text-font11 text-scogogray hover:text-scogoorange cursor-pointer " />

                    </SitePopup>
                ) : (
                    <OverflowTip someLongText={`(${viewTicket?.external_site_id || viewTicket?.site_branch_name})`} textClass="text-font11 text-scogogray " />
                )}
            </p>
        );
    }
    return <></>;
};