export const CHECK_USER_LOADING = 'CHECK_USER_LOADING';
export const SET_OTP_BUTTON_LOADING = 'SET_OTP_BUTTON_LOADING';
export const REQUEST_OTP_LOADING = 'REQUEST_OTP_LOADING';
export const SEND_FORGOT_PASSWORD_EMAIL_LOADING = 'SEND_FORGOT_PASSWORD_EMAIL_LOADING';
export const SET_PASSWORD_BUTTON_LOADING = 'SET_PASSWORD_BUTTON_LOADING';
export const SET_CHAT_LIST_LOADING = 'SET_CHAT_LIST_LOADING';
export const SET_NEXT_CONVERSATIONS_LOADING = 'SET_NEXT_CONVERSATIONS_LOADING';
export const SET_CONVERSTAION_MESSAGES_LOADING = 'SET_CONVERSTAION_MESSAGES_LOADING';
export const SET_CONVERSATION_DETAILS_LOADING = 'SET_CONVERSATION_DETAILS_LOADING';

export const SET_HELP_SUPPORT_LOADING = 'SET_HELP_SUPPORT_LOADING';
export const PO_DETAILS_LOADING = 'PO_DETAILS_LOADING';

export const SET_REPLY_CONVERSTAION_MESSAGES_LOADING = 'SET_REPLY_CONVERSTAION_MESSAGES_LOADING';

//sites
export const GET_ALL_SITES_LOADING = 'GET_ALL_SITES_LOADING';

//filter sites
export const GET_ALL_FILTER_SITES_LOADING = 'GET_ALL_FILTER_SITES_LOADING';

//assets
export const GET_ALL_ASSETS_LOADING = 'GET_ALL_ASSETS_LOADING';

//filter assets
export const GET_ALL_FILTER_ASSETS_LOADING = 'GET_ALL_FILTER_ASSETS_LOADING';

//warehouse
export const GET_ALL_WAREHOUSE_LOADING = 'GET_ALL_WAREHOUSE_LOADING';
export const SET_NEXT_WAREHOUSE_LIST_LOADING = 'SET_NEXT_WAREHOUSE_LIST_LOADING';

//spareAssets
export const GET_ALL_SPARE_ASSETS_LOADING = 'GET_ALL_SPARE_ASSETS_LOADING';
export const GET_NEXT_SPARE_ASSETS_LOADING = 'GET_NEXT_SPARE_ASSETS_LOADING';

//formButtonLoading
export const FORM_BUTTON_LOADING = 'FORM_BUTTON_LOADING';

// modalbuttnloding
export const CUSTOM_MODAL_LOADING = 'CUSTOM_MODAL_LOADING';

//searchLoading
export const SEARCH_MODULE_DATA_LOADING = 'SEARCH_MODULE_DATA_LOADING';

export const SET_NEXT_CONVERSTAION_MESSAGES_LOADING = 'SET_NEXT_CONVERSTAION_MESSAGES_LOADING';

export const SET_PROJECT_LIST_LOADING = 'SET_PROJECT_LIST_LOADING';

// Wallet
export const SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING = 'SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING';
export const SET_WALLET_REWARDS_TRANSACTIONS_LOADING = 'SET_WALLET_REWARDS_TRANSACTIONS_LOADING';
export const COPY_TRANSACTIONS_DETAILS_LOADING = 'COPY_TRANSACTIONS_DETAILS_LOADING';

// Payments
export const SET_ALL_PAYMENTS_LOADING = 'SET_ALL_PAYMENTS_LOADING';

export const IS_NOTIFICATION_LOADING = 'IS_NOTIFICATION_LOADING';
export const IS_NEXT_NOTIFICATION_LOADING = 'IS_NEXT_NOTIFICATION_LOADING';

export const PARTNERS_LIST_LOADING = 'PARTNERS_LIST_LOADING';

export const SET_EXISTING_CITY_LIST_LOADING = 'SET_EXISTING_CITY_LIST_LOADING';
export const SET_ALL_CITY_LIST_LOADING = 'SET_ALL_CITY_LIST_LOADING';

export const SET_FIELd_ENGINEERS_LOADING = 'SET_FIELd_ENGINEERS_LOADING';

export const SET_SP_PROFILE_DETAILS_LOADING = 'SET_SP_PROFILE_DETAILS_LOADING';

export const SP_ASSIGNED_NEW_TICKETS_LIST_LOADING = 'SP_ASSIGNED_NEW_TICKETS_LIST_LOADING';

export const PROJECT_TEAMS_LIST_LOADING = 'PROJECT_TEAMS_LIST_LOADING';
export const SET_PROJECT_DROPDOWN_LIST_LOADING = 'SET_PROJECT_DROPDOWN_LIST_LOADING';

export const CITIES_LIST_LOADING = 'CITIES_LIST_LOADING';

export const PARTNERS_DROPDOWN_LIST_LOADING = 'PARTNERS_DROPDOWN_LIST_LOADING';

export const ENDUSER_LIST_LOADING = 'ENDUSER_LIST_LOADING';
export const PROJECT_PREQUISITE_LIST_LOADING = 'PROJECT_PREQUISITE_LIST_LOADING';
export const CUSTOMERS_LIST_LOADING = 'CUSTOMERS_LIST_LOADING';
export const USECASES_LIST_LOADING = 'USECASES_LIST_LOADING';
export const SERVICETYPE_LIST_LOADING = 'SERVICETYPE_LIST_LOADING';
export const TEMPLATE_LIST_LOADING = 'TEMPLATE_LIST_LOADING';
export const GET_USER_DETAILS_LOADING = 'GET_USER_DETAILS_LOADING';
export const GET_CUSTOMER_VENDORS_LIST_LOADING = 'GET_CUSTOMER_VENDORS_LIST_LOADING';
export const GET_USERS_RATECARD_LOADING = 'GET_USERS_RATECARD_LOADING';

export const SET_ALL_CITY_LOADING = 'SET_ALL_CITY_LOADING';
export const SET_ALL_STATE_LOADING = 'SET_ALL_STATE_LOADING';
export const SHARE_ID_CARD_LOADING = 'SHARE_ID_CARD_LOADING';
export const SHARE_PUBLIC_PROFILE_LOADING = 'SHARE_PUBLIC_PROFILE_LOADING';
export const GET_WAREHOUSE_OWNER_DROPDOWN_LOADING = 'GET_WAREHOUSE_OWNER_DROPDOWN_LOADING';
export const GET_WAREHOUSE_DROPDOWN_LOADING = 'GET_WAREHOUSE_DROPDOWN_LOADING';
export const GET_SUPERDESK_CAPEX_TYPES_LOADING = 'GET_SUPERDESK_CAPEX_TYPES_LOADING';
export const GET_DROPDOWN_REPLACE_ALL_ASSETS_LOADING = 'GET_DROPDOWN_REPLACE_ALL_ASSETS_LOADING';
export const GET_ALL_ASSET_TYPE_LOADING = 'GET_ALL_ASSET_TYPE_LOADING';
export const GET_ALL_SITES_DROPDOWN_LOADING = 'GET_ALL_SITES_DROPDOWN_LOADING';
export const GET_TOOLS_LOADING = 'GET_TOOLS_LOADING';

export const GET_RESOURCE_LOADING = 'GET_RESOURCE_LOADING';

export const GET_WALLET_TRANSACTIONS_LOADING = 'GET_WALLET_TRANSACTIONS_LOADING';
export const GET_WALLET_NEXT_TRANSACTIONS_LOADING = 'GET_WALLET_NEXT_TRANSACTIONS_LOADING';

export const ASSEST_HISTORY_LOADING = 'ASSEST_HISTORY_LOADING';

export const GET_CUSTOMER_FILTER_LIST_LOADING = 'GET_CUSTOMER_FILTER_LIST_LOADING';

export const GET_SPAREASSETS_FILTER_LIST_LOADING = 'GET_SPAREASSETS_FILTER_LIST_LOADING';
export const GET_WAREHOUSE_FILTER_LIST_LOADING = 'GET_WAREHOUSE_FILTER_LIST_LOADING';

export const SET_PO_TABLE_LOADING = 'SET_PO_TABLE_LOADING';
export const SET_OPEN_TICKETS_PAYMENTS_LOADING = 'SET_OPEN_TICKETS_PAYMENTS_LOADING';

export const SET_IS_PAYOUTS_FILTERLIST_LOADING = 'SET_IS_PAYOUTS_FILTERLIST_LOADING';
export const SET_PO_FILTERLIST_LOADING = 'SET_PO_FILTERLIST_LOADING';
export const SET_IS_REWARDS_AND_PENALTIES_FILTERLIST_LOADING = 'SET_IS_REWARDS_AND_PENALTIES_FILTERLIST_LOADING';
export const SET_IS_WITHDRAWAL_FILTERLIST_LOADING = 'SET_IS_WITHDRAWAL_FILTERLIST_LOADING';

export const VIEW_TICKET_BY_ID_LOADING = 'VIEW_TICKET_BY_ID_LOADING';
export const SET_EXECUTION_DATE_TIMELINE_LOADING = 'SET_EXECUTION_DATE_TIMELINE_LOADING';
export const IS_UPDATE_COURIER_STATUS_LOADING = 'IS_UPDATE_COURIER_STATUS_LOADING';
export const IS_SOW_DOCUMENT_NOT_FOUND_LOADING = 'IS_SOW_DOCUMENT_NOT_FOUND_LOADING';
export const IS_SIGN_OFF_SAMPLE_REPORT_NOT_FOUND_LOADING = 'IS_SIGN_OFF_SAMPLE_REPORT_NOT_FOUND_LOADING';
export const IS_DOWNLOAD_REPORT_LOADING = 'IS_DOWNLOAD_REPORT_LOADING';
export const IS_REGENARATE_REPORT_LOADING = 'IS_REGENARATE_REPORT_LOADING';
export const IS_TICKET_PAYMENT_LOADING = 'IS_TICKET_PAYMENT_LOADING';
export const FIND_SITE_LOADING = 'FIND_SITE_LOADING';
export const GET_ASSET_CONFIG_TEMPLATE_LOADING = 'GET_ASSET_CONFIG_TEMPLATE_LOADING';

export const FIND_CUSTOMER_PROFILR_LOADING = 'FIND_CUSTOMER_PROFILR_LOADING';

export const EDIT_PAYOUT_FORM_LOADING = 'EDIT_PAYOUT_FORM_LOADING';

export const APPROVE_WALLET_LOADING = 'APPROVE_WALLET_LOADING';
export const REJECT_WALLET_LOADING = 'REJECT_WALLET_LOADING';
export const INSTANT_PAY_WALLET_LOADING = 'INSTANT_PAY_WALLET_LOADING';
export const SET_RECEPIENTS_FILTERED_DATA_LOADING = 'SET_RECEPIENTS_FILTERED_DATA_LOADING';

export const GET_WALLET_BALANCE_LOADING = 'GET_WALLET_BALANCE_LOADING';

export const GET_USER_BANK_DETAILS_LOADING = 'GET_USER_BANK_DETAILS_LOADING';

export const GET_CONVERSATION_MEDIA_LOADING = 'GET_CONVERSATION_MEDIA_LOADING';
export const GET_NEXT_CONVERSATION_MEDIA_LOADING = 'GET_NEXT_CONVERSATION_MEDIA_LOADING';
export const IS_UPDATE_CONVERSATION_LOADING = 'IS_UPDATE_CONVERSATION_LOADING';

export const GET_CONVERSATION_INFO_LOADING = 'GET_CONVERSATION_INFO_LOADING';

export const OPEN_SINGLE_CHAT_LOADING = 'OPEN_SINGLE_CHAT_LOADING';

export const GET_SINGLE_CHAT_USER_DETAILS_LOADING = 'GET_SINGLE_CHAT_USER_DETAILS_LOADING';

export const GROUP_USERS_LIST_LOADING = 'GROUP_USERS_LIST_LOADING';
export const TICKET_LIST_LOADING = 'TICKET_LIST_LOADING';

export const FE_INTENTION_LOADING = 'FE_INTENTION_LOADING';

export const REACHED_SITE_STATUS_LOADING = 'REACHED_SITE_STATUS_LOADING';

export const SITE_COMPLETE_STATUS_LOADING = 'SITE_COMPLETE_STATUS_LOADING';

export const TODAY_STATUS_LOADING = 'TODAY_STATUS_LOADING';
export const TICKET_ASSET_REMARK_LOADING = 'TICKET_ASSET_LOA_LOADING';
export const IS_FE_DROPDOWN_LIST_LOADING = 'IS_FE_DROPDOWN_LIST_LOADING';
export const IS_SP_DROPDOWN_LIST_LOADING = 'IS_SP_DROPDOWN_LIST_LOADING';
export const IS_PANCARD_VERIFICATION_LOADING = 'IS_PANCARD_VERIFICATION_LOADING';
export const IS_BUSSINESS_PANCARD_VERIFICATION_LOADING = 'IS_BUSSINESS_PANCARD_VERIFICATION_LOADING';
export const IS_CUSTOMER_ONBOARDING_LOADING = 'IS_CUSTOMER_ONBOARDING_LOADING';
export const IS_CHECK_DOMAIN_AVAILABE_CHECK_LOADING = 'IS_CHECK_DOMAIN_AVAILABE_CHECK_LOADING';
export const IS_GST_VERIFICATION_LOADING = 'IS_GST_VERIFICATION_LOADING';

export const USER_PROFILE_DETAILS_LOADING = 'USER_PROFILE_DETAILS_LOADING';
export const USER_BUSSINESS_DETAILS_LOADING = 'USER_BUSSINESS_DETAILS_LOADING';
export const USER_BANK_DETAILS_LOADING = 'USER_BANK_DETAILS_LOADING';

export const PAY_SECURITY_DEPOSIT_LOADING = 'PAY_SECURITY_DEPOSIT_LOADING';

export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING';

export const GENRATE_OTP_LOADING = 'GENRATE_OTP_LOADING';
export const VERIFY_OTP_LOADING = 'VERIFY_OTP_LOADING';

export const EMAIL_VERIFICATION_LOADING = 'EMAIL_VERIFICATION_LOADING';

export const ADD_GST_LOADING = 'ADD_GST_LOADING';
export const GET_GST_LIST_LOADING = 'GET_GST_LIST_LOADING';
export const GET_GST_LIST_BY_ID_LOADING = 'GET_GST_LIST_BY_ID_LOADING';
export const UPDATE_GST_BY_ID_LOADING = 'UPDATE_GST_BY_ID_LOADING';
export const DELETE_GST_BY_ID_LOADING = 'DELETE_GST_BY_ID_LOADING';

export const SEND_INVITES_LOADING = 'SEND_INVITES_LOADING';
export const SET_IS_OPEN_TICKET_PAYMENT_FILTERLIST_LOADING = 'SET_IS_OPEN_TICKET_PAYMENT_FILTERLIST_LOADING';
export const IS_SPAREASSET_WITH_ASSET_ID_LOADING = 'IS_SPAREASSET_WITH_ASSET_ID_LOADING';
export const GET_TECH_SUPPORT_USERS_LOADING = 'GET_TECH_SUPPORT_USERS_LOADING';
export const SET_ALL_STATES_CITY_LOADING = 'SET_ALL_STATES_CITY_LOADING';

export const IS_TICKET_PO_DETAILS_LOADING = 'IS_TICKET_PO_DETAILS_LOADING';
export const GET_SIGN_OFF_TEMPLATES_LOADING = 'GET_SIGN_OFF_TEMPLATES_LOADING';
export const GET_COURIER_VENDORS_LOADING = 'GET_COURIER_VENDORS_LOADING';
export const GET_BOQ_ASSETS_LOADING = 'GET_BOQ_ASSETS_LOADING';

export const GET_SITE_LIST_FROM_PINCODE_LOADING = 'GET_SITE_LIST_FROM_PINCODE_LOADING';
export const LIST_ISSUE_CATEGORIES_LOADING = 'LIST_ISSUE_CATEGORIES_LOADING';
export const GET_SCOGO_DEALIDS_DROPDOWN_LOADING = 'GET_SCOGO_DEALIDS_DROPDOWN_LOADING';
export const IS_LOADING_GET_ISSUE_CATEGORIES_DROPDOWN = 'IS_LOADING_GET_ISSUE_CATEGORIES_DROPDOWN';

export const GET_TICKET_STATS_LOADING = 'GET_TICKET_STATS_LOADING';
export const LIST_TECH_ENGINEERS_LOADING = 'LIST_TECH_SUPPORT_USERS_LOADING';
export const LIST_SLA_LOADING = 'LIST_SLA_LOADING';

export const ADD_MEMBERS_OR_TEAMS_TO_CHAT_LOADING = 'ADD_MEMBERS_OR_TEAMS_TO_CHAT_LOADING';
export const LIST_RECEIVABLES_GENERATE_INVOICE_LOADING = 'LIST_RECEIVABLES_GENERATE_INVOICE_LOADING';
export const LIST_RECEIVABLES_PROJECT_TICKETS_LOADING = 'LIST_RECEIVABLES_PROJECT_TICKETS_LOADING';

export const LIST_CONVERSATION_SIDEBAR_MEDIA_LOADING = 'LIST_CONVERSATION_SIDEBAR_MEDIA_LOADING';

export const SET_CHAT_NOTIFICATIONS_LOADING = 'SET_CHAT_NOTIFICATIONS_LOADING';
export const SET_CHANGE_NOTIFICATION_SETTINGS_LOADING = 'SET_CHANGE_NOTIFICATION_SETTINGS_LOADING';

export const UPDATE_TICKET_PAYMENT_ALLOWANCE_LOADING = 'UPDATE_TICKET_PAYMENT_ALLOWANCE_LOADING';

export const IS_REVENUES_LIST_LOADING = 'IS_REVENUES_LIST_LOADING';
export const LIST_TICKET_CUSTOMER_REPORT_MEDIA_LOADING = 'LIST_TICKET_CUSTOMER_REPORT_MEDIA_LOADING';

export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';
export const APPROVE_OR_REJECT_MEDIA_LOADING = 'APPROVE_OR_REJECT_MEDIA_LOADING';
export const APPROVE_OR_REJECT_CARD_MESSAGE_LOADING = ' APPROVE_OR_REJECT_CARD_MESSAGE_LOADING';
export const TICKET_FILTER_LIST_LOADING = 'TICKET_FILTER_LIST_LOADING';

export const PROJECT_POST_LOADING = 'PROJECT_POST_LOADING';
export const GET_LINKED_PROJECT_OF_USERS_LOADING = 'GET_LINKED_PROJECT_OF_USERS_LOADING';
export const GET_LINKED_PROJECT_OF_TEAMS_LOADING = 'GET_LINKED_PROJECT_OF_TEAMS_LOADING';

export const PARTNERS_LIST_DETAILS_LOADING = 'PARTNERS_LIST_DETAILS_LOADING';
export const PARTNERS_COUNT_LOADING = 'PARTNERS_COUNT_LOADING';
export const PARTNERS_LIST_COUNT_LOADING = 'PARTNERS_LIST_COUNT_LOADING';
export const SET_FE_PROFILE_DETAILS_LOADING = 'SET_FE_PROFILE_DETAILS_LOADING';
export const WHATSAPP_QR_LOADING = 'WHATSAPP_QR_LOADING';
export const SET_SEARCH_CONVERSTAION_MESSAGES_LOADING = 'SET_SEARCH_CONVERSTAION_MESSAGES_LOADING';
export const AADHAR_VALIDAION_LOADING = 'AADHAR_VALIDAION_LOADING';
export const SET_ALL_LOCALITY_LOADING = 'SET_ALL_LOCALITY_LOADING';
export const SET_ALL_DISTRICT_LOADING = 'SET_ALL_DISTRICT_LOADING';
export const IS_PO_GST_DETAILS_LOADING = 'IS_PO_GST_DETAILS_LOADING';

export const SET_USER_LIST_LOADING = 'SET_USER_LIST_LOADING';
export const WHATSAPP_GROUP_LIST_LOADING = 'WHATSAPP_GROUP_LIST_LOADING';
export const FORWARD_MESSAGE_WHATSAPP_LOADING = 'FORWARD_MESSAGE_WHATSAPP_LOADING';

export const SET_NEXT_USER_LIST_LOADING = 'SET_NEXT_USER_LIST_LOADING';
export const IS_BANK_DETAILS_BY_ID_LOADING = 'IS_BANK_DETAILS_BY_ID_LOADING';
export const AREA_COUNT_LOADING = 'AREA_COUNT_LOADING';
export const GET_WALLET_BALANCE_BY_ID_LOADING = 'GET_WALLET_BALANCE_BY_ID_LOADING';
export const SET_GPT_LIST_LOADING = 'SET_GPT_LIST_LOADING';
export const SET_NEXT_GPT_LIST_LOADING = 'SET_NEXT_GPT_LIST_LOADING';
export const UI_CONFIG_LOADING = 'UI_CONFIG_LOADING';

export const GET_PROJECT_PM_LOADING = 'GET_PROJECT_PM_LOADING';
export const IS_ACTIVE_VOICE_CALL_TICKET_LIST_LOADING = 'IS_ACTIVE_VOICE_CALL_TICKET_LIST_LOADING';
export const ASSET_TAG_DETAILS_LOADING = 'ASSET_TAG_DETAILS_LOADING';
export const SET_IS_SITE_BOQ_LOADING = 'SET_IS_SITE_BOQ_LOADING';

export const IS_ENABLE_VC_USER_LOADING = 'IS_ENABLE_VC_USER_LOADING';
export const IS_DISABLE_VC_USER_LOADING = 'IS_DISABLE_VC_USER_LOADING';

export const SET_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING = 'SET_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING';
export const SET_ENDUSERS_LIST_REQUEST = 'SET_ENDUSERS_LIST_REQUEST';
export const SET_NEXT_ENDUSERS_LIST_REQUEST = 'SET_NEXT_ENDUSERS_LIST_REQUEST';
export const SET_NEXT_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING = 'SET_NEXT_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING';
export const SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING = 'SET_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING';
export const SET_NEXT_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING = 'SET_NEXT_ALL_ASSETS_GROUP_BY_ENDUSER_LOADING';
export const SET_ATTACHMENT_WITH_PARTIAL_URL_LOADING = 'SET_ATTACHMENT_WITH_PARTIAL_URL_LOADING';
export const IS_GET_PROJECT_SLAS_LOADING = 'IS_GET_PROJECT_SLAS_LOADING';
export const GET_SCANNED_ASSETS_LOADING = 'GET_SCANNED_ASSETS_LOADING';
export const GET_USER_WALLET_BALANCE_BY_ID_LOADING = 'GET_USER_WALLET_BALANCE_BY_ID_LOADING';
export const GET_ALL_BRANCH_LIST_LOADING = 'GET_ALL_BRANCH_LIST_LOADING';
export const TRANSFORN_ATTACHMENT_LOADING = 'TRANSFORN_ATTACHMENT_LOADING';
export const IS_CHECK_AND_UPDATE_KYC_STATUS_LOADING = 'IS_CHECK_AND_UPDATE_KYC_STATUS_LOADING';
export const IS_CNERGEE_ASSET_DEVICE_DETAILS_LOADING = 'IS_CNERGEE_ASSET_DEVICE_DETAILS_LOADING';