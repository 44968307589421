import React, { useState, useEffect, useRef, useCallback } from 'react';
import { websocketEvents } from '../../../../utils/websocket';

import { throttle } from 'lodash';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { sendDataThroughWebsocket } from '../../../../actions/websocket';
// https://www.npmjs.com/package/react-mentions
import { MentionsInputBar } from './MentionsInput';
import { useSelector, useDispatch } from 'react-redux';
import { GetQueryParam } from '../../../../utils/common';
import NatsServiceAll from '../../../../utils/nats';
import { useParams } from 'react-router-dom';
import { SIA_CHAT_LIST, SIA_CHAT_MESSAGE } from '../../../../types/conversations';
import config from '../../../../config';
import axios from 'axios';

export default function InputBar({
    inputTextValue,
    onFileSelect,
    handleInputChange,
    onSend,
    allowFileUpload,
    setInputTextValue,
    taggableUsers,
    conversationId,
    replyShow,
    handlePaste,
    showDoubleHash,
    openedConversationId,
    uuid,


}) {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [inputRichValue, setInputRichValue] = useState('');
    const { loggedUser } = useSelector((state) => state.auth);
    const toSend = useRef(true);
    const { isConversationDetailsLoading, isConversationMessagesLoading } = useSelector((state) => state.loading);
    const isInputDisabled = isConversationMessagesLoading[openedConversationId] || isConversationDetailsLoading;
    let inputBarRef = useRef();
    const dispatch = useDispatch();
    const emojiRef = useRef(null);
    const chatGptQueryParams = GetQueryParam('chatGpt', Boolean);
    const { chatId } = useParams();
    const [chatSessionId, setChatSessionId] = useState(uuid)
    const { siaChatList } = useSelector((state) => state.conversations);

    const handleClickOutside = (event) => {
        if (emojiRef.current && !emojiRef.current.contains(event.target)) {
            setShowEmojiPicker(false);
        }
    };
    const cb = (chunk) => {
        const parse = JSON.parse(chunk)


        dispatch({ type: SIA_CHAT_MESSAGE, payload: { msg: { ...parse } } });

        const callList = siaChatList.find(({ SessionId }) => SessionId === chatId);
        if (!callList) {
            axios.get(`${config.api.siaChatUrl}/v1/agents/${loggedUser.id}/cloud-chats`)
                .then((res) => {
                    dispatch({ type: SIA_CHAT_LIST, payload: res.data })
                })
        }
    }
    useEffect(() => {
        inputFocus()
        if (chatId) {
            setChatSessionId(chatId)
        } else {
            setChatSessionId(uuid)
        }

    }, [chatId, uuid])
    useEffect(() => {
        NatsServiceAll.subscribe(`cloud.${chatSessionId}.ai.messages`, cb)
        return () => {
            NatsServiceAll.unsubscribe(`cloud.${chatSessionId}.ai.messages`)
        }
    }, [uuid, chatId, chatSessionId])



    const sendMessageOnclick = () => {
        if (chatGptQueryParams) {

            if (inputRichValue) {
                NatsServiceAll.publish(`cloud.${chatSessionId}.human.messages`, { query: inputRichValue, user: loggedUser })

                dispatch({ type: SIA_CHAT_MESSAGE, payload: { msg: { data: inputRichValue, is_agent: false } } })
                setInputTextValue('')
                setInputRichValue('')
            }

            return;
        }
        if (toSend.current) {
            if (isInputDisabled) return;
            onSend(setInputRichValue);
            inputFocus();
            toSend.current = false;
            setTimeout(() => (toSend.current = true), 500);
        }
    };
    const handleKeyDown = (e) => {
        const isNotInCodeBlock = (inputRichValue?.match?.(/```/g)?.length || 0) % 2 === 0;
        if (e.key === 'Enter' && !e.shiftKey && isNotInCodeBlock) {
            sendMessageOnclick();
            e.preventDefault();
        }
    };

    useEffect(() => {
        setInputRichValue('');
        if (!isInputDisabled) {
            inputFocus();
        }
    }, [conversationId, isInputDisabled]);

    const onEmojiClick = (emoji) => {
        let newInputTextValue = inputTextValue + emoji.native;
        let newInputRichValue = inputRichValue + emoji.native;
        setInputTextValue(newInputTextValue);
        setInputRichValue(newInputRichValue);
        setShowEmojiPicker(false);
        inputFocus();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const inputFocus = () => {
        if (inputBarRef) {
            inputBarRef.current?.focus();
        }
    };

    useEffect(() => {
        if (replyShow) inputFocus();
    }, [replyShow]);

    const fileInput = (accept) => (
        <input disabled={isInputDisabled} accept={accept} type='file' className='absolute cursor-pointer opacity-0 z-5 h-full w-full' multiple onChange={onFileSelect} value='' />
    );

    const fileUploadIcon = (
        <>
            <div className='mr-2 relative cursor-pointer'>
                {fileInput()}
                <span className='material-icons text-scogogray  text-font20 cursor-pointer'>attach_file</span>
                {/* <div className='w-0.5 h-10 -right-2 bg-scogoddd absolute top-0'></div> */}
            </div>
        </>
    );

    const sendTypingEvent = (conversationId) => {
        dispatch(
            sendDataThroughWebsocket({
                action: websocketEvents.chat.typing,
                data: {
                    conversation: conversationId,
                },
            })
        );
    };
    useEffect(() => {
        if (inputBarRef?.current) {
            inputBarRef.current.addEventListener('paste', handlePaste);
        }
        return () => inputBarRef?.current?.removeEventListener('paste', handlePaste);
    }, [handlePaste]);


    const throttleFunTypinEvent = useCallback(throttle(sendTypingEvent, 1000), []);
    return (
        <>
            <div className='  w-full min-h-inherit'>
                <div className='flex items-center min-h-inherit bg-slate-200 justify-center'>
                    <div className='flex items-center justify-center'>
                        <div className='  relative flex items-center justify-center pl-2'>
                            {!showEmojiPicker && (
                                <span
                                    className='material-icons-outlined text-scogogray cursor-pointer  text-font20 hover:text-scogoorange '
                                    onClick={() => !isInputDisabled && setShowEmojiPicker(true)}
                                >
                                    mood
                                </span>
                            )}
                            {showEmojiPicker && <span className='material-icons text-scogogray cursor-pointer text-font20  '>emoji_emotions</span>}

                            {showEmojiPicker && (
                                <div style={{ position: 'fixed', bottom: '100px', zIndex: '50' }} ref={emojiRef} >
                                    <Picker onEmojiSelect={onEmojiClick} data={data} theme='light' />
                                </div>
                            )}
                        </div>
                        <div className=' flex items-center justify-center cursor-pointer pt-1'>{allowFileUpload && fileUploadIcon}</div>
                    </div>

                    <div className='py-2 w-full  ' >
                        <MentionsInputBar
                            isInputDisabled={isInputDisabled}
                            onChange={(e, newRichValue, newPlainTextValue, mentions) => {
                                setInputRichValue(newRichValue.trimStart());
                                handleInputChange(newPlainTextValue.trimStart(), mentions);
                                throttleFunTypinEvent(openedConversationId);
                            }}
                            onKeyDown={handleKeyDown}
                            value={inputRichValue}
                            ref={inputBarRef}
                            taggableUsers={taggableUsers}
                            showDoubleHash={showDoubleHash}
                        />
                    </div>



                    <div className='cursor-pointer px-2 ' onClick={sendMessageOnclick}>
                        <span className='material-icons text-scogogray text-font20 pt-1'>send</span>
                    </div>

                </div>
            </div>
        </>
    );
}
