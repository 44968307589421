import React from 'react';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

export const FormattedGptMessage = ({ message }) => {

    return (
        <div className='text-scogo15 text-font13 pr-6 pl-2 font-normal markdown-table-wrapper'>
            <Markdown
                remarkPlugins={[remarkGfm]} // Enables table rendering
                components={{
                    code({ inline, children, className, ...props }) {
                        // Only render syntax highlighter for code blocks, not for tables
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                            <SyntaxHighlighter
                                // style={'#282a36'}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            >
                                {String(children).replace(/\n$/, "")}
                            </SyntaxHighlighter>
                        ) : (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        );
                    },
                }}
            >
                {message}
            </Markdown>
        </div>
    );
};
