import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { payPO } from '../../../../actions/wallet';
import { BankForm } from './BankForm';
import BankCard from '../../../../common/BankCard';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';

export const payPoFormname = 'payPoFormname';

export const PayBankPopup = ({ poItem, closeMenu = () => { }, isRefresh, enduserId }) => {
    const dispatch = useDispatch();
    const { banks } = useSelector((state) => state.user);
    const { bankDetails } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { vendorId, ticketId, _id, totalPrice } = poItem;

    const payPoData = {
        type: 'BANK_TRANSFER',
        vendorId: vendorId,
        ticketId: ticketId,
        itemId: _id,
        withdrawAmount: totalPrice,
        // COMMENTED BY AMARJEET BANKOPEN
        // banking_partner: 'BANKOPEN',
    };

    const handleBankPay = useCallback(() => {
        dispatch(payPO({ data: payPoData, formName: payPoFormname, isRefresh, callViewTicket: true, onSuccess: closeMenu, enduserId }));
    }, [dispatch, isRefresh, enduserId]);

    const bankAccountHolderName = bankDetails?.sp_bank_account_holder_name ? bankDetails.sp_bank_account_holder_name : '';
    const bankAccountNumber = bankDetails?.sp_bank_account_number ? bankDetails.sp_bank_account_number : '';
    const bankName = bankDetails?.sp_bank_name ? bankDetails.sp_bank_name : '';
    const ifscCode = bankDetails?.sp_bank_ifsc_code ? bankDetails.sp_bank_ifsc_code : '';

    return (
        <div className='py-2 px-2 h-full w-full'>
            <p className='text-scogogray text-font16'>Are you sure you want to pay to bank?</p>
            {bankDetails && (
                <>
                    <BankCard
                        bankName={bankName}
                        ifscCode={ifscCode}
                        bankAccountHolderName={bankAccountHolderName}
                        bankAccountNumber={bankAccountNumber}
                    />
                    <div className='gap-2 flex items-center justify-end mt-8'>
                        <ButtonScogoPrimary textOrComponent='Yes' onClick={() => handleBankPay()} loading={formButtonLoading?.[payPoFormname]} />
                        <ButtonScogoClosedOutlined textOrComponent='No' onClick={() => closeMenu()} />
                    </div>
                </>
            )}
            {!bankDetails && (
                <BankForm
                    isSP
                    callPay
                    payPoData={payPoData}
                    isRefresh={true}
                    closeMenu={closeMenu}
                    bankDetail={bankDetails}
                    banks={banks}
                    vendorId={vendorId}
                    type='ISP'
                />
            )}
        </div>
    );
};