import React, { useRef, useMemo, useCallback, useState, useLayoutEffect, useEffect } from 'react';
import { ChatMsgLoader } from '../messageBox/ChatComponents/ChatMsgLoader';
import SubHeader from './SubHeader';
import Header from './Header';
import Scroller from './Scroller';
import TypingUsers from './TypingUsers';
import MessageInput from '../messageBox/InputBar/MessageInput';
import MessagesList from './MessageList';
import { useSelector, useDispatch } from 'react-redux';
import { chatType, defaultMessageLimit, defaultGroupChatImage } from '../ChatController';
import { openTextModalAction, closeModalAction } from '../../../actions/modal';
import { sendDataThroughWebsocket } from '../../../actions/websocket';
import { useQuery, GetQueryParam } from '../../../utils/common'
import { chats, trackSupportTicket } from '../../../router/roleWisePageRoute';
import { isEndUser, isFe, isScm, isSp } from '../../../utils/role';
import { websocketEvents } from '../../../utils/websocket';
import { getConversationMessages, getNextConversationMessages, setConversationUnreadableMessageCount, setopenedConversationMessageIsLatest, updateunReadMessage, closeHelpSupport } from '../../../actions/conversations';
import _ from 'lodash';
import { useNavigate, useParams, useLocation, useMatch } from 'react-router-dom';
import MaterialIconToolTipComponent from '../../../common/MaterialIconToolTip';
import moment from 'moment';
import { REMOVE_TYPING_IN_CHAT } from '../../../types/conversations';
import { transformTicketforChatAction } from '../../../reducers/tickets';
import { v4 as uuidv4 } from 'uuid';
import { SIA_CHAT_MESSAGE } from '../../../types/conversations';
import axios from 'axios';
import config from '../../../config';
export const scogo_new_chat_created = 'scogo_new_chat_created';

export default function RenderChatMessages({ showTicketSearch = false, onTicketModalSearchCancel = () => { }, onTicketSearhClick = () => { }, isSuppportTrackMessagesElemVisible = false, mainDivClass = '', ticketId = 0, mainChatContanerRef, isSupportTicket = false, showChatHeader = true, handleCloseChat, openedConversationId, forceShowMessageInput = false, isChatPopup, handleChatArrowClick, isChatMinimized, renderChecklists = false, getOpenedConversationsIds, supportTrackDetails }) {
    const messageInputRef = useRef();
    const { isNextConversationMessagesLoading, isConversationMessagesLoading, isReplyConversationMessagesLoading, isViewTicketByIdLoading } = useSelector((state) => state.loading);
    const {
        conversationMessages,
        mpConversationUserTyping,
        messageQueueList,
        conversationDetails,
        openedConversationUnreadableMessageCount,
        openedConversationMessageIsLatest,
        chatList,
        chatTickets,
        latestMessagesIds,
        siaChatMessages
    } = useSelector((state) => state.conversations);
    const { loggedUser } = useSelector((state) => state.auth);
    const { viewTicket } = useSelector((state) => state.tickets);
    let openedConversation = conversationDetails?.[openedConversationId];
    if (isSupportTicket && !openedConversation) openedConversation = ticketId && conversationDetails?.[ticketId];
    const [uuid, setUuid] = useState()
    const dispatch = useDispatch();
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [replyShow, setReplyShow] = useState(false);
    const [replyToMessage, setReplyToMessage] = useState(null);
    const [prevScroll, setPrevScroll] = useState({ messageLength: 0, height: 0, lastMessaseId: 0 });
    const [unReadMessageId, setUnReadMessageId] = useState("");
    const openedQueueMessagesList = messageQueueList[openedConversationId];
    const openedConversationMessagesList = conversationMessages[openedConversationId];
    const scrollDivRef = useRef(null);
    const firstMessageRef = useRef(null);
    const openChat = Array.isArray(chatList) && chatList.find((chat) => chat._id === openedConversationId);
    const messageId = useQuery().get('messageId');
    const messageClickTime = useQuery().get('t');
    const chatGptQueryParams = GetQueryParam('chatGpt', Boolean);
    let { chatId } = useParams();
    const [hideCloseIconTimer, setHideCloseIconTimer] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const openedConversationIdScogoMeet = useQuery().get('openedConversationId');
    if (!chatId) {
        chatId = openedConversationIdScogoMeet;
    }
    const matchChatPage = useMatch(chats.path);


    useEffect(() => {
        if (!chatId && matchChatPage?.pathname === '/chats' && chatGptQueryParams) {
            const uid = uuidv4()
            setUuid(uid)
            navigate(`${chats.path}/${uid}?chatGpt=true`);
            dispatch({ type: SIA_CHAT_MESSAGE, payload: { cleanup: true } })
        }
    }, [chatId, loggedUser.first_name, matchChatPage?.pathname, chatGptQueryParams]);

    useEffect(() => {
        if (chatId && siaChatMessages.length === 0 && chatGptQueryParams) {
            let message = `Hey! I’m Sia, your Scogo Assistant. How may I assist you today?
I can help you with creating new service tickets or provide details about any existing tickets`
            dispatch({ type: SIA_CHAT_MESSAGE, payload: { msg: { data: message, is_agent: true } } });
            axios.get(`${config.api.siaChatUrl}/v1/agents/cloud-chat-messages/${chatId}`)
                .then((res) => {
                    const list = res?.data?.map(({ History, created_at, updated_at }) => {
                        let history = JSON.parse(History);
                        return { data: history.data.content, is_agent: history.type === 'ai', created_at, updated_at }
                    }) || [];
                    if (list.length > 0) {
                        dispatch({ type: SIA_CHAT_MESSAGE, payload: { append: false, list } });
                    }
                })
        }
    }, [siaChatMessages, chatId, dispatch, chatGptQueryParams]);

    const isCurrentChatLoading = isConversationMessagesLoading[openedConversationId];
    const isCurrentReplyLoading = isReplyConversationMessagesLoading[openedConversationId];

    const scrollToBottom = useCallback((options) => {

        if (!options?.behavior) {
            options.behavior = 'smooth';
        }
        let lastDiv = document.getElementById('last_element_after_messages');
        lastDiv?.scrollIntoView(options);
    }, []);

    const openedConversationMessages = useMemo(() => {
        let messages = openedConversationMessagesList || [];
        let openedQueueLst = openedQueueMessagesList || [];
        if (Array.isArray(openedQueueLst)) {
            messages = [...messages, ...openedQueueLst];
        }
        return messages;
    }, [openedQueueMessagesList, openedConversationMessagesList]);

    const handleFileDrop = (ev) => {
        ev.preventDefault();
        let files = [];
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            files = [...ev.dataTransfer.items].filter((item) => item.kind === 'file' && item.type !== '').map((item) => item.getAsFile());
        } else {
            // Use DataTransfer interface to access the file(s)
            files = [...ev.dataTransfer.files];
        }
        if (files.length > 0) {
            messageInputRef.current?.onFileSelect?.(files);
        }
    };

    const showCurrentDateOnScroll = () => {
        let chatMsgsRef = scrollDivRef.current;
        let dateDividers = chatMsgsRef && chatMsgsRef.querySelectorAll('.dateDivider');

        let currentLabel = null;
        dateDividers.forEach((dateLabel) => {
            if (chatMsgsRef.scrollTop > dateLabel.offsetTop) {
                currentLabel = dateLabel;
            }
        });

        if (currentLabel) {
            firstMessageRef.current.style.display = 'block';
            firstMessageRef.current.innerText = currentLabel.innerText;
        } else {
            firstMessageRef.current.style.display = 'none';
        }
    };

    const supportTrackConversationDetails = conversationDetails && conversationDetails[ticketId]; // should be changed

    let convoDetail = openedConversation;
    if (supportTrackConversationDetails) convoDetail = supportTrackConversationDetails;
    const handleScroll = (event) => {
        let isScrollNotAtBottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) >= 100;
        const scrollOnBottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) <= 1;
        const isScrollAtTop = event.target?.scrollTop === 0;
        setShowScrollButton(isScrollNotAtBottom);
        const isFirstMessage = openedConversationMessages[0]?.isFirstMessage === true;
        if (isScrollAtTop && convoDetail && openedConversationMessages) {
            if (!isNextConversationMessagesLoading[openedConversationId] && !isCurrentChatLoading && !isFirstMessage) {
                setPrevScroll({
                    height: event.target.scrollHeight,
                    messageLength: openedConversationMessages?.length,
                    lastMessaseId: openedConversationMessages?.[openedConversationMessages?.length - 1]?._id,
                });
                dispatch(getNextConversationMessages({ _id: convoDetail._id, messageId: openedConversationMessages?.[0]._id, when: 'before' }));
            }
        } else if (scrollOnBottom && convoDetail && openedConversationMessages.length) {
            if (!isNextConversationMessagesLoading[openedConversationId] && !isCurrentChatLoading && !openedConversationMessageIsLatest?.[openedConversationId]) {
                dispatch(
                    getNextConversationMessages({ _id: convoDetail._id, messageId: openedConversationMessages?.[openedConversationMessages.length - 1]._id, when: 'after' })
                );
            }
        }
        if (scrollOnBottom && convoDetail && convoDetail.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadMessageCount: 0, _id: openedConversationId }));
            dispatch(setConversationUnreadableMessageCount());
        }
        showCurrentDateOnScroll();
    };

    const markConversationRead = useCallback(() => {
        if (openedConversationId && (openChat?.unreadMessageCount > 0 || convoDetail && convoDetail.unreadMessageCount > 0)) {
            dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.markRead, data: { conversation: openedConversationId } }));
        }
    }, [dispatch, openedConversationId, openChat?.unreadMessageCount, convoDetail]);

    const unReadMessageDiv = (id) => {
        var messageDiv = document.getElementById(id);
        if (messageDiv) {
            _.delay(() => {
                messageDiv.scrollIntoView({ behavior: 'instant' }, true);
            }, 200)
        }
    }

    const scrollDivTo = (scrollto, behavior = 'smooth') => {
        scrollDivRef?.current?.scrollTo({
            top: scrollto,
            behavior: behavior,
        });
    };

    const openChatInNewTab = () => {
        let url = `/chats/${openedConversationId}?hideList=true`;
        if (chatGptQueryParams) url = `${url}&chatGpt=true`
        window.open(url, '_blank');
    };

    const closeHelpRequest = useCallback((Id) => {
        if (isScm(loggedUser.role?.id, loggedUser.type)) {
            dispatch(closeHelpSupport(Id));
        } else {
            const formName = 'closeHelpRequestForm';
            dispatch(
                openTextModalAction({
                    heading: 'Close Help Request',
                    text: 'Are you sure you want to Close the Request?',
                    formName,
                    buttons: [
                        {
                            showLoading: true,
                            textOrComponent: 'Yes',
                            type: 'red',
                            buttonClass: 'hover:bg-scogoLightFillRed',
                            onClick: () => {
                                dispatch(closeHelpSupport(Id, formName));
                            },
                        },
                        {
                            textOrComponent: 'No',
                            type: 'blue',
                            onClick: () => {
                                dispatch(closeModalAction());
                            },
                        },
                    ],
                })
            );
        }
    }, [dispatch, loggedUser?.role?.id, loggedUser?.type]);

    const icons = () => {
        const openInNew = { name: 'open_in_new', onClick: () => openChatInNewTab(), title: 'Open Chat In New Tab' }
        let icon = [];
        if (openedConversationId) icon.push(openInNew);
        let hideCloseIconCondition = openedConversationMessages?.[openedConversationMessages?.length - 1]?.payload?.fulfillmentMessages?.[0]?.payload?.input?.options?.[0]?.trigger;
        if (openedConversation?.active && openedConversation?.type === chatType.help && hideCloseIconCondition !== 'ticket-close' && !hideCloseIconTimer) {
            icon = [
                {
                    name: 'power_settings_new',
                    onClick: () => {
                        setHideCloseIconTimer(true);
                        setTimeout(() => {
                            setHideCloseIconTimer(false);
                        }, 10000);
                        closeHelpRequest(openedConversationId);
                    },
                    title: 'Close Request',
                },
                { name: 'open_in_new', onClick: () => openChatInNewTab(), title: 'Open Chat In New Tab' },
            ];
        }

        icon = icon.filter(({ name }) => {
            if (name === 'open_in_new' && chatGptQueryParams) {
                return false
            }
            return true;
        });

        return icon?.map((e) => {
            return <MaterialIconToolTipComponent title={e?.title} onClick={() => e.onClick()} materialIconName={e?.name} materialIconClass={`text-scogoprimary hover:text-scogoorange cursor-pointer material-icons ${isChatPopup ? 'text-font14' : 'text-font16'}`} />;
        });
    };

    const getLatestConversationMessages = (delayTime = 500) => {
        if (openedConversationMessageIsLatest?.[openedConversationId]) {
            scrollToBottom({ block: 'end' });
        } else {
            scrollToBottom({ block: 'end' });
            _.delay(() => {
                dispatch(setopenedConversationMessageIsLatest({ isLatest: true, _id: openedConversationId }));
                dispatch(getConversationMessages({ _id: openedConversation._id }));
                let params = new URLSearchParams(window.location.search);
                params.delete('messageId');
                params = params.toString();
                navigate({
                    pathname: window.location.pathname,
                    search: params,
                });
            }, delayTime);
        }
        if (openedConversation?.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadCount: 0, _id: openedConversationId }))
        }
    };

    const onClickReplyMessage = (id) => {
        var messageDiv = document.getElementById(id);
        if (messageDiv) {
            messageDiv.scrollIntoView({ behavior: 'smooth' }, true);
            messageDiv.className = 'highLightDiv';
            _.delay(() => {
                messageDiv.classList.remove('highLightDiv');
            }, 5000);
        } else {
            scrollDivTo(4);
            let params = new URLSearchParams(window.location.search);
            params.set('messageId', id);
            params = params.toString();
            _.delay(() => {
                navigate({
                    pathname: window.location.pathname,
                    search: params,
                });
            }, 600);
        }
    };

    const onReplyClick = (selectedMessage) => {
        setReplyShow(true);
        setReplyToMessage(selectedMessage);
    };

    const { conversationImage = defaultGroupChatImage, conversationName = '' } = useMemo(() => {
        let conversationName = openedConversation?.conversationName;

        let conversationImage = openedConversation?.conversationImage;
        if (openedConversation?.type === chatType.single) {
            const secondUser = Array.isArray(openedConversation?.conversants) && openedConversation?.conversants.find((chat) => chat.userId !== loggedUser.id);
            conversationName = secondUser?.username;
            conversationImage = secondUser?.profilePic;
        }
        if (pathname === trackSupportTicket.path && supportTrackConversationDetails) {
            conversationName = supportTrackConversationDetails.conversationName
        }
        if (chatGptQueryParams) {
            conversationName = 'Sia'
        }
        return { conversationImage, conversationName }
    }, [loggedUser.id, openedConversation?.conversationName, openedConversation?.conversationImage, openedConversation?.type, openedConversation?.conversants, supportTrackConversationDetails, pathname, chatGptQueryParams]);

    const closeReply = useCallback(() => {
        setReplyShow(false);
        setReplyToMessage(null);
    }, []);

    useEffect(() => {
        return () => {
            setUnReadMessageId("")
            dispatch(updateunReadMessage({ unreadCount: 0, _id: openedConversationId }))
        }
    }, [openedConversationId, dispatch])

    useEffect(() => {
        closeReply();
        return () => {
            setPrevScroll({});
        };
    }, [openedConversationId, closeReply]);


    useEffect(() => {
        const typingInterval = setInterval(() => {
            dispatch({ type: REMOVE_TYPING_IN_CHAT });
        }, 3000);
        return () => clearInterval(typingInterval)
    }, []);

    useEffect(() => {
        if (openedConversation?.unreadMessageCount === 0 || openedConversation?.unreadMessageCount === undefined || siaChatMessages.length > 0) {
            scrollToBottom({ block: 'end' });
        }
    }, [scrollToBottom, openedConversationId, isCurrentChatLoading, siaChatMessages.length])

    useEffect(() => {
        const { messageLength, height, lastMessaseId } = prevScroll;
        const prevMessagesLen = validateNumberValue(messageLength);
        const openedMessagesLength = validateNumberValue(openedConversationMessages?.length);
        const isLastSavedMessageIsSame = openedConversationMessages?.[openedMessagesLength - 1]?._id !== lastMessaseId;
        const isSendMessage = openedConversationMessages?.[openedMessagesLength - 1] && !openedConversationMessages?.[openedMessagesLength - 1].timestamp;
        const lastVisibleElementId = scrollDivRef.current && getLastVisibleElementId({ scrollableDivRef: scrollDivRef });
        const findIndexOfLastVisibleElement = validateNumberValue(openedConversationMessages?.findIndex((message) => message._id === lastVisibleElementId));
        const isNearToBottom = (openedMessagesLength - findIndexOfLastVisibleElement) < 5;

        if (openedMessagesLength && prevMessagesLen && openedMessagesLength > prevMessagesLen && !isLastSavedMessageIsSame) {
            setPrevScroll({
                messageLength: openedConversationMessages?.length,
                height, lastMessaseId
            });
            scrollDivTo(scrollDivRef?.current?.scrollHeight - height, 'auto');
        } else if (isSendMessage || isNearToBottom) {
            _.delay(() => {
                scrollToBottom({ block: 'end' });
            }, 0);
            // delay is to make the invocation of scrollToBottom async so that height of attachment type message is calculated before scrolling.
        }
    }, [openedConversationMessages, openedConversationId, prevScroll, scrollToBottom, latestMessagesIds]);


    function validateNumberValue(value) {
        return typeof value === 'number' ? value : 0;
    }

    useEffect(() => {
        markConversationRead();
        window.addEventListener('focus', markConversationRead);
        return () => {
            window.removeEventListener('focus', markConversationRead)
        };
    }, [markConversationRead]);

    useLayoutEffect(() => {
        if (isCurrentReplyLoading === false || messageClickTime) {
            _.delay(() => {
                var messageDiv = document.getElementById(messageId);
                if (messageDiv) {
                    messageDiv.className = 'highLightDiv';
                    messageDiv.scrollIntoView({ behavior: 'smooth' }, true);
                    _.delay(() => {
                        messageDiv.classList.remove('highLightDiv');
                    }, 5000);
                }
            }, 200);
        }
    }, [messageId, isCurrentReplyLoading, messageClickTime]);

    const conversationUnreadMessageLoaded = conversationDetails?.[chatId] ? true : false // need to fix

    useEffect(() => {
        let unreadCount = openedConversation?.unreadMessageCount
        let unReadMesIndex = -1;
        if (unreadCount === 0 && unReadMessageId?.length > 0) {
            setUnReadMessageId("")
        }
        if (conversationUnreadMessageLoaded && unreadCount && unReadMessageId?.length === 0) {
            unReadMesIndex = openedConversationMessages?.length - unreadCount
        } else if (unreadCount < defaultMessageLimit && unreadCount > 0 && unReadMessageId?.length === 0) {
            unReadMesIndex = openedConversationMessages?.length - unreadCount
        } else if (unreadCount >= defaultMessageLimit && unReadMessageId?.length === 0) {
            unReadMesIndex = openedConversationMessages?.length - defaultMessageLimit
        }
        if (unReadMesIndex >= 0) {
            setUnReadMessageId(openedConversationMessages?.[unReadMesIndex]?._id)
            unReadMessageDiv(openedConversationMessages?.[unReadMesIndex - 1]?._id)
        }
    }, [openedConversation?.unreadMessageCount, openedConversationMessages?.length]);

    const showMessageInput = (openedConversation?.active && !openedConversation?.isBotEnabled && !openedConversation?.hideInput) || chatGptQueryParams || (pathname === trackSupportTicket.path) || forceShowMessageInput;
    const isChatMessagesLoading = isCurrentChatLoading || isCurrentReplyLoading;

    const memoizedTicket = useMemo(() => {
        if (isSupportTicket && supportTrackDetails) {
            return transformTicketforChatAction(supportTrackDetails);
        } else if (isChatPopup && conversationDetails && openedConversationId && conversationDetails[openedConversationId] && chatTickets[conversationDetails[openedConversationId].conversationId]) {
            return chatTickets[conversationDetails[openedConversationId].conversationId]
        } else if (!isViewTicketByIdLoading && viewTicket) return transformTicketforChatAction(viewTicket)
        return {}
    }, [conversationDetails, viewTicket, isViewTicketByIdLoading, isChatPopup, openedConversationId, chatTickets, isSupportTicket, supportTrackDetails]);

    const subHeaderVisibleElement = useMemo(() => {
        let checklist = false;
        let add_boq = false;
        let show_problem_desciption = false;

        if (memoizedTicket.ticket_source === 'SIA_AGENT_TOOL' && isScm(loggedUser.role.id, loggedUser.type)) {
            show_problem_desciption = true;
        } else if (isChatPopup) {
            checklist = renderChecklists;
        } else if (isSupportTicket) {
            add_boq = (((isSp(loggedUser.role.id, loggedUser.type) && [0, loggedUser.id].includes(memoizedTicket.accepted_sp_id))) || (isFe(loggedUser.role.id, loggedUser.type) && [0, loggedUser.id].includes(memoizedTicket.accepted_fe_id)) || (isEndUser(loggedUser.role.id) && [0, loggedUser.id].includes(memoizedTicket.accepted_fe_id))) && memoizedTicket?.signOffRequest !== 1;
            checklist = !add_boq
        } else {
            checklist = openedConversation?.type === chatType.ticket && parseFloat(memoizedTicket?.template_detail?.has_checklist) === 1 && memoizedTicket?.ticket_id === parseFloat(openedConversation?.conversationId);
        }

        return { checklist, add_boq, show_problem_desciption }
    }, [isSupportTicket, memoizedTicket?.template_detail?.has_checklist, memoizedTicket?.ticket_id, openedConversation?.conversationId, openedConversation?.type, loggedUser.id, loggedUser.role.id, loggedUser.type, memoizedTicket.accepted_fe_id, isChatPopup, renderChecklists, memoizedTicket.ticket_source, memoizedTicket.accepted_sp_id, memoizedTicket.signOffRequest]);


    return (
        <div className={`border-2 border-scogogray overflow-hidden ${mainDivClass} `} onDrop={handleFileDrop} onDragOver={(ev) => ev.preventDefault()} >
            <div
                className='relative h-full flex bg-scogoeee '
                style={{
                    flexFlow: 'column',
                }}
            >
                <div className={`flex-initial ${isChatMinimized ? 'fixed z-50 w-[33rem]' : ''}`}>
                    {showChatHeader &&
                        <div onClick={handleChatArrowClick} className={`flex items-center chatContainerHeader bg-slate-200  ${isChatPopup ? 'md:px-1 cursor-pointer' : 'md:px-8'}  px-2 gap-3  `}>
                            <Header
                                loading={isCurrentChatLoading}
                                conversationImage={conversationImage}
                                chatName={conversationName}
                                showProfileImg={openedConversation?.type !== chatType.help}
                                icons={icons}
                                isSingleChat={openedConversation?.type === chatType.single}
                                isTicketchat={openedConversation?.type === chatType.ticket}
                                openedConversationId={openedConversationId}
                                showWhatsappicon={(openedConversation?.type === chatType.ticket) && isScm(loggedUser.role.id, loggedUser.type)}
                                showTicketSearch={showTicketSearch}
                                onTicketModalSearchCancel={onTicketModalSearchCancel}
                                onTicketSearhClick={onTicketSearhClick}
                                isSuppportTrackMessagesElemVisible={isSuppportTrackMessagesElemVisible}
                                mainChatContanerRef={mainChatContanerRef}
                                handleCloseChat={handleCloseChat}
                                isChatPopup={isChatPopup}
                                openedConversation={openedConversation}
                                handleChatArrowClick={handleChatArrowClick}
                                isChatMinimized={isChatMinimized}
                                memoizedTicket={memoizedTicket}
                                getOpenedConversationsIds={getOpenedConversationsIds}
                                openedConversationMessages={openedConversationMessages}
                                unReadMessageId={unReadMessageId}
                                isSupportTicket={isSupportTicket}
                                chatGptQueryParams={chatGptQueryParams}
                            />
                        </div>}
                </div>
                {!chatGptQueryParams && <div className='overflow-y-auto flex-initital'>
                    <SubHeader isSupportTicket={isSupportTicket} memoizedTicket={memoizedTicket} isChatPopup={isChatPopup} openedConversation={openedConversation} subHeaderVisibleElement={subHeaderVisibleElement} />
                </div>}
                {isChatMessagesLoading && (
                    <div className='relative overflow-hidden px-8 mt-2  flex-auto'>
                        <ChatMsgLoader />
                    </div>

                )}

                {(!isChatMessagesLoading || chatGptQueryParams) && (
                    <MessagesList isChatMinimized={isChatMinimized} openedConversationMessages={openedConversationMessages} unReadMessageId={unReadMessageId} openedConversation={openedConversation} onClickReplyMessage={onClickReplyMessage} onReplyClick={onReplyClick} replyToMessage={replyToMessage} replyShow={replyShow} showChatSubHeader={subHeaderVisibleElement.checklist} firstMessageRef={firstMessageRef} scrollDivRef={scrollDivRef} isCurrentChatLoading={isCurrentChatLoading} handleScroll={handleScroll} isChatPopup={isChatPopup} />
                )}

                <div className={`flex-none ${isChatMinimized ? 'pointer-events-none' : ''}`}>
                    <TypingUsers users={mpConversationUserTyping?.[openedConversationId]} additionalClasses='bg-white pl-2 py-1' maxUsernamesToShow={5} />
                    {showMessageInput && (
                        <MessageInput
                            ref={messageInputRef}
                            showAllTag={openedConversation?.type !== chatType.single}
                            getLatestConversationMessages={getLatestConversationMessages}
                            closeReply={closeReply}
                            replyShow={replyShow}
                            setReplyShow={setReplyShow}
                            replyToMessage={replyToMessage}
                            scrollToBottom={scrollToBottom}
                            showDoubleHash={openedConversation?.type === chatType.group}
                            ticketId={ticketId}
                            isSupportTicket={isSupportTicket}
                            openedConversationId={openedConversationId}
                            isChatPopup={isChatPopup}
                            uuid={uuid}
                        />
                    )}
                </div>
                {(showScrollButton && !isChatPopup) && <Scroller openedConversationUnreadableMessageCount={openedConversationUnreadableMessageCount} getLatestConversationMessages={getLatestConversationMessages} />}
            </div>
        </div>
    );
}


export const getLastVisibleElementId = ({ scrollableDivRef }) => {
    const scrollableDiv = scrollableDivRef.current;
    const elements = scrollableDiv.querySelectorAll('.message_class_for_scroll');

    let lastVisible = null;

    for (let i = elements.length - 1; i >= 0; i--) {
        const element = elements[i];
        const rect = element.getBoundingClientRect();

        if (rect.top < scrollableDiv.clientHeight) {
            lastVisible = element;
            break;
        }
    }

    return lastVisible ? lastVisible.id : undefined;
};

export const RenderScrollButton = ({ openedConversationUnreadableMessageCount, getLatestConversationMessages }) => {
    return <div
        style={{ right: '2.8%', bottom: '4.5rem' }}
        className='absolute cursor-pointer border flex place-content-center bg-scogoprimary rounded-md outline-0 border-none opacity-75'
    >
        <span onClick={() => getLatestConversationMessages(500)} className='material-icons text-white'>
            expand_more
        </span>
        {openedConversationUnreadableMessageCount ? (
            <span
                className={`absolute  inline-flex items-center justify-center px-2 py-1 text-font10 font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-scogoorange rounded-full notificationBadge`}
                style={{
                    top: '2%',
                    right: '1%',
                }}
            >
                {openedConversationUnreadableMessageCount}
            </span>
        ) : (
            <></>
        )}
    </div>
}

export const isDateAreSame = (a, b) => {
    return a && b && moment(a).format('dd/MM/Y') === moment(b).format('dd/MM/Y');
};