import { scogoEvent } from '../pages/Ticket/TicketController';
import { payments, tickets } from '../types';
import { findAndUpdate, ticketBoqDetails } from '../utils/utils';
import { validateResponseDate, validateNumberValue, validateStringValue } from '../utils/common';
import { allTab, newAndCustomerTab, triggerTab, plannedTab, ongoingTab, IssuesTab, completedTab, closedTab, scheduledTab } from '../pages/Ticket/TicketController';
import { isCluster, isFe, isPsp, isSp, isSuperVisor } from '../utils/role';
import config from '../config';
import { logisticsType } from '../pages/Payments/SideBarComponents/TrackCourier';
import { chatType } from '../pages/chat/ChatController';


const INITIAL_STATE = {
    hasExecutionStarted: false,
    error: undefined,
    ticketsCount: {},
    ticketList: [],
    ticketByIdMap: new Map(),
    filterTicketCount: {},
    ticketDetailsError: false,
    activeVoiceCallList: [],
    spNewTicketsList: [],
    ticketDetails: undefined,
    openTicketDetailsError: undefined,
    ticketSitePos: {},
    chatHeaderExecutionStepFields: {},
    cnergeeTicketDeviceData: {}
};

const ticketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case tickets.BOQ_LIST_SUCCESS:
            return {
                ...state,
                boqList: action.payload,
            };
        case tickets.BOQ_LIST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case tickets.SP_ASSIGNED_NEW_TICKETS_LIST_SUCCESS:
            return {
                ...state,
                spAssignedNewTickets: action.payload,
            };
        case tickets.EXEC_DATECHANGE_REMARKS_SUCCESS:
            return {
                ...state,
                remarks: action.payload,
            };
        case tickets.SP_ONGOING_TICKETS_SUCCESS:
            return {
                ...state,
                ongoingSpTickets: action.payload.data,
            };
        case tickets.VIEW_TICKET_SUCCESS:
            const ticket = action.payload || {};
            const viewTicket = {
                ...ticket,
                broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
                created_at: validateResponseDate(ticket.created_at),
                delay_timestamp: validateResponseDate(ticket.delay_timestamp),
                execution_date: validateResponseDate(ticket.execution_date),
                fe_assigned_timestamp: validateResponseDate(ticket.fe_assigned_timestamp),
                job_sign_off_date: validateResponseDate(ticket.job_sign_off_date),
                live_tracking_enabled_at: validateResponseDate(ticket.live_tracking_enabled_at),
                planned_date: validateResponseDate(ticket.planned_date),
                reached_site_date: validateResponseDate(ticket.reached_site_date),
                sign_off_approval_date: validateResponseDate(ticket.sign_off_approval_date),
                site_completed_on: validateResponseDate(ticket.site_completed_on),
                sp_accept_timestamp: validateResponseDate(ticket.sp_accept_timestamp),
                updated_at: validateResponseDate(ticket.updated_at),
            };
            return {
                ...state,
                viewTicket,
            };
        case tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_SUCCESS: {
            const ticketDetail = action.payload;
            const openTicketDetails = {
                ...ticketDetail,
                created_at: validateResponseDate(ticketDetail.created_at),

            };
            return {
                ...state,
                openTicketDetails,
            };
        }
        case tickets.UPDATE_VIEW_TICKET: {
            const ticket = state.viewTicket || {};
            ticket.sign_off_document = action.payload;
            const ticketDetails = state.ticketDetails || {};
            ticketDetails.sign_off_document = action.payload;
            return { ...state, viewTicket: ticket, ticketDetails };
        }
        case tickets.CLEAR_VIEW_TICKET_FROM_STORE:
            return {
                ...state,
                viewTicket: undefined,
            };
        case tickets.GET_EXECUTION_DATE_TIMELINE_SUCCESS:
            return {
                ...state,
                timeline: action.payload,
            };

        case tickets.UPDATE_TICKET_COURIER_STATUS_SUCCESS: {
            const { data, type } = action.payload;
            const viewTicket = state.viewTicket;
            if (type === logisticsType.forward && viewTicket?.tracking_detail) {
                viewTicket.tracking_detail.awbno = data.awbno;
                viewTicket.tracking_detail.carrier = data.carrier;
                viewTicket.tracking_detail.current_status = data.current_status;
                viewTicket.tracking_detail.tracking_url = data.tracking_url;
            } else if (type === logisticsType.reverse && viewTicket?.rltracking_detail) {
                viewTicket.rltracking_detail.awbno = data.awbno;
                viewTicket.rltracking_detail.carrier = data.carrier;
                viewTicket.rltracking_detail.current_status = data.current_status;
                viewTicket.rltracking_detail.tracking_url = data.tracking_url;
            }

            return {
                ...state,
                courierStatus: data,
                viewTicket,
            };
        }

        case tickets.UPDATE_TICKET_TABLE_COURIER_STATUS_SUCCESS: {
            const { data, type, ticketId } = action.payload;
            let ticketList = state.ticketList;
            ticketList = ticketList.map((ticket) => {
                if (ticket.ticket_id === ticketId) {
                    if (type === logisticsType.forward && ticket?.tracking_detail) {
                        ticket.tracking_detail.awbno = data.awbno;
                        ticket.tracking_detail.carrier = data.carrier;
                        ticket.tracking_detail.current_status = data.current_status;
                        ticket.tracking_detail.tracking_url = data.tracking_url;
                    } else if (type === logisticsType.reverse && ticket?.rltracking_detail) {
                        ticket.rltracking_detail.awbno = data.awbno;
                        ticket.rltracking_detail.carrier = data.carrier;
                        ticket.rltracking_detail.current_status = data.current_status;
                        ticket.rltracking_detail.tracking_url = data.tracking_url;
                    }
                }
                return ticket;
            });

            return {
                ...state,
                ticketList,
            };
        }
        case tickets.GET_CHILD_TICKETS_SUCCESS:
            return {
                ...state,
                childTickets: action.payload,
            };
        case tickets.CLEAR_PAYMENTS_SIDEBAR_DATA_FROM_STORE: {
            return {
                ...state,
                courierStatus: undefined,
                timeline: undefined,
                viewTicket: undefined,
                childTickets: undefined,
                sowNotFound: undefined,
                signOffNotFound: undefined,
                reportLink404: undefined,
                ticketPayment: undefined,
                ticketBasePayment: undefined,
            };
        }
        case tickets.SOW_DOCUMENT_NOT_FOUND:
            return {
                ...state,
                sowNotFound: action.payload,
            };
        case tickets.SIGN_OFF_SAMPLE_REPORT_NOT_FOUND:
            return {
                ...state,
                signOffNotFound: action.payload,
            };

        case tickets.GET_TICKET_DETAILS_SUCCESS: {
            const ticket = action.payload || {};
            const ticketDetails = {
                ...ticket,
                broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
                created_at: validateResponseDate(ticket.created_at),
                delay_timestamp: validateResponseDate(ticket.delay_timestamp),
                execution_date: validateResponseDate(ticket.execution_date),
                fe_assigned_timestamp: validateResponseDate(ticket.fe_assigned_timestamp),
                job_sign_off_date: validateResponseDate(ticket.job_sign_off_date),
                live_tracking_enabled_at: validateResponseDate(ticket.live_tracking_enabled_at),
                planned_date: validateResponseDate(ticket.planned_date),
                reached_site_date: validateResponseDate(ticket.reached_site_date),
                sign_off_approval_date: validateResponseDate(ticket.sign_off_approval_date),
                site_completed_on: validateResponseDate(ticket.site_completed_on),
                sp_accept_timestamp: validateResponseDate(ticket.sp_accept_timestamp),
                updated_at: validateResponseDate(ticket.updated_at),
            };
            return {
                ...state,
                ticketDetails,
                ticketDetailsError: false
            };
        }

        case tickets.UPDATE_REACHED_SITE_IN_TICKET_DETAILS: {
            const { ticketId, reachedSite } = action.payload;
            const ticketDetails = state.ticketDetails || {};
            const viewTicket = state.viewTicket || {};
            if (viewTicket && viewTicket.id === ticketId) viewTicket.reached_site = reachedSite;
            if (ticketDetails && ticketDetails.id === ticketId) ticketDetails.reached_site = reachedSite;

            return {
                ...state,
                ticketDetails,
                viewTicket
            }
        }
        case tickets.EDIT_TICKET_SUCCESS: {
            const ticket = action.payload || {};
            const ticketDetails = {
                ...state.ticketDetails,
                job_fk_pincode_id: ticket.job_fk_pincode_id,
                job_site_address_1: ticket.job_site_address_1,
                issue_category: ticket.issue_category,
                description: ticket.description,
                execution_date: validateResponseDate(ticket.execution_date),
            };
            if (ticket.supportAsset) {
                let asset = {
                    serial_number: ticket.supportAsset.serial_number,
                    partcode: ticket.supportAsset.partcode,
                    asset_type_id: ticket.supportAsset.asset_type_id,
                }
                const previousAssets = ticket.assets || [];
                ticketDetails.assets = [...previousAssets, asset];
            }
            return {
                ...state,
                ticketDetails,
            };
        }
        case tickets.CLEANUP_TICKET_DETAILS: {
            return {
                ...state,
                ticketDetails: {},
            };
        }

        case tickets.CLEANUP_TICKET_PAYMENT_DETAILS: {
            return {
                ...state,
                ticketPayment: null,
                ticketBasePayment: null,
            };
        }

        case tickets.TICKET_REPORT_LINK_SUCCESS:
            return {
                ...state,
                reportLink: action.payload,
            };
        case tickets.TICKET_REPORT_LINK_NOT_FOUND:
            return {
                ...state,
                reportLink404: action.payload,
            };

        case tickets.EMAIL_REPORT_SUCCESS: {
            return {
                ...state,
                emailReported: action.payload,
            };
        }

        case tickets.GET_TICKET_PAYMENTS_SUCCESS: {
            return {
                ...state,
                ticketPayment: action.payload,
            };
        }
        case tickets.GET_TICKET_BASE_PAYMENTS_SUCCESS: {
            return {
                ...state,
                ticketBasePayment: action.payload,
            };
        }
        case tickets.PROCESS_ADDITIONAL_AMOUNT_SUCCESS: {
            return {
                ...state,
                processAdditionalResponse: action.payload,
            };
        }
        case tickets.REQUEST_ADDITIONAL_AMOUNT_SUCCESS: {
            return {
                ...state,
                requestAdditionalAmountStatus: action.payload,
            };
        }
        case tickets.CLEANUP_PAYMENT_TICKET_DETAILS: {
            return {
                ...state,
                ticketBasePayment: undefined,
                ticketPayment: undefined,
            };
        }
        case tickets.START_TICKET_EXECUTION_SUCCESS: {
            return {
                ...state,
                hasExecutionStarted: action.payload,
            };
        }
        case tickets.INITIATE_TICKET_SUCCESS_IN_EXECUTION:
            return {
                ...state,
                ticketInitiated: action.payload,
            };
        case tickets.SAVE_FE_PRE_REQUISITE_SUCCESS:
            return {
                ...state,
                siteprereq: action.payload,
            };
        case tickets.GET_SITE_BOQ_SUCCESS:
            return {
                ...state,
                siteBoqList: action.payload,
            };
        case tickets.SAVE_FE_SITE_BOQ_SUCCESS:
            return {
                ...state,
                checkBOQ: action.payload,
            };
        case tickets.GET_WORK_IN_PROGRESS_STEPS_SUCCESS:
            return {
                ...state,
                executionSteps: action.payload,
            };
        case tickets.GET_WORK_IN_PROGRESS_STEPS_FIELDS_SUCCESS:
            return {
                ...state,
                executionStepFields: action.payload,
            };
        case tickets.GET_WORK_IN_PROGRESS_CHAT_HEADER_STEPS_FIELDS_SUCCESS: {
            const { data, ticket_id } = action.payload;
            return {
                ...state,
                chatHeaderExecutionStepFields: { ...state.chatHeaderExecutionStepFields, [ticket_id]: data },
            };
        }
        case tickets.REMOVE_EXECUTPION_STEPS_FIELD_FROM_STORE: {
            return {
                ...state,
                executionStepFields: undefined,
            };
        }
        case tickets.UPDATE_EXECUTION_STEP_DATA_SUCCESS:
            const { step, source, ticket_id } = action.payload;
            if (source === 'chat_steps' && ticket_id) {
                const currentStep = state.chatHeaderExecutionStepFields[ticket_id];
                const stepsFields = currentStep.map((st) => {
                    if (parseFloat(st.step_fields_relationship_id) === parseFloat(step.step_fields_relationship_id)) {
                        const file_values = step.files.map((file) => ({ file_name: file.file_name, file_path: file.file_path, mimeType: file.mimeType }))
                        return { ...st, file_values, status: 0 }
                    }
                    return st;
                });
                return {
                    ...state,
                    chatHeaderExecutionStepFields: { ...state.chatHeaderExecutionStepFields, [ticket_id]: stepsFields }
                }
            }
            const stepsFields = state.executionStepFields.single.map((st) => {
                if (parseFloat(st.step_fields_relationship_id) === parseFloat(step.step_fields_relationship_id)) {
                    const file_values = step.files.map((file) => ({ file_name: file.file_name, file_path: file.file_path, mimeType: file.mimeType }))
                    return { ...st, file_values, status: 0 }
                }
                return st;
            });

            return {
                ...state,
                executionStepFields: { single: stepsFields },
            };

        case tickets.UPDATE_EXECUTION_STEP_FIELD_STATUS_FROM_WEBSOCKET: {
            const { stepFieldsRelationshipId, status, attachments, conversation } = action.payload;
            let stepFields = state.executionStepFields;
            if (stepFields && Array.isArray(stepFields.single) && stepFieldsRelationshipId) {
                stepFields.single = stepFields.single.map((step) => {
                    if (step.step_fields_relationship_id === stepFieldsRelationshipId) {
                        step.status = status;
                        if (Array.isArray(attachments) && attachments.length > 0) {
                            step.file_values = attachments.map((file) => {
                                return {
                                    file_name: file.fileName,
                                    file_path: file.fileUrl,
                                    mimeType: file.mimeType,
                                }
                            })
                        }
                        return step;
                    }
                    return step
                })
            }

            if (conversation.type === chatType.ticket && conversation.conversationId && stepFieldsRelationshipId) {
                const currentStep = state.chatHeaderExecutionStepFields[conversation.conversationId];
                if (currentStep) {
                    const stepsFields = currentStep.map((step) => {
                        if (step.step_fields_relationship_id === stepFieldsRelationshipId) {
                            step.status = status;
                            if (Array.isArray(attachments) && attachments.length > 0) {
                                step.file_values = attachments.map((file) => {
                                    return {
                                        file_name: file.fileName,
                                        file_path: file.fileUrl,
                                        mimeType: file.mimeType,
                                    }
                                })
                            }
                            return step;
                        }
                        return step
                    });
                    return {
                        ...state,
                        chatHeaderExecutionStepFields: { ...state.chatHeaderExecutionStepFields, [conversation.conversationId]: stepsFields }
                    }
                }
            };

            const findSignoffAttachment = attachments.find((att) => att.isSignOff);
            if (!stepFieldsRelationshipId && findSignoffAttachment && conversation.conversationId && status === 2) {
                const viewTicket = state.viewTicket || {};
                const ticketDetails = state.ticketDetails || {};
                if (viewTicket.id === parseInt(conversation.conversationId)) {
                    viewTicket.sign_off_document = []
                }
                if (ticketDetails.id === parseInt(conversation.conversationId)) {
                    viewTicket.sign_off_document = []
                }
                return {
                    ...state,
                    viewTicket,
                    ticketDetails
                }

            }

            return {
                ...state,
                executionStepFields: stepFields
            };
        }
        case tickets.UPDATE_WORK_IN_PROGRESS_STEP_DATA_SUCCESS:
            return {
                ...state,
                updatedStepFields: action.payload,
            };
        case tickets.END_WORK_IN_PROGRESS_SUCCESS:
            return {
                ...state,
                endWIP: action.payload,
            };
        case tickets.TICKETS_PUT_TO_STORE_SUCCESS:
            return { ...state, ...action.payload };
        case payments.CLEANUP_FOR_UPDATE_PO: {
            return {
                ...state,
                boqList: undefined,
            };
        }
        case tickets.GET_TICKET_LIST_SUCCESS: {
            const { ticketList, ticketsCount, loggedUserType, loggedUserRole } = action.payload;
            let transformedTicketCount = state.ticketsCount;
            if (ticketsCount && Object.keys(ticketsCount).length > 0) {
                transformedTicketCount = {
                    [allTab.path]: ticketsCount.alltickets,
                    [newAndCustomerTab.path]: isCluster(loggedUserRole, loggedUserType) || isSuperVisor(loggedUserRole, loggedUserType) ? ticketsCount.triggered : ticketsCount.new,
                    [triggerTab.path]: ticketsCount.triggered,
                    [plannedTab.path]: ticketsCount.planned,
                    [ongoingTab.path]: ticketsCount.ongoing,
                    [IssuesTab.path]: ticketsCount.escalated,
                    [completedTab.path]: ticketsCount.sitecomplete,
                    [closedTab.path]: ticketsCount.closed,
                };
            }
            const transformedTicketList = transformTicketList(ticketList);
            return {
                ...state,
                ticketList: transformedTicketList,
                ticketsCount: transformedTicketCount,
                filterTicketCount: transformedTicketCount,
            };
        }

        case tickets.PUT_TO_TICKETS_STORE: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case tickets.TICKETS_TODAY_STATUS_LIST_SUCCESS: {
            const { todayStatusTicketList } = action.payload;
            return {
                ...state,
                todayStatusTicketList: todayStatusTicketList,
            };
        }
        case tickets.TICKETS_TODAY_STATUS_COUNT_SUCCESS: {
            return {
                ...state,
                todayTicketsCount: action.payload,
            };
        }
        case tickets.UPDATED_SITE_DETAILS_WHEN_EDIT_TICKET: {
            return {
                ...state,
                updatedSiteDetails: action.payload,
            };
        }
        case tickets.GET_TODAY_STATUS_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount } = action.payload;
            return {
                ...state,
                todayStatusTicketList: filterData,
                todayTicketsCount: filterCount,
            };
        }

        case tickets.INITIATE_TICKET_SUCCESS: {
            const { ticketId, user } = action.payload;
            let scogo_events = state?.todayStatusTicketList?.find((element) => element.id === ticketId)?.scogo_events;

            scogo_events.push({
                event_master_id: scogoEvent.reachedSite,
                user: { first_name: user.first_name, last_name: user.last_name },
                updated_at: new Date(),
            });

            const updatedList = findAndUpdate({
                id: ticketId,
                dataList: state.todayStatusTicketList,
                updatingValues: { scogo_events, reached_site: 1 },
            });

            return {
                ...state,
                todayStatusTicketList: updatedList,
            };
        }

        case tickets.SET_TODAY_STATUS_FILTER_AND_PAGINATION_DATA: {
            const { payload, isFilterActive, limit, page, query } = action.payload;
            let updatedPayload = {
                searchQuery: query,
            };
            if (isFilterActive !== undefined) updatedPayload.isFilterActive = isFilterActive;
            if (payload) updatedPayload.filterPayload = payload;
            if (limit) updatedPayload.rowsPerPage = limit;
            if (page) updatedPayload.page = page;
            return {
                ...state,
                ...updatedPayload,
            };
        }

        case tickets.GET_SP_ACCEPTED_TICKETS_SUCCESS: {
            const { ticketList } = action.payload;
            const transformedTicketList = transformSPTicketList(ticketList);
            return {
                ...state,
                ticketList: transformedTicketList,
            };
        }

        case tickets.GET_FE_ACCEPTED_TICKETS_SUCCESS: {
            const { ticketList } = action.payload;
            const transformedTicketList = transformFETicketList(ticketList);
            return {
                ...state,
                ticketList: transformedTicketList,
            };
        }

        case tickets.GET_SP_TICKETS_COUNT_SUCCESS: {
            const { ticketsCount } = action.payload;
            let transformedTicketCount = state.ticketsCount;
            if (ticketsCount && Object.keys(ticketsCount).length > 0) {
                transformedTicketCount = {
                    [allTab.path]: ticketsCount.sp_all_tickets_count,
                    [newAndCustomerTab.path]: ticketsCount.new,
                    [ongoingTab.path]: ticketsCount.sp_ongoing_tickets_count,
                    [IssuesTab.path]: ticketsCount.sp_onhold_tickets_count,
                    [completedTab.path]: ticketsCount.sp_completed_tickets_count,
                };
            }
            return {
                ...state,
                ticketsCount: transformedTicketCount,
                filterTicketCount: transformedTicketCount,
            };
        }
        case tickets.GET_FE_TICKETS_COUNT_SUCCESS: {
            const { ticketsCount } = action.payload;
            let transformedTicketCount = state.ticketsCount;
            if (ticketsCount && Object.keys(ticketsCount).length > 0) {
                transformedTicketCount = {
                    [allTab.path]: ticketsCount.fe_all_tickets_count,
                    [scheduledTab.path]: ticketsCount.fe_scheduled_tickets_count,
                    [ongoingTab.path]: ticketsCount.fe_ongoing_tickets_count,
                    [IssuesTab.path]: ticketsCount.fe_onhold_tickets_count,
                    [completedTab.path]: ticketsCount.fe_completed_tickets_count,
                };
            }
            return {
                ...state,
                ticketsCount: transformedTicketCount,
                filterTicketCount: transformedTicketCount,
            };
        }

        case tickets.GET_TICKET_FILTER_LIST_SUCCESS: {
            let partners = [];
            let engineers = [];
            if (Array.isArray(action.payload.partners)) {
                partners = [...action.payload.partners, { id: 0, first_name: '(Blank)', last_name: '' }];
            }
            if (Array.isArray(action.payload.engineers)) {
                engineers = [...action.payload.engineers, { id: 0, first_name: '(Blank)', last_name: '' }];
            }
            return {
                ...state,
                ticketFilterList: {
                    ...action.payload,
                    planned: [
                        { key: 'YES', label: 'Yes' },
                        { key: 'NO', label: 'No' },
                    ],
                    reachSite: [
                        { key: 'YES', label: 'Yes' },
                        { key: 'NO', label: 'No' },
                    ],
                    siteComplete: [
                        { key: 'YES', label: 'Yes' },
                        { key: 'NO', label: 'No' },
                    ],
                    invoiceGenerated: [
                        { key: 'raised', label: 'Invoice Raised' },
                        { key: 'not_raised', label: 'Invoice Not Raised' },
                        { key: 'customer_billing_approved_and_po_pending', label: 'Customer Billing Approved & PO Pending' },
                        { key: 'customer_billing_approved_and_po_recieved', label: 'Customer Billing Approved & PO Recieved' },
                    ],
                    ticketTypes: [
                        { key: 'rma', label: 'RMA' },
                        { key: 'rl', label: 'RL' },
                        { key: 'po', label: 'PO' },
                        { key: 'field', label: 'Field' },
                        { key: 'migration', label: 'Migration' }
                    ],
                    ticketStatus: [
                        { key: 'rma_pending', label: 'RMA Pending' },
                        { key: 'new', label: 'New' },
                        { key: 'triggered', label: 'Triggered' },
                        { key: 'planned', label: 'Planned' },
                        { key: 'ongoing', label: 'Ongoing' },
                        { key: 'on_hold', label: 'OnHold' },
                        { key: 'sign_off_accepted', label: 'Sign Off Accepted' },
                        { key: 'sign_off_rejected', label: 'Sign Off Rejected' },
                        { key: 'sign_off_requested', label: 'Sign Off Requested' },
                        { key: 'customer_approval_pending', label: 'Customer Approval Pending' },
                        { key: 'sign_off_lock', label: 'Sign Off Lock' },
                        { key: 'site_complete', label: 'Site Complete' },
                        { key: 'hard_closed', label: 'Hard Closed' },
                        { key: 'revisit', label: 'Revisit' },
                        { key: 'cancelled', label: 'Cancelled' },
                    ],
                    ticketStatusSpFe: [
                        { key: 'rma_pending', label: 'RMA Pending' },
                        { key: 'planned', label: 'Planned' },
                        { key: 'ongoing', label: 'Ongoing' },
                        { key: 'on_hold', label: 'OnHold' },
                        { key: 'sign_off_accepted', label: 'Sign Off Accepted' },
                        { key: 'sign_off_rejected', label: 'Sign Off Rejected' },
                        { key: 'sign_off_requested', label: 'Sign Off Requested' },
                        { key: 'sign_off_lock', label: 'Sign Off Lock' },
                    ],
                    cityTier: [
                        { key: 'T1', label: 'T1' },
                        { key: 'T2', label: 'T2' },
                        { key: 'T3', label: 'T3' },
                        { key: 'T4', label: 'T4' },
                    ],
                    assignmentTypes: [
                        { key: 'BROADCAST', id: 'broadcast' },
                        { key: 'MANUAL', id: 'manual' },
                    ],
                    engineers,
                    partners,
                },
            };
        }

        case tickets.GET_TICKETS_COUNT_SUCCESS: {
            const { ticketsCount, role, type } = action.payload;
            let transformedTicketCount = state.ticketsCount;

            if (ticketsCount && Object.keys(ticketsCount).length > 0) {
                if (isPsp(role, type) || isSp(role, type)) {
                    transformedTicketCount = {
                        [allTab.path]: ticketsCount.sp_all_tickets_count,
                        [newAndCustomerTab.path]: ticketsCount.new,
                        [ongoingTab.path]: ticketsCount.sp_ongoing_tickets_count,
                        [IssuesTab.path]: ticketsCount.sp_onhold_tickets_count,
                        [completedTab.path]: ticketsCount.sp_completed_tickets_count,
                    };
                } else if (isFe(role, type)) {
                    transformedTicketCount = {
                        [allTab.path]: ticketsCount.fe_all_tickets_count,
                        [scheduledTab.path]: ticketsCount.fe_scheduled_tickets_count,
                        [ongoingTab.path]: ticketsCount.fe_ongoing_tickets_count,
                        [IssuesTab.path]: ticketsCount.fe_onhold_tickets_count,
                        [completedTab.path]: ticketsCount.fe_completed_tickets_count,
                    };
                } else {
                    transformedTicketCount = {
                        [allTab.path]: ticketsCount.alltickets,
                        [newAndCustomerTab.path]: isCluster(role, type) || isSuperVisor(role, type) ? ticketsCount.triggered : ticketsCount.new,
                        [triggerTab.path]: ticketsCount.triggered,
                        [plannedTab.path]: ticketsCount.planned,
                        [ongoingTab.path]: ticketsCount.ongoing,
                        [IssuesTab.path]: ticketsCount.escalated,
                        [completedTab.path]: ticketsCount.sitecomplete,
                        [closedTab.path]: ticketsCount.closed,
                    };
                }
            }
            return {
                ...state,
                ticketsCount: transformedTicketCount,
                filterTicketCount: transformedTicketCount,
            };
        }

        case tickets.GET_SP_NEW_TICKETS_LIST_SUCCESS: {
            return {
                ...state,
                spNewTicketsList: transformSpNewTicketsList(action.payload),
            };
        }

        case tickets.CLEAR_SP_ASSIGNED_NEW_TICKETS_LIST: {
            return {
                ...state,
                spAssignedNewTickets: undefined,
            };
        }

        case tickets.GET_ASSETS_CONFIG_TEMPLATE_SUCCESS:
            return {
                ...state,
                assetConfigLink: action.payload,
            };
        case tickets.GET_TICKET_FILTERED_LIST_SUCCESS: {
            const { ticketList, count } = action.payload;
            return {
                ...state,
                ticketList: transformTicketList(ticketList),
                openedTicketTabCount: count,
            };
        }

        case tickets.SET_TICKET_FILTER_DATA: {
            const { payload, page, limit, query, tab, mainTab } = action.payload;
            return {
                ...state,
                rowsPerPage: limit,
                page,
                searchQuery: query,
                filterPayload: payload,
                mainTab,
                tab,
            };
        }

        case tickets.SET_TICKETS_SIDEDRAWER:
            return {
                ...state,
                showTicketSideDrawer: action.payload.showTicketSideDrawer,
                sideBarName: action.payload.sideBarName,
                sideDrawerPayload: action.payload.ticketSideDrawerPayload,
            };

        case tickets.GET_TICKET_ASSETS_REMARKS_SUCCESS: {
            const { ticketAssets } = action.payload;
            const { assets, remarks, logistics, pos, spareassets, warehouses, ratings, agents, sitesPO, pm_details, templates } = ticketAssets;

            let transformed_ticket_list = state.ticketList;
            transformed_ticket_list = transformed_ticket_list.map((ticket) => {
                let assetList = [];
                let remarkList = [];
                let warehouse_details = [];
                let agents_list = [];
                let rltracking_detail = null;
                let tracking_detail = null;
                let po = {};
                let rating = {};
                let poList = [];
                let pm_first_name = '';
                let pm_last_name = '';
                let pm_mobile = '';
                let has_checklist = 0;
                if (Array.isArray(assets) && assets.length > 0) {
                    assetList = assets.filter((asset) => asset.fk_ticket_id === ticket.ticket_id);
                }
                if (Array.isArray(templates) && templates.length > 0) {
                    let findTemplate = templates.find((temp) => temp.id === ticket.fk_template_id);
                    if (findTemplate) has_checklist = findTemplate.has_checklist;
                }
                if (Array.isArray(ratings) && ratings.length > 0) {
                    rating = ratings.find((rate) => rate.fk_ticket_id === ticket.ticket_id);
                }
                if (Array.isArray(warehouses) && warehouses.length > 0) {
                    warehouse_details = warehouses.filter((warehouse) => warehouse.id === ticket.warehouse)?.[0];
                }

                if (Array.isArray(spareassets) && spareassets.length > 0 && !(ticket.parent_ticket_id !== 0 && ticket.serviceTypeId === 4)) {
                    let spareAssetList = spareassets.filter((asset) => asset.fk_ticket_id === ticket.ticket_id);
                    assetList = [...assetList, ...spareAssetList];
                }
                if (Array.isArray(remarks) && remarks.length > 0) {
                    remarkList = remarks.filter((remark) => remark.fk_ticket_id === ticket.ticket_id);
                }
                if (Array.isArray(logistics) && logistics.length > 0) {
                    rltracking_detail = logistics.find((tracking) => tracking.fk_ticket_id === ticket.ticket_id && tracking.docket_type === 2);
                    tracking_detail = logistics.find((tracking) => tracking.fk_ticket_id === ticket.ticket_id && tracking.docket_type === 1);
                }
                if (Array.isArray(pos) && pos.length > 0) {
                    po = pos.find((i) => i.ticketId === ticket.ticket_id);
                    if (po && po.ticketId) {
                        po.poCreationDate = po?.creationDate;
                        po.poNextRenewalDate = po?.nextRenewalDate;
                        po.poRenewedOn = po?.renewedOn;
                        delete po?.creationDate;
                        delete po?.nextRenewalDate;
                        delete po?.renewedOn;
                        delete po?.ticketId;
                        delete po._id;
                        delete po.__v;
                    }
                }
                if (Array.isArray(agents) && agents.length > 0) {
                    agents_list = agents.filter((agent) => agent.fk_ticket_id === ticket.ticket_id);
                }
                if (Array.isArray(sitesPO) && sitesPO.length > 0) {
                    poList = sitesPO.filter((po) => po.siteId > 0 && ticket.po_raised === 1 && po.siteId === ticket.siteId);
                }
                if (Array.isArray(pm_details) && pm_details.length > 0) {
                    let pm = pm_details.find((pm) => pm.fk_ticket_id === ticket.ticket_id);
                    if (pm) {
                        pm_first_name = pm.first_name;
                        pm_last_name = pm.last_name;
                        pm_mobile = pm.pm_mobile;
                    }
                }

                remarkList = remarkList.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                return { ...ticket, assets: assetList, remarks: remarkList, rltracking_detail, tracking_detail, warehouse_details, ...po, rating, agents: agents_list, poList, pm_first_name, pm_last_name, pm_mobile, has_checklist };
            });

            return {
                ...state,
                ticketList: transformed_ticket_list,
            };
        }

        case tickets.GET_TICKET_STATS_SUCCESS: {
            return {
                ...state,
                ticketStats: action.payload,
            };
        }

        case tickets.GET_TICKET_REMARKS_SUCCESS: {
            return {
                ...state,
                ticketRemark: action.payload?.remarks,
            };
        }

        case tickets.GET_TICKETS_BY_IDS_SUCCESS: {
            const ticketList = action.payload;
            const ticketByIdMapNew = new Map(ticketList.map((ticket) => [ticket.id.toString(), ticket]));
            const ticketByIdMap = new Map([...state.ticketByIdMap, ...ticketByIdMapNew]);
            return {
                ...state,
                ticketByIdMap,
            };
        }
        case tickets.PO_DETAILS_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                poDetails: data?.ticket?.po,
            };
        }
        case tickets.REMOVE_PO_DETAILS_FROM_STORE: {
            return {
                ...state,
                poDetails: null,
            };
        }
        case tickets.GET_SIGN_OFF_STEPS_SUCCESS:
            return {
                ...state,
                signOffSteps: action.payload,
            };
        case tickets.GET_SIGN_OFF_STEP_VALUES_SUCCESS:
            return {
                ...state,
                signOffStepValues: action.payload,
            };
        case tickets.CLEAR_TICKET_LIST:
            return {
                ...state,
                ticketList: [],
                openedTicketTabCount: undefined,
            };
        case tickets.GET_TICKET_DETAILS_BY_ID_SUCCESS: {

            return {
                ...state,
                ticketDetailsById: action.payload
            };
        }
        case tickets.UPDATE_VIEW_TICKET_FROM_WEBSOCKET: {
            const { ticket } = action.payload;
            return {
                ...state,
                viewTicket: ticket
            };
        }
        case tickets.UPDATE_VIEW_TICKET_SIGNOFF_DOCUMENT: {
            const { attachment } = action.payload;
            const viewTicket = state.viewTicket;
            const ticketDetails = state.ticketDetails || {};

            if (viewTicket && attachment.isSignOff) {
                viewTicket.sign_off_document = [];
            }
            if (ticketDetails && attachment.isSignOff) {
                ticketDetails.sign_off_document = [];
            }

            return {
                ...state,
                viewTicket,
                ticketDetails
            };
        }
        case tickets.UPDATE_TICKET_UNLOCK_SIGNOFF_STATUS_FROM_WEBSOCKET: {
            const { ticketId, sign_off_lock } = action.payload;
            let ticketList = state.ticketList;
            ticketList = ticketList.map((ticket) => {
                if (ticket.ticket_id === ticketId) {
                    return { ...ticket, sign_off_lock }
                }
                return ticket;
            })
            return {
                ...state,
                ticketList
            };
        }
        case tickets.GET_TICKET_DETAILS_FAILURE: {
            return {
                ...state,
                ticketDetailsError: true
            }
        }
        case tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_FAILURE: {
            return {
                ...state,
                openTicketDetailsError: true
            }
        }
        case tickets.NO_TICKET_FOUND: {
            return {
                ...state,
                noTicketFound: action.payload
            }
        }
        case tickets.GET_ACTIVE_VOICE_CALL_SUCCESS: {
            const { activeVoiceCallList } = action.payload;
            return {
                ...state,
                activeVoiceCallList
            }
        }
        case tickets.RECIEVED_ACTIVE_VOICE_CALL_FROM_WEBSOCKET: {
            const activeVoiceCallList = state.activeVoiceCallList;
            const isAlreadyExisting = activeVoiceCallList.find((ticket) => ticket.id === action.payload.id);
            if (!isAlreadyExisting) {
                activeVoiceCallList.push(action.payload);
            }
            return {
                ...state,
                activeVoiceCallList
            }
        }
        case tickets.VOICE_CALL_END_FROM_WEBSOCKET: {
            const activeVoiceCallList = state.activeVoiceCallList.filter((ticket) => {
                return ticket.id !== action.payload.id
            });

            return {
                ...state,
                activeVoiceCallList
            }
        }
        case tickets.GET_ISSUE_CATEGORY_FILTER_SUCCESS: {
            let blankIssueCategory = { key: 0, label: '(Blank)', id: 0 };
            let endCustomerUpdatedTicketInfo = { key: 0, label: 'End Customer updated ticket info', id: 72 };
            let endCustomerUploadedMediaInTicket = { key: 0, label: 'End Customer uploaded media in ticket', id: 73 };
            let hardCopyStatus = { key: 0, label: 'Hard Copy Recieved', id: 31 };
            let rlClosedStatus = { key: 0, label: 'RL is closed', id: 83 };
            let defaultIssueCategoriesList = [endCustomerUpdatedTicketInfo, endCustomerUploadedMediaInTicket, hardCopyStatus, rlClosedStatus, blankIssueCategory];
            let issue_categories = defaultIssueCategoriesList;
            if (Array.isArray(action.payload)) {
                issue_categories = [...action.payload, ...defaultIssueCategoriesList];
            }
            return {
                ...state,
                ticketFilterList: {
                    ...state.ticketFilterList || {},
                    issueCategories: issue_categories
                }
            }
        }
        case tickets.CREATE_INDEPENDENT_TICKETS_SUCCESS: {
            return {
                ...state,
                trackSupportTicketCreated: action.payload
            }
        }
        case tickets.UPDATE_TICKET_PROPERTIES_SUCCESS: {
            const { fk_ticket_id, created_by, created_at, id, event_master_id, event, input } = action.payload;
            let ticketRemark = state.ticketRemark;
            const rem = {
                created_by,
                created_at,
                event_master_id,
                event,
                id,
                remark: input
            }
            if (ticketRemark) {
                ticketRemark = [rem, ...ticketRemark]
            }
            const ticketList = state.ticketList.map((ticket) => {
                if (ticket.ticket_id === fk_ticket_id) {
                    return {
                        ...ticket, remarks: [rem, ...ticket.remarks]
                    }
                }
                return ticket
            });
            return {
                ...state,
                ticketList,
                ticketRemark
            }
        }

        case tickets.UPDATE_TICKET_SITE_PO_SUCCESS: {
            const { ticketId, sitePos } = action.payload;
            return {
                ...state,
                ticketSitePos: { ...state.ticketSitePos, [ticketId]: sitePos }
            }
        }
        case tickets.CNERGEE_ASSET_DEVICE_DETAILS_SUCCESS: {
            const { ticketId, data } = action.payload;
            return {
                ...state,
                cnergeeTicketDeviceData: { ...state.cnergeeTicketDeviceData, [ticketId]: data }
            }
        }

        case tickets.BULK_HARDCOPY_RECIEVED_UPDATE_FAILURE:
        case tickets.MOVE_TO_RMA_FAILURE:
        case tickets.ASK_APPOINTMENT_FAILURE:
        case tickets.UNLOCK_SIGNOFF_FAILURE:
        case tickets.GET_TICKET_DETAILS_BY_ID_FAILURE:
        case tickets.EDIT_EXECUTION_DATE_FAILURE:
        case tickets.BULK_EDIT_EXECUTION_DATE_FAILURE:
        case tickets.ASSIGN_TECH_SUPPORT_USERS_FAILURE:
        case tickets.CLONE_TICKETS_FAILURE:
        case tickets.CLOSE_FEASIBILITY_FAILURE:
        case tickets.ACCEPT_REJECT_TICKET_ASSET_FAILURE:
        case tickets.CUSTOMER_TICKET_SIGNOFF_FAILURE:
        case tickets.REJECT_TICKET_SIGNOFF_FAILURE:
        case tickets.UPDATE_TICKET_RMA_DETAILS_FAILURE:
        case tickets.CHANGE_TICKET_WAREHOUSE_FAILURE:
        case tickets.ASSIGN_TO_SCOGO_FAILURE:
        case tickets.BULK_ASSIGN_TO_SCOGO_FAILURE:
        case tickets.WITHDRAW_TICKET_FAILURE:
        case tickets.REASSIGN_SP_MANUALLY_FAILURE:
        case tickets.ASSIGN_SP_MANUALLY_FAILURE:
        case tickets.GET_TICKET_ASSETS_REMARKS_FAILURE:
        case tickets.GET_ASSETS_CONFIG_TEMPLATE_FAILURE:
        case tickets.GET_SP_NEW_TICKETS_LIST_FAILURE:
        case tickets.GET_FE_ACCEPTED_TICKETS_FAILURE:
        case tickets.GET_FE_TICKETS_COUNT_FAILURE:
        case tickets.GET_SP_TICKETS_COUNT_FAILURE:
        case tickets.GET_SP_ACCEPTED_TICKETS_FAILURE:
        case tickets.GET_TICKET_LIST_FAILURE:
        case tickets.GET_TICKET_PAYMENTS_FAILURE:
        case tickets.TICKET_REPORT_LINK_FAILURE:
        case tickets.EMAIL_REPORT_FAILURE:
        case tickets.SP_ASSIGNED_NEW_TICKETS_LIST_FAILURE:
        case tickets.EXEC_DATECHANGE_REMARKS_FAILURE:
        case tickets.SP_ONGOING_TICKETS_FAILURE:
        case tickets.UPDATE_TICKET_COURIER_STATUS_FAILURE:
        case tickets.GET_EXECUTION_DATE_TIMELINE_FAILURE:
        case tickets.VIEW_TICKET_FAILURE:
        case tickets.GET_TICKETS_BY_IDS_FAILURE:
        case tickets.GET_TICKETS_RESUME_FAILURE:
        case tickets.BULK_RESUME_TICKET_FAILURE:
        case tickets.ENGINEER_RATING_AND_COMMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default ticketReducer;

export const transformTicketList = (ticketList) => {
    if (Array.isArray(ticketList)) {
        return ticketList.map((ticket) => {
            let locality;
            let district;
            if (validateStringValue(ticket?.city?.city_name) !== validateStringValue(ticket?.district?.name)) {
                district = ticket?.district;
            }
            if (
                validateStringValue(ticket?.city?.city_name) !== validateStringValue(ticket?.locality?.name) &&
                validateStringValue(ticket?.district?.name) !== validateStringValue(ticket?.locality?.name)
            ) {
                locality = ticket.locality;
            }

            return {
                assignment_type: validateStringValue(ticket.assignment_type),
                broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
                customer_po_received: ticket.customer_po_received,
                customer_approval_received: ticket.customer_approval_received,
                symbo_invoice_no: ticket.symbo_invoice_no,
                symbo_policy_no: ticket.symbo_policy_no,
                ticketSpPaymentRatio: ticket?.accepted_sp?.payment_ratio,
                is_deleted: ticket.is_deleted,
                is_revisit_required: ticket.is_revisit_required,
                service_type_name: validateStringValue(ticket?.service_type?.service_name),
                external_ticket_id: validateStringValue(ticket?.external_ticket_id),
                created_by: ticket.created_by,
                current_execution_date: validateResponseDate(ticket.execution_date),
                ticket_id: ticket.id,
                job_ticket_number: ticket.job_ticket_number,
                distance_travelled: ticket?.distance_travelled,
                customer_name: validateStringValue(ticket?.customer?.customer_company_name),
                enduser_name: validateStringValue(ticket?.end_user?.end_user_name),
                project_name: validateStringValue(ticket?.project?.project_name),
                external_site_id: validateStringValue(ticket?.site?.external_site_id),
                site_branch_name: validateStringValue(ticket?.site?.site_branch_name),
                site_address: `${validateStringValue(ticket.job_site_address_1)} ${validateStringValue(ticket.job_site_address_2)}`.trim(),
                job_fk_pincode_id: validateNumberValue(ticket.job_fk_pincode_id),
                ticket_description: validateStringValue(ticket.description),
                ticket_title: validateStringValue(ticket.title),
                city: validateStringValue(ticket?.city?.city_name),
                cityTier: validateStringValue(ticket?.city?.city_tier),
                state: validateStringValue(ticket?.state?.state_name),
                cluster_name: `${validateStringValue(ticket?.cluster?.first_name)} ${validateStringValue(ticket?.cluster?.last_name)}`.trim(),
                cluster_mobile: validateNumberValue(ticket?.cluster?.mobile),
                cluster_email: ticket?.cluster?.email,
                cluster_id: ticket?.cluster?.id,
                psp_name: `${validateStringValue(ticket?.psp?.first_name)} ${validateStringValue(ticket?.psp?.last_name)}`,
                psp_mobile: ticket?.psp?.mobile,
                psp_email: ticket?.psp?.email,
                psp_id: ticket?.psp?.id,
                supervisor_name: `${validateStringValue(ticket?.supervisor_details?.first_name)} ${validateStringValue(ticket?.supervisor_details?.last_name)}`.trim(),
                supervisor_mobile: validateNumberValue(ticket?.supervisor_details?.mobile),
                supervisor_id: validateNumberValue(ticket?.supervisor_details?.id),
                supervisor_email: validateNumberValue(ticket?.supervisor_details?.email),
                sp_name: ticket?.accepted_sp?.service_partner?.sp_name
                    ? `${validateStringValue(ticket?.accepted_sp?.service_partner?.sp_name)}`
                    : `${validateStringValue(ticket?.accepted_sp?.first_name)} ${validateStringValue(ticket?.accepted_sp?.last_name)}`.trim(),
                sp_email: validateStringValue(ticket?.accepted_sp?.email),
                sp_mobile: validateNumberValue(ticket?.accepted_sp?.mobile),
                fe_mobile: validateNumberValue(ticket?.accepted_fe?.mobile),
                fe_email: validateStringValue(ticket?.accepted_fe?.email),
                fe_name: `${validateStringValue(ticket?.accepted_fe?.first_name)} ${validateStringValue(ticket?.accepted_fe?.last_name)}`.trim(),
                local_contact_name: validateStringValue(ticket.job_site_contact_name),
                local_contact_mobile: validateNumberValue(ticket.job_site_contact_mobile),
                local_contact_email: validateStringValue(ticket.job_site_contact_mail),
                partner_base_payment: validateNumberValue(ticket.partner_base_payment),
                use_case_name: validateStringValue(ticket?.usecase?.use_case_name),
                cluster_base_payment: validateNumberValue(ticket?.cluster_base_payment),
                psp_base_payment: validateNumberValue(ticket?.psp_base_payment),
                supervisor_base_payment: validateNumberValue(ticket?.supervisor_base_payment),
                warehouse_owner_base_payment: validateNumberValue(ticket?.warehouse_owner_base_payment),
                customer_base_price: validateNumberValue(ticket.project?.customer_base_price),
                customer_additional_expenses: validateNumberValue(ticket.customer_additional_expenses),
                additional_expenses: validateNumberValue(ticket.additional_expenses),
                additional_amount_paid: validateNumberValue(ticket.additional_amount_paid),
                supervisor_amount_paid: validateNumberValue(ticket.supervisor_amount_paid),
                warehouse_expenses: validateNumberValue(ticket.warehouse_expenses),
                psp_additional_incentive: validateNumberValue(ticket.psp_additional_incentive),
                additional_incentive: validateNumberValue(ticket.additional_incentive),
                created_at: validateResponseDate(ticket.created_at),
                execution_date: validateResponseDate(ticket.execution_date),
                planned_date: validateResponseDate(ticket.planned_date),
                site_completed_on: validateResponseDate(ticket.site_completed_on),
                sla_details: ticket.sla_details,
                tracking_detail: ticket.tracking_detail,
                rltracking_detail: ticket.rltracking_detail,
                signOffAccept: ticket.is_signoff_accept,
                signOffRequest: ticket.is_sign_off_request,
                signOffReject: ticket.is_signoff_rejected,
                hardClose: ticket.is_hard_close,
                closedByNoc: ticket.closed_by_noc,
                reachedSite: ticket.reached_site,
                isRmaRaised: ticket.is_rma_raised,
                customerSignOffAccept: ticket.is_customer_signoff_accept,
                customerSignOffRequired: ticket.is_customer_signoff_required,
                requestRaised: ticket.request_raised,
                serviceTypeId: ticket.service_type_id,
                assignToScogo: ticket.assign_to_scogo,
                remarks: [],
                assets: [],
                job_sign_off_notes: ticket.job_sign_off_notes,
                pm_rejection_message: ticket.pm_rejection_message,
                isEscalated: ticket.is_escalated,
                ticket_owned_by: ticket.ticket_owned_by,
                isOnHold: ticket.is_on_hold,
                jobStatus: ticket.job_status,
                accepted_fe_id: ticket.accepted_fe_id,
                accepted_sp_id: ticket.accepted_sp_id,
                ticketType: ticket.ticket_type,
                fk_assigned_primary_sp_id: ticket.fk_assigned_primary_sp_id,
                cl_rma_response: ticket.cl_rma_response,
                revisitCount: ticket.revisit_count_value,
                warehouse_owner: ticket.warehouse_owner,
                request_additional_amount: ticket.request_additional_amount,
                parent_ticket_id: ticket.parent_ticket_id,
                sign_off_response: ticket.sign_off_response,
                hard_copy_required: ticket.hard_copy_required,
                is_report_generated: ticket.is_report_generated,
                po_raised: ticket.po_raised,
                is_live_tracking_enabled: ticket.is_live_tracking_enabled,
                asset_config_required: ticket.asset_config_required,
                fk_sign_off_template_id: ticket.fk_sign_off_template_id,
                consoleUrl: `${config.remoteTerminal}/${ticket?.id}`,
                migrateTo: ticket.migrate_to,
                warehouse: ticket.warehouse,
                customerId: ticket.fk_customer_id,
                siteId: ticket.site_id,
                customer_invoice_id: ticket.customer_invoice_id,
                live_tracking_url: validateStringValue(ticket.live_tracking_url),
                hard_copy_received: ticket?.hard_copy_received,
                remote_teminal_connection_status: ticket?.remote_teminal_connection_status,
                remote_teminal_connection_url: ticket?.remote_teminal_connection_url,
                latitude: ticket?.latitude,
                longitude: ticket?.longitude,
                locality,
                district,
                sign_off_lock: ticket?.sign_off_lock,
                issue_category: validateNumberValue(ticket?.issue_category),
                issue_title: validateStringValue(ticket?.issue?.title),
                end_user_id: ticket?.end_user_id,
                job_fk_city_id: ticket?.job_fk_city_id,
                job_fk_state_id: ticket?.job_fk_state_id,
                is_rma_eligible: validateNumberValue(ticket?.customer?.is_rma_eligible),
                replacement_required: validateNumberValue(ticket?.project?.replacement_required),
                room_call_status: ticket?.room_call_status,
                room_id: ticket?.fk_room_id,
                scogo_pm_name: validateStringValue(ticket?.project?.scogo_pm_name),
                scogo_pm_mobile: validateStringValue(ticket?.project?.scogo_pm_mobile),
                scogo_pm_id: validateStringValue(ticket?.project?.scogo_pm_id),
                fk_project_id: validateStringValue(ticket?.project?.id),
                fk_template_id: validateNumberValue(ticket?.fk_template_id),
                project_sla: validateNumberValue(ticket.project_sla),
                ticket_source: ticket.ticket_source,
                domain: ticket?.customer?.domain,
                project_price: ticket?.project?.project_price,
                psp_incentive: ticket?.project?.psp_incentive,
                customer_paid_to_scogo: parseFloat(ticket?.customer_paid_to_scogo) || 0,
            };
        });
    }
    return [];
};

const transformSPTicketList = (ticketList) => {
    if (Array.isArray(ticketList)) {
        return ticketList.map((ticket) => {
            let locality;
            let district;
            if (validateStringValue(ticket?.city?.city_name) !== validateStringValue(ticket?.district?.name)) {
                district = ticket?.district;
            }
            if (
                validateStringValue(ticket?.city?.city_name) !== validateStringValue(ticket?.locality?.name) &&
                validateStringValue(ticket?.district?.name) !== validateStringValue(ticket?.locality?.name)
            ) {
                locality = ticket.locality;
            }
            return {
                assignment_type: validateStringValue(ticket.assignment_type),
                broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
                customer_po_received: ticket.customer_po_received,
                customer_approval_received: ticket.customer_approval_received,
                symbo_invoice_no: ticket.symbo_invoice_no,
                symbo_policy_no: ticket.symbo_policy_no,
                distance_travelled: ticket?.distance_travelled,
                ticketSpPaymentRatio: ticket?.accepted_sp?.payment_ratio,
                psp_id: ticket.psp_id,
                customer_additional_expenses: validateNumberValue(ticket.customer_additional_expenses), //
                additional_expenses: validateNumberValue(ticket.additional_expenses), //
                additional_amount_paid: validateNumberValue(ticket.additional_amount_paid), //
                supervisor_amount_paid: validateNumberValue(ticket.supervisor_amount_paid), //
                warehouse_expenses: validateNumberValue(ticket.warehouse_expenses), //
                psp_additional_incentive: validateNumberValue(ticket.psp_additional_incentive), ///
                additional_incentive: validateNumberValue(ticket.additional_incentive), //
                is_deleted: ticket.is_deleted,
                is_revisit_required: ticket.is_revisit_required,
                service_type_name: validateStringValue(ticket?.service_type?.service_name),
                external_ticket_id: validateStringValue(ticket?.external_ticket_id),
                current_execution_date: validateResponseDate(ticket.execution_date),
                ticket_id: ticket.id,
                job_ticket_number: ticket.job_ticket_number,
                customer_name: validateStringValue(ticket?.customer?.customer_company_name),
                enduser_name: validateStringValue(ticket?.end_user_detail?.end_user_name),
                project_name: validateStringValue(ticket?.project_name),
                external_site_id: validateStringValue(ticket?.site_detail?.external_site_id),
                site_branch_name: validateStringValue(ticket?.site_detail?.site_branch_name),
                site_address: `${validateStringValue(ticket?.job_site_address_1)} ${validateStringValue(ticket?.job_site_address_2)}`,
                job_fk_pincode_id: ticket?.job_fk_pincode_id ? ticket?.job_fk_pincode_id : '',
                ticket_description: validateStringValue(ticket.description),
                ticket_title: validateStringValue(ticket.title),
                city: validateStringValue(ticket?.job_city?.city_name),
                state: validateStringValue(ticket?.job_state?.state_name),
                use_case_name: validateStringValue(ticket?.use_case?.use_case_name),
                local_contact_name: validateStringValue(ticket.job_site_contact_name),
                local_contact_mobile: validateNumberValue(ticket.job_site_contact_mobile),
                local_contact_email: validateStringValue(ticket.job_site_contact_mail),
                partner_base_payment: validateNumberValue(ticket.partner_base_payment),
                cluster_base_payment: validateNumberValue(ticket?.cluster_base_payment),
                psp_base_payment: validateNumberValue(ticket?.psp_base_payment),
                supervisor_base_payment: validateNumberValue(ticket?.supervisor_base_payment),
                warehouse_owner_base_payment: validateNumberValue(ticket?.warehouse_owner_base_payment),
                created_at: validateResponseDate(ticket.created_at),
                execution_date: validateResponseDate(ticket.execution_date),
                planned_date: validateResponseDate(ticket.planned_date),
                site_completed_on: validateResponseDate(ticket.site_completed_on),
                signOffAccept: ticket.is_signoff_accept,
                signOffRequest: ticket.is_sign_off_request,
                signOffReject: ticket.is_signoff_rejected,
                hardClose: ticket.is_hard_close,
                closedByNoc: ticket.closed_by_noc,
                reachedSite: ticket.reached_site,
                isRmaRaised: ticket.is_rma_raised,
                customerSignOffAccept: ticket.is_customer_signoff_accept,
                customerSignOffRequired: ticket.is_customer_signoff_required,
                requestRaised: ticket.request_raised,
                serviceTypeId: ticket.service_type_id,
                job_sign_off_notes: ticket.job_sign_off_notes,
                pm_rejection_message: ticket.pm_rejection_message,
                assignToScogo: ticket.assign_to_scogo,
                remarks: [],
                assets: [],
                ticket_owned_by: ticket.ticket_owned_by,
                isOnHold: ticket.is_on_hold,
                accepted_fe_id: ticket.accepted_fe_id,
                accepted_sp_id: ticket.accepted_sp_id,
                sp_name: `${validateStringValue(ticket?.accepted_s_p_detail?.first_name)} ${validateStringValue(ticket?.accepted_s_p_detail?.last_name)}`.trim(),
                sp_email: validateStringValue(ticket?.accepted_s_p_detail?.email),
                sp_mobile: validateNumberValue(ticket?.accepted_s_p_detail?.mobile),
                fe_mobile: validateNumberValue(ticket?.accepted_fe_detail?.mobile),
                fe_email: validateStringValue(ticket?.accepted_fe_detail?.email),
                fe_name: `${validateStringValue(ticket?.accepted_fe_detail?.first_name)} ${validateStringValue(ticket?.accepted_fe_detail?.last_name)}`.trim(),
                ticketType: ticket.ticket_type,
                fk_assigned_primary_sp_id: ticket.fk_assigned_primary_sp_id,
                cl_rma_response: ticket.cl_rma_response,
                revisitCount: ticket.revisit_count_value,
                warehouse_owner: ticket.warehouse_owner,
                request_additional_amount: ticket.request_additional_amount,
                parent_ticket_id: ticket.parent_ticket_id,
                sign_off_response: ticket.sign_off_response,
                hard_copy_required: ticket.hard_copy_required,
                is_report_generated: ticket.is_report_generated,
                po_raised: ticket.po_raised,
                is_live_tracking_enabled: ticket.is_live_tracking_enabled,
                isEscalated: ticket.is_escalated, // not find
                customer_base_price: '', // not found
                sla_details: ticket.sla_details, // not found
                tracking_detail: ticket.tracking_detail, // not found
                rltracking_detail: ticket.rltracking_detail, // not found
                asset_config_required: ticket.asset_config_required,
                fk_sign_off_template_id: ticket.fk_sign_off_template_id,
                psp_name: `${validateStringValue(ticket?.psp_details?.first_name)} ${validateStringValue(ticket?.psp_details?.last_name)}`.trim(),
                cluster_name: `${validateStringValue(ticket?.cl_details?.first_name)} ${validateStringValue(ticket?.cl_details?.last_name)}`.trim(),
                cluster_id: ticket?.cluster_id,
                live_tracking_url: validateStringValue(ticket.live_tracking_url),
                hard_copy_received: ticket?.hard_copy_received,
                consoleUrl: `${config.remoteTerminal}/${ticket?.id}`,
                remote_teminal_connection_status: ticket?.remote_teminal_connection_status,
                remote_teminal_connection_url: ticket?.remote_teminal_connection_url,
                latitude: ticket?.latitude,
                longitude: ticket?.longitude,
                locality,
                district,
                sign_off_lock: ticket?.sign_off_lock,
                issue_category: validateNumberValue(ticket?.issue_category),
                issue_title: validateStringValue(ticket?.issue?.title),
                end_user_id: ticket?.end_user_id,
                job_fk_city_id: ticket?.job_fk_city_id,
                job_fk_state_id: ticket?.job_fk_state_id,
                is_rma_eligible: validateNumberValue(ticket?.customer?.is_rma_eligible),
                replacement_required: validateNumberValue(ticket?.project?.replacement_required),
                room_call_status: ticket?.room_call_status,
                room_id: ticket?.fk_room_id,
                scogo_pm_name: validateStringValue(ticket?.project?.scogo_pm_name),
                scogo_pm_mobile: validateStringValue(ticket?.project?.scogo_pm_mobile),
                scogo_pm_id: validateStringValue(ticket?.project?.scogo_pm_id),
                fk_project_id: validateStringValue(ticket?.project?.id),
                fk_template_id: validateNumberValue(ticket?.fk_template_id),
                project_sla: validateNumberValue(ticket.project_sla),
                ticket_source: ticket.ticket_source,
                domain: ticket?.customer?.domain
            };
        });
    }
    return [];
};

const transformFETicketList = (ticketList) => {
    if (Array.isArray(ticketList)) {
        return ticketList.map((ticket) => {
            let locality;
            let district;
            if (validateStringValue(ticket?.city?.city_name) !== validateStringValue(ticket?.district?.name)) {
                district = ticket?.district;
            }
            if (
                validateStringValue(ticket?.city?.city_name) !== validateStringValue(ticket?.locality?.name) &&
                validateStringValue(ticket?.district?.name) !== validateStringValue(ticket?.locality?.name)
            ) {
                locality = ticket.locality;
            }
            return {
                assignment_type: validateStringValue(ticket.assignment_type),
                broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
                customer_po_received: ticket.customer_po_received,
                customer_approval_received: ticket.customer_approval_received,
                symbo_invoice_no: ticket.symbo_invoice_no,
                symbo_policy_no: ticket.symbo_policy_no,
                ticketSpPaymentRatio: ticket?.accepted_sp?.payment_ratio,
                distance_travelled: ticket?.distance_travelled,
                psp_id: ticket.psp_id,
                is_deleted: ticket?.is_deleted,
                is_revisit_required: ticket.is_revisit_required,
                service_type_name: validateStringValue(ticket?.service_type?.service_name),
                external_ticket_id: validateStringValue(ticket?.external_ticket_id),
                current_execution_date: validateResponseDate(ticket.execution_date),
                ticket_id: ticket.id,
                job_ticket_number: ticket.job_ticket_number,
                customer_name: validateStringValue(ticket?.customer?.customer_company_name),
                enduser_name: validateStringValue(ticket?.end_user_detail?.end_user_name),
                project_name: validateStringValue(ticket?.project_name),
                external_site_id: validateStringValue(ticket?.site_detail?.external_site_id),
                site_branch_name: validateStringValue(ticket?.site_detail?.site_branch_name),
                site_address: `${validateStringValue(ticket?.job_site_address_1)} ${validateStringValue(ticket?.job_site_address_2)}`,
                job_fk_pincode_id: ticket?.job_fk_pincode_id ? ticket?.job_fk_pincode_id : '',
                ticket_description: validateStringValue(ticket.description),
                ticket_title: validateStringValue(ticket.title),
                city: validateStringValue(ticket?.job_city?.city_name),
                cityTier: validateStringValue(ticket?.city?.city_tier),
                state: validateStringValue(ticket?.job_state?.state_name),
                use_case_name: validateStringValue(ticket?.use_case?.use_case_name),
                partner_base_payment: validateNumberValue(ticket.partner_base_payment),
                cluster_base_payment: validateNumberValue(ticket?.cluster_base_payment),
                psp_base_payment: validateNumberValue(ticket?.psp_base_payment),
                supervisor_base_payment: validateNumberValue(ticket?.supervisor_base_payment),
                warehouse_owner_base_payment: validateNumberValue(ticket?.warehouse_owner_base_payment),
                created_at: validateResponseDate(ticket.created_at),
                execution_date: validateResponseDate(ticket.execution_date),
                planned_date: validateResponseDate(ticket.planned_date),
                site_completed_on: validateResponseDate(ticket.site_completed_on),
                signOffAccept: ticket.is_signoff_accept,
                signOffRequest: ticket.is_sign_off_request,
                signOffReject: ticket.is_signoff_rejected,
                hardClose: ticket.is_hard_close,
                closedByNoc: ticket.closed_by_noc,
                reachedSite: ticket.reached_site,
                isRmaRaised: ticket.is_rma_raised,
                customerSignOffAccept: ticket.is_customer_signoff_accept,
                customerSignOffRequired: ticket.is_customer_signoff_required,
                requestRaised: ticket.request_raised,
                serviceTypeId: ticket.service_type_id,
                job_sign_off_notes: ticket.job_sign_off_notes,
                pm_rejection_message: ticket.pm_rejection_message,
                assignToScogo: ticket.assign_to_scogo,
                remarks: [],
                assets: [],
                ticket_owned_by: ticket.ticket_owned_by,
                isOnHold: ticket.is_on_hold,
                accepted_fe_id: ticket.accepted_fe_id,
                accepted_sp_id: ticket.accepted_sp_id,
                sp_name: `${validateStringValue(ticket?.accepted_s_p_detail?.first_name)} ${validateStringValue(ticket?.accepted_s_p_detail?.last_name)}`.trim(),
                sp_email: validateStringValue(ticket?.accepted_s_p_detail?.email),
                sp_mobile: validateNumberValue(ticket?.accepted_s_p_detail?.mobile),
                fe_mobile: validateNumberValue(ticket?.accepted_fe_detail?.mobile),
                fe_email: validateStringValue(ticket?.accepted_fe_detail?.email),
                fe_name: `${validateStringValue(ticket?.accepted_fe_detail?.first_name)} ${validateStringValue(ticket?.accepted_fe_detail?.last_name)}`.trim(),
                ticketType: ticket.ticket_type,
                fk_assigned_primary_sp_id: ticket.fk_assigned_primary_sp_id,
                cl_rma_response: ticket.cl_rma_response,
                isEscalated: ticket.is_escalated,
                revisitCount: ticket.revisit_count_value,
                warehouse_owner: ticket.warehouse_owner,
                additional_amount_paid: validateNumberValue(ticket.additional_amount_paid),
                request_additional_amount: ticket.request_additional_amount,
                parent_ticket_id: ticket.parent_ticket_id,
                sign_off_response: ticket.sign_off_response,
                hard_copy_required: ticket.hard_copy_required,
                is_report_generated: ticket.is_report_generated,
                po_raised: ticket.po_raised,
                is_live_tracking_enabled: ticket.is_live_tracking_enabled,
                asset_config_required: ticket.asset_config_required,
                fk_sign_off_template_id: ticket.fk_sign_off_template_id,
                psp_name: `${validateStringValue(ticket?.psp_details?.first_name)} ${validateStringValue(ticket?.psp_details?.last_name)}`.trim(),
                cluster_name: `${validateStringValue(ticket?.cl_details?.first_name)} ${validateStringValue(ticket?.cl_details?.last_name)}`.trim(),
                cluster_id: ticket?.cluster_id,
                live_tracking_url: validateStringValue(ticket.live_tracking_url),
                hard_copy_received: ticket?.hard_copy_received,
                consoleUrl: `${config.remoteTerminal}/${ticket?.id}`,
                remote_teminal_connection_status: ticket?.remote_teminal_connection_status,
                remote_teminal_connection_url: ticket?.remote_teminal_connection_url,
                latitude: ticket?.latitude,
                longitude: ticket?.longitude,
                locality,
                district,
                sign_off_lock: ticket?.sign_off_lock,
                issue_category: validateNumberValue(ticket?.issue_category),
                issue_title: validateStringValue(ticket?.issue?.title),
                end_user_id: ticket?.end_user_id,
                job_fk_city_id: ticket?.job_fk_city_id,
                job_fk_state_id: ticket?.job_fk_state_id,
                is_rma_eligible: validateNumberValue(ticket?.customer?.is_rma_eligible),
                replacement_required: validateNumberValue(ticket?.project?.replacement_required),
                room_call_status: ticket?.room_call_status,
                room_id: ticket?.fk_room_id,
                scogo_pm_name: validateStringValue(ticket?.project?.scogo_pm_name),
                scogo_pm_mobile: validateStringValue(ticket?.project?.scogo_pm_mobile),
                scogo_pm_id: validateStringValue(ticket?.project?.scogo_pm_id),
                fk_project_id: validateStringValue(ticket?.project?.id),
                fk_template_id: validateNumberValue(ticket?.fk_template_id),
                project_sla: validateNumberValue(ticket.project_sla),
                ticket_source: ticket.ticket_source,
                domain: ticket?.customer?.domain,
            };
        });
    }
    return [];
};

const transformSpNewTicketsList = (ticketList) => {
    return ticketList.map((ticket) => {
        return {
            customer_po_received: ticket.customer_po_received,
            customer_approval_received: ticket.customer_approval_received,
            symbo_invoice_no: ticket.symbo_invoice_no,
            symbo_policy_no: ticket.symbo_policy_no,
            ticketSpPaymentRatio: ticket?.accepted_sp?.payment_ratio,
            is_deleted: ticket?.is_deleted,
            is_revisit_required: ticket.is_revisit_required,
            ticket_id: ticket.id,
            job_ticket_number: ticket.job_ticket_number,
            city_name: ticket.job_city.city_name,
            cityTier: validateStringValue(ticket?.city?.city_tier),
            project_created_by: ticket.project_created_by,
            project_price: ticket.project_price,
            service_type_name: validateStringValue(ticket?.service_type?.service_name),
            external_site_id: ticket?.site_detail?.external_site_id,
            site_branch_name: ticket?.site_detail?.site_branch_name,
            execution_date: validateResponseDate(ticket.execution_date),
            job_site_contact_name: ticket.job_site_contact_name,
            signOffRequest: ticket?.is_sign_off_request,
            job_site_contact_mobile: ticket.job_site_contact_mobile,
            job_site_contact_email: ticket.job_site_contact_mail,
            job_site_address: `${ticket.job_site_address_1} ${ticket.job_site_address_2}`,
            use_case_name: ticket?.use_case?.use_case_name,
            job_fk_pincode_id: ticket?.job_fk_pincode_id,
            ticket_description: ticket.description,
            project_description: ticket.project_description,
            psp_base_payment: ticket.psp_base_payment,
            partner_base_payment: validateNumberValue(ticket.partner_base_payment),
            additional_expenses: ticket.additional_expenses,
            psp_id: ticket.psp_id,
            boqDetails: ticketBoqDetails(ticket.assets),
            asset_config_required: ticket.asset_config_required,
            tracking_detail: ticket.tracking_detail ? ticket.tracking_detail : undefined,
            broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
            sla_unit: ticket.sla_unit,
            assign_to_scogo: ticket.assign_to_scogo,
            ticket_owned_by: ticket.ticket_owned_by,
            isOnHold: ticket.is_on_hold,
            accepted_fe_id: ticket.accepted_fe_id,
            psp_name: `${validateStringValue(ticket?.psp_details?.first_name)} ${validateStringValue(ticket?.psp_details?.last_name)}`.trim(),
            cluster_name: `${validateStringValue(ticket?.cl_details?.first_name)} ${validateStringValue(ticket?.cl_details?.last_name)}`.trim(),
            cluster_id: ticket?.cluster_id,
            accepted_sp_id: ticket.accepted_sp_id,
            ticketType: ticket.ticket_type,
            fk_assigned_primary_sp_id: ticket.fk_assigned_primary_sp_id,
            cl_rma_response: ticket.cl_rma_response,
            isEscalated: ticket.is_escalated,
            revisitCount: ticket.revisit_count_value,
            warehouse_owner: ticket.warehouse_owner,
            current_execution_date: validateResponseDate(ticket.execution_date),
            is_live_tracking_enabled: ticket.is_live_tracking_enabled,
            fk_sign_off_template_id: ticket.fk_sign_off_template_id,
            isRmaRaised: ticket.is_rma_raised,
            serviceTypeId: ticket.service_type_id,
            consoleUrl: `${config.remoteTerminal}/${ticket?.id}`,
            end_user_name: validateStringValue(ticket?.end_user_detail?.end_user_name),
            additional_amount_paid: validateNumberValue(ticket.additional_amount_paid),
            live_tracking_url: validateStringValue(ticket.live_tracking_url),
            hard_copy_received: ticket?.hard_copy_received,
            remote_teminal_connection_status: ticket?.remote_teminal_connection_status,
            remote_teminal_connection_url: ticket?.remote_teminal_connection_url,
            latitude: ticket?.latitude,
            longitude: ticket?.longitude,
            sign_off_lock: ticket?.sign_off_lock,
            issue_category: validateNumberValue(ticket?.issue_category),
            issue_title: validateStringValue(ticket?.issue?.title),
            end_user_id: ticket?.end_user_id,
            job_fk_city_id: ticket?.job_fk_city_id,
            job_fk_state_id: ticket?.job_fk_state_id,
            is_rma_eligible: validateNumberValue(ticket?.customer?.is_rma_eligible),
            replacement_required: validateNumberValue(ticket?.project?.replacement_required),
            scogo_pm_name: ticket?.pm_detail ? `${validateStringValue(ticket?.pm_detail.first_name)} ${validateStringValue(ticket?.pm_detail.last_name)}` : validateStringValue(ticket.project_created_by),
            room_call_status: ticket?.room_call_status,
            room_id: ticket?.fk_room_id,
            fk_project_id: validateStringValue(ticket?.project?.id),
            fk_template_id: validateNumberValue(ticket?.fk_template_id),
            project_sla: validateNumberValue(ticket.project_sla),
            ticket_source: ticket.ticket_source,
            domain: ticket?.customer?.domain
        };
    });
};

export const transformTicketforChatAction = (ticket) => {
    return {
        assignment_type: validateStringValue(ticket.assignment_type),
        broadcast_timestamp: validateResponseDate(ticket.broadcast_timestamp),
        customer_po_received: ticket.customer_po_received,
        customer_approval_received: ticket.customer_approval_received,
        is_deleted: ticket?.is_deleted,
        is_revisit_required: ticket?.is_revisit_required,
        service_type_name: validateStringValue(ticket?.service_type?.service_name),
        external_ticket_id: validateStringValue(ticket?.external_ticket_id),
        created_by: ticket?.created_by,
        current_execution_date: validateResponseDate(ticket.execution_date),
        ticket_id: ticket?.id,
        job_ticket_number: ticket?.job_ticket_number,
        distance_travelled: ticket?.distance_travelled,
        customer_name: validateStringValue(ticket?.customer?.customer_company_name),
        enduser_name: validateStringValue(ticket?.end_user?.end_user_name || ticket?.end_user_detail?.end_user_name),
        project_name: validateStringValue(ticket?.project?.project_name),
        external_site_id: validateStringValue(ticket?.site?.external_site_id || ticket?.site_detail?.external_site_id),
        site_branch_name: validateStringValue(ticket?.site?.site_branch_name || ticket?.site_detail?.site_branch_name),
        site_address: `${validateStringValue(ticket?.job_site_address_1)} ${validateStringValue(ticket?.job_site_address_2)}`.trim(),
        job_fk_pincode_id: validateNumberValue(ticket?.job_fk_pincode_id),
        ticket_description: validateStringValue(ticket?.description),
        ticket_title: validateStringValue(ticket?.title),
        city: validateStringValue(ticket?.city?.city_name),
        cityTier: validateStringValue(ticket?.city?.city_tier),
        state: validateStringValue(ticket?.state?.state_name),
        cluster_name: `${validateStringValue(ticket?.cluster?.first_name)} ${validateStringValue(ticket?.cluster?.last_name)}`.trim(),
        cluster_mobile: validateNumberValue(ticket?.cluster?.mobile),
        cluster_email: ticket?.cluster?.email,
        cluster_id: ticket?.cluster?.id,
        psp_name: `${validateStringValue(ticket?.psp?.first_name)} ${validateStringValue(ticket?.psp?.last_name)}`.trim(),
        psp_mobile: ticket?.psp?.mobile,
        psp_email: ticket?.psp?.email,
        psp_id: ticket?.psp?.id,
        supervisor_name: `${validateStringValue(ticket?.supervisor_details?.first_name)} ${validateStringValue(ticket?.supervisor_details?.last_name)}`.trim(),
        supervisor_mobile: validateNumberValue(ticket?.supervisor_details?.mobile),
        supervisor_id: validateNumberValue(ticket?.supervisor_details?.id),
        supervisor_email: validateNumberValue(ticket?.supervisor_details?.email),
        sp_name: ticket?.accepted_sp?.service_partner?.sp_name
            ? `${validateStringValue(ticket?.accepted_sp?.service_partner?.sp_name)}`
            : `${validateStringValue(ticket?.accepted_sp?.first_name)} ${validateStringValue(ticket?.accepted_sp?.last_name)}`.trim(),
        sp_email: validateStringValue(ticket?.accepted_sp?.email),
        sp_mobile: validateNumberValue(ticket?.accepted_sp?.mobile),
        fe_mobile: validateNumberValue(ticket?.accepted_fe?.mobile || ticket?.fe_mobile),
        fe_email: validateStringValue(ticket?.accepted_fe?.email),
        fe_name: `${validateStringValue(ticket?.accepted_fe?.first_name || ticket?.fe_first_name)} ${validateStringValue(
            ticket?.accepted_fe?.last_name || ticket?.fe_last_name
        )}`.trim(),
        local_contact_name: validateStringValue(ticket?.job_site_contact_name),
        local_contact_mobile: validateNumberValue(ticket?.job_site_contact_mobile),
        local_contact_email: validateStringValue(ticket?.job_site_contact_mail),
        partner_base_payment: validateNumberValue(ticket?.partner_base_payment),
        use_case_name: validateStringValue(ticket?.usecase?.use_case_name),
        cluster_base_payment: validateNumberValue(ticket?.cluster_base_payment),
        psp_base_payment: validateNumberValue(ticket?.psp_base_payment),
        supervisor_base_payment: validateNumberValue(ticket?.supervisor_base_payment),
        warehouse_owner_base_payment: validateNumberValue(ticket?.warehouse_owner_base_payment),
        customer_base_price: validateNumberValue(ticket?.project?.customer_base_price),
        customer_additional_expenses: validateNumberValue(ticket?.customer_additional_expenses),
        additional_expenses: validateNumberValue(ticket?.additional_expenses),
        additional_amount_paid: validateNumberValue(ticket?.additional_amount_paid),
        supervisor_amount_paid: validateNumberValue(ticket?.supervisor_amount_paid),
        warehouse_expenses: validateNumberValue(ticket?.warehouse_expenses),
        psp_additional_incentive: validateNumberValue(ticket?.psp_additional_incentive),
        additional_incentive: validateNumberValue(ticket?.additional_incentive),
        created_at: validateResponseDate(ticket?.created_at),
        execution_date: validateResponseDate(ticket?.execution_date),
        planned_date: validateResponseDate(ticket?.planned_date),
        site_completed_on: validateResponseDate(ticket?.site_completed_on),
        sla_details: ticket?.sla_details,
        tracking_detail: ticket?.tracking_detail,
        rltracking_detail: ticket?.rltracking_detail,
        signOffAccept: ticket?.is_signoff_accept,
        signOffRequest: ticket?.is_sign_off_request,
        signOffReject: ticket?.is_signoff_rejected,
        hardClose: ticket?.is_hard_close,
        closedByNoc: ticket?.closed_by_noc,
        reachedSite: ticket?.reached_site,
        isRmaRaised: ticket?.is_rma_raised,
        customerSignOffAccept: ticket?.is_customer_signoff_accept,
        customerSignOffRequired: ticket?.is_customer_signoff_required,
        requestRaised: ticket?.request_raised,
        serviceTypeId: ticket?.service_type_id,
        assignToScogo: ticket?.assign_to_scogo,
        remarks: [],
        assets: Array.isArray(ticket.assets) ? ticket.assets : [],
        job_sign_off_notes: ticket?.job_sign_off_notes,
        pm_rejection_message: ticket?.pm_rejection_message,
        isEscalated: ticket?.is_escalated,
        ticket_owned_by: ticket?.ticket_owned_by,
        isOnHold: ticket?.is_on_hold,
        jobStatus: ticket?.job_status,
        accepted_fe_id: ticket?.accepted_fe_id,
        accepted_sp_id: ticket?.accepted_sp_id,
        ticketType: ticket?.ticket_type,
        fk_assigned_primary_sp_id: ticket?.fk_assigned_primary_sp_id,
        cl_rma_response: ticket?.cl_rma_response,
        revisitCount: ticket?.revisit_count_value,
        warehouse_owner: ticket?.warehouse_owner,
        request_additional_amount: ticket?.request_additional_amount,
        parent_ticket_id: ticket?.parent_ticket_id,
        sign_off_response: ticket?.sign_off_response,
        hard_copy_required: ticket?.hard_copy_required,
        is_report_generated: ticket?.is_report_generated,
        po_raised: ticket?.po_raised,
        is_live_tracking_enabled: ticket?.is_live_tracking_enabled,
        asset_config_required: ticket?.asset_config_required,
        consoleUrl: `${config.remoteTerminal}/${ticket?.id}`,
        hard_copy_received: ticket.hard_copy_received,
        remote_teminal_connection_status: ticket?.remote_teminal_connection_status,
        remote_teminal_connection_url: ticket?.remote_teminal_connection_url,
        latitude: ticket?.latitude,
        longitude: ticket?.longitude,
        sign_off_lock: ticket?.sign_off_lock,
        issue_category: validateNumberValue(ticket?.issue_category),
        issue_title: validateStringValue(ticket?.issue?.title),
        domain: ticket?.customer_details?.domain, // only need to ticket chat
        end_user_id: ticket?.end_user_id,
        job_fk_city_id: ticket?.job_fk_city_id,
        job_fk_state_id: ticket?.job_fk_state_id,
        is_rma_eligible: validateNumberValue(ticket?.customer?.is_rma_eligible),
        replacement_required: validateNumberValue(ticket?.project?.replacement_required),
        room_call_status: ticket?.room_call_status,
        room_id: ticket?.fk_room_id,
        scogo_pm_name: validateStringValue(ticket?.project?.scogo_pm_name),
        scogo_pm_mobile: validateStringValue(ticket?.project?.scogo_pm_mobile),
        scogo_pm_id: validateStringValue(ticket?.project?.scogo_pm_id),
        fk_project_id: validateStringValue(ticket?.project?.id) || validateStringValue(ticket?.fk_project_id),
        fk_template_id: validateNumberValue(ticket?.fk_template_id),
        project_sla: validateNumberValue(ticket.project_sla),
        template_detail: ticket.template_detail,
        project_contact_person_email_address: ticket.project_contact_person_email_address,
        pm_email: ticket.pm_email,
        sign_off_document: ticket.sign_off_document,
        ticket_source: ticket.ticket_source,
        longitude: ticket.longitude,
        latitude: ticket.latitude,
    };
};
