// NatsService.js

import { connect, StringCodec } from "nats.ws";
import config from "../config";

class NatsService {
    constructor() {
        this.nc = null;       // NATS connection
        this.subscriptions = new Map(); // Track subscriptions
        this.sc = StringCodec()
    }

    async connect() {
        try {
            // Connect to NATS server over WebSocket
            this.nc = await connect({ servers: config.appUrls.natsUrl, user: config.appUrls.NATS_USER, pass: config.appUrls.NATS_PASS }); // Replace with your NATS server address

        } catch (error) {
            console.error("Failed to connect to NATS server:", error);
        }
    }

    async subscribe(topic, callback) {
        if (!this.nc) {
            await this.connect(); // Ensure connection is established before subscribing
        }

        try {
            // Subscribe to the specified topic
            const subscription = this.nc.subscribe(topic);

            (async () => {
                for await (const message of subscription) {
                    const decodedMessage = this.sc.decode(message.data)
                    callback(decodedMessage); // Pass the message data to the callback
                }
            })();

            // Store the subscription so it can be managed
            this.subscriptions.set(topic, subscription);

        } catch (error) {
            console.error(`Failed to subscribe to topic ${topic}:`, error);
        }
    }

    async publish(topic, message) {
        if (!this.nc) await this.connect(); // Ensure connection is established before publishing

        try {
            const encodedMessage = this.sc.encode(JSON.stringify(message)); // Encode message using StringCodec
            this.nc.publish(topic, encodedMessage);
        } catch (error) {
            console.error(`Failed to publish message to topic ${topic}:`, error);
        }
    }

    unsubscribe(topic) {
        const subscription = this.subscriptions.get(topic);
        if (subscription) {
            subscription.unsubscribe();
            this.subscriptions.delete(topic);
        }
    }

    disconnect() {
        if (this.nc) {
            this.nc.close();
            this.nc = null;

        }
    }
}
const NatsServiceAll = new NatsService();
export default NatsServiceAll;