import { combineReducers } from 'redux';
import { RESET_REDUX_STORE } from '../types/auth';
import customer from './customer';
import auth from './auth';
import conversations from './conversations';
import inventory from './inventory';
import loading from './loading';
import websocket from './websocket';
import modal from './modal';
import payments from './payments';
import wallet from './wallet';
import tickets from './tickets';
import project from './project';
import toast from './toast';
import superdesk from './superdesk';
import utils from './utils.js';
import admin from './admin';
import user from './users';
import partners from './partners';
import fe from './fe';
import pmscm from './pmscm';
import teams from './teams';
import sp from './sp';
import onboarding from './onboarding';
import fm from './fm';
import whatsapp from './whatsapp';

const appReducer = combineReducers({
    auth,
    customer,
    conversations,
    loading,
    websocket,
    modal,
    payments,
    wallet,
    inventory,
    tickets,
    project,
    toast,
    admin,
    user,
    utils,
    superdesk,
    partners,
    fe,
    pmscm,
    teams,
    sp,
    onboarding,
    fm,
    whatsapp,

});

const reducer = (state, action) => {
    if (action.type === RESET_REDUX_STORE) {
        return appReducer({ user: { uiConfig: state.user.uiConfig } }, action);
    }

    return appReducer(state, action);
};

export default reducer;
